import React, { useState, useContext, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  IconButton,
  Divider,
} from "@material-ui/core";
import useStyles from "../styles";
import Modal from "@mui/material/Modal";
import { Close, AddAPhoto } from "@material-ui/icons";
import { AuthContext } from "../../../contextAPI/context";
import { resolveErrorMessage } from "../../../services/service";
import * as api from "../../../api";
import { toast } from "react-toastify";
import { VENDORS } from "../../../contextAPI/actions/auth.actions";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import { Add } from "@material-ui/icons";
import { LoadingOutlined } from "@ant-design/icons";
import { Input } from "antd";
import ChooseCategoryDropDown from "../../../GlobalComponents/DropDowns/ChooseCategoryDropDown";

function AddVendor(props) {
  const classes = useStyles();
  const { authDispatch } = useContext(AuthContext);
  const { addVendorModal, setaddVendorModal, handleGetVendor } = props;
  const [vendorImage, setvendorImage] = useState(
    "https://github.com/OlgaKoplik/CodePen/blob/master/profile.jpg?raw=true"
  );
  const [vendorName, setvendorName] = useState("");
  const [vendorNumber, setvendorNumber] = useState("");
  const [vendorEmail, setvendorEmail] = useState("");
  const [vendorAddress, setvendorAddress] = useState("");
  const [vendorDetails, setvendorDetails] = useState("nothing");
  const [vendorAttachFiles, setvendorAttachFiles] = useState("");
  const [errorMsg, seterrorMsg] = useState("");
  const [Image, setImage] = useState("");
  const [loader, setloader] = useState(false);
  const { TextArea } = Input;
  const [searchKey, setsearchKey] = useState("");
  const [vendorCategory, setvendorCategory] = useState([]);
  const [vendorSubCategory, setvendorSubCategory] = useState([]);

  const handleReset = () => {
    setaddVendorModal(false);
    seterrorMsg("");
    setvendorImage(
      "https://github.com/OlgaKoplik/CodePen/blob/master/profile.jpg?raw=true"
    );
    setvendorName("");
    setvendorNumber("");
    setvendorEmail("");
    setvendorAddress("");
    setvendorCategory([]);
    setvendorSubCategory([]);
    setvendorAttachFiles("");
    setloader(false);
  };

  const handleAddVendor = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    if (vendorNumber.length > 10) {
      setloader(false);
      seterrorMsg("Number Must be 10 character long");
    } else if (vendorCategory?.legnth === 0) {
      setloader(false);
      seterrorMsg("Please Select a Category");
    } else {
      try {
        let temp = [];
        vendorCategory?.map((sub) => {
          temp.push(sub?._id);
        });


        const sendData = {
          name: vendorName,
          email: vendorEmail,
          phone: vendorNumber,
          categories: vendorCategory?.map((cat) => cat?._id),
          address: vendorAddress,
          details: vendorDetails,
          societyId: fetchSocietyId(),
          isSubVendor: false,
          image:Image
        };
      


        const res = await api.createVendor(sendData);
        if (res?.data?.success) {
          let updatedVendors = res.data.society.vendors.filter((vn) => {
            return vn.delete === false;
          });
          authDispatch({
            type: VENDORS,
            payload: updatedVendors,
          });
          handleGetVendor();
          toast.success("Vendor Added Successfully", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
          });

          // setaddVendorModal(false);
          // setloader(false);
          handleReset();
        }
      } catch (error) {
        setloader(false);
        seterrorMsg(resolveErrorMessage(error));
      }
    }
  };

  useEffect(() => {
    setvendorSubCategory([]);
  }, [vendorCategory]);

  const ImgUpload = ({ onChange, src }) => (
    <label htmlFor="photo-upload">
      <div className={classes.imgWrap}>
        <img
          for="photo-upload"
          src={src}
          height="80px"
          width="80px"
          style={{
            position: "absolute",
          }}
        />

        <input
          id="photo-upload"
          type="file"
          onChange={onChange}
          accept="image/*"
          style={{
            width: 0,
            outline: 0,
            backgroundColor: "#4b8edb",
          }}
        />
      </div>
    </label>
  );

  const photoUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    setImage(file);
    reader.onloadend = () => {
      setvendorImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <Modal open={addVendorModal}>
      <Paper
        style={{
          backgroundColor: "#e6e7f0",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
          overflow: "hidden",
          // width: "45vw",
          minWidth: "500px",
          maxWidth: "50vw",
        }}
        className={classes.ModalView}
      >
        <Grid
          container
          style={{
            textAlign: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              fontWeight: "normal",
              color: "#000",
              textAlign: "center",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: "400",
              marginLeft: 10,
              fontSize: 17,
              opacity: 1,
            }}
          >
            Add Vendor
          </Typography>
          {!loader && (
            <IconButton
              onClick={() => {
                handleReset();
              }}
            >
              <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
            </IconButton>
          )}
        </Grid>
        <Divider
          style={{
            marginTop: 0,
            marginBottom: 10,
            backgroundColor: "#000",
          }}
        />
        {/* <Typography
          style={{
            color: "#283f59",
            fontFamily: "Montserrat, sans-serif",
            fontWeight: "800",
            marginTop: 2,
            marginLeft: -10,
            marginBottom: 5,
            textAlign: "center",
          }}
        >
          <AddAPhoto
            style={{
              fontSize: 22,
              color: "#2f3aa3",
              marginTop: -7,
              marginRight: 6,
            }}
          />
          Upload Photo
        </Typography> */}
        {/* <Grid container style={{ justifyContent: "center" }}>
          <ImgUpload onChange={photoUpload} src={vendorImage} />
        </Grid> */}
        <Grid container style={{ marginLeft: -10 }}>
          <Grid container item xs={6}>
            <Typography
              style={{
                color: "#283f59",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                marginTop: 2,
                marginLeft: 15,
                marginBottom: 5,
              }}
            >
              Name
            </Typography>
          </Grid>
          <Grid container item xs={6}>
            <Typography
              style={{
                color: "#283f59",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                marginTop: 2,
                marginLeft: 15,
                marginBottom: 5,
              }}
            >
              Contact No.
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid container item xs={6}>
            <input
              style={{
                borderRadius: 10,
                padding: 10,
                width: "98%",
                border: "1px solid #416082",
                marginBottom: 8,
                outline: 0,
              }}
              onChange={(event) => setvendorName(event.target.value)}
              placeholder="Enter Name"
            />
          </Grid>
          <Grid container item xs={6}>
            <input
              type="number"
              style={{
                borderRadius: 10,
                padding: 10,
                width: "98%",
                border: "1px solid #416082",
                marginBottom: 8,
                outline: 0,
              }}
              onChange={(event) => setvendorNumber(event.target.value)}
              placeholder="Enter Phone Number"
            />
          </Grid>
        </Grid>

        <Grid container style={{ marginLeft: -10 }}>
          <Grid container item xs={6}>
            <Typography
              style={{
                color: "#283f59",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                marginTop: 2,
                marginLeft: 15,
                marginBottom: 5,
              }}
            >
              Email
            </Typography>
          </Grid>
          <Grid container item xs={6}>
            <Typography
              style={{
                color: "#283f59",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                marginTop: 2,
                marginLeft: 15,
                marginBottom: 5,
              }}
            >
              Address
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid container item xs={6}>
            <input
              type="email"
              style={{
                borderRadius: 10,
                padding: 10,
                width: "98%",
                border: "1px solid #416082",
                marginBottom: 8,
                outline: 0,
              }}
              onChange={(event) => setvendorEmail(event.target.value)}
              placeholder="Enter Email"
            />
          </Grid>
          <Grid container item xs={6}>
            <input
              style={{
                borderRadius: 10,
                padding: 10,
                width: "98%",
                border: "1px solid #416082",
                marginBottom: 8,
                outline: 0,
              }}
              onChange={(event) => setvendorAddress(event.target.value)}
              placeholder="Enter Address"
            />
          </Grid>
        </Grid>
        <Grid container style={{ marginLeft: -10 }}>
          <Grid container item xs={12}>
            <Typography
              style={{
                color: "#283f59",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                marginTop: 2,
                marginLeft: 15,
                marginBottom: 5,
              }}
            >
              Category
            </Typography>
          </Grid>

          {/* <Typography
              style={{
                color: "#283f59",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                marginTop: 2,
                marginLeft: 15,
                marginBottom: 5,
              }}
            >
              Subcategory
            </Typography> */}
        </Grid>
        <Grid container>
          <Grid container item xs={12}>
            <ChooseCategoryDropDown
              setCategory={setvendorCategory}
              category={vendorCategory}
              isMultiple={true}
              searchKey={searchKey}
              setsearchKey={setsearchKey}
            />
          </Grid>
          {/* <Grid container item xs={6}>
            <ChooseSubCategoryDropDown
              setSubCategory={setvendorSubCategory}
              subCategory={vendorSubCategory}
              isMultiple={false}
              searchKey={searchKey}
              setsearchKey={setsearchKey}
              category={vendorCategory}
            />
          </Grid> */}
        </Grid>
        <Typography
          style={{
            color: "#283f59",
            fontFamily: "Montserrat, sans-serif",
            fontWeight: "600",
            marginTop: 10,
            marginLeft: 10,
            marginBottom: 5,
            fontSize: 14,
          }}
        >
          Upload Documents
        </Typography>
        <Grid container>
          <IconButton>
            <AddAPhoto
              style={{
                fontSize: 30,
                color: "#2f3aa3",
                marginTop: -10,
              }}
            />
          </IconButton>
          <input
            type="file"
            onChange={(event) => {
              setvendorAttachFiles(event.target.files[0]);
            }}
            id="img"
            name="img"
            style={{
              color: "#000",
              width: "90%",
              padding: 10,
              border: "1px solid #6c757d",
              borderRadius: 10,
              marginBottom: 6,
            }}
            multiple
          ></input>
        </Grid>
        {/* <Grid container>
          <Grid container item xs={8}>
            <Typography
              style={{
                color: "#283f59",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                marginTop: 2,
                marginLeft: 5,
                marginBottom: 4,
                marginLeft: 8,
              }}
            >
              Detail
            </Typography>
          </Grid>
        </Grid> */}
        {/* <Grid container>
          <Grid container item xs={12}>
            <TextArea
              className="custom"
              style={{
                height: 100,
                borderRadius: 10,
              }}
              onChange={(event) => setvendorDetails(event.target.value)}
              placeholder="Enter Detail Here.."
            />
          
          </Grid>
        </Grid> */}
        {errorMsg && (
          <Typography
            style={{ marginTop: 10, color: "red", textAlign: "center" }}
          >
            {errorMsg}
          </Typography>
        )}
        <Grid container style={{ justifyContent: "center" }}>
          <div
            class="card"
            style={{
              height: 35,
              boxShadow: "0 3px 6px #7D858D",
              width: "155px",
              backgroundColor: "#2f45c5",
              color: "#ffffff",
              cursor: "pointer",
              textAlign: "center",
              marginTop: "10px",
            }}
            onClick={() => {
              if (!loader) {
                handleAddVendor();
              } else {
                //NOTHING HAPPENED
              }
            }}
          >
            <div class="card-body ">
              <p
                class="card-text"
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: "-10px",
                  marginBottom: "10px",
                }}
              >
                <>
                  {loader ? (
                    <LoadingOutlined
                      style={{
                        marginRight: 10,
                        fontSize: "15px",
                      }}
                    />
                  ) : (
                    <Add
                      style={{
                        marginRight: 10,
                        fontSize: "15px",
                      }}
                    />
                  )}
                  {!loader ? "Add Vendor" : `Adding...`}
                </>
              </p>
            </div>
          </div>
        </Grid>
      </Paper>
    </Modal>
  );
}

export default AddVendor;
