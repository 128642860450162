import { Paper } from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import useStyles from "../styles";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { InfoOutlined } from "@material-ui/icons";
import ComplaintLifeCycleMoreInfoModal from "./ComplaintLifeCycleMoreInfo";
import { useParams } from "react-router";
import { AuthContext } from "../../../../contextAPI/context";
import ComplaintSubVendorInfoModal from "./ComplaintSubVendorInfoModal";

function LifeCycleTable(props) {
  const classes = useStyles();
  const { authState } = useContext(AuthContext);
  const { complaintType } = useParams();
  const { user, complaintLifeCycle, complaint, loader, setloader } = props;
  const [openMoreInfoModal, setOpenMoreInfoModal] = useState(false);
  const [selectedLifeCycleData, setselectedLifeCycleData] = useState([]);
  const [lifeCycleData, setlifeCycleData] = useState([]);
const [openSubVendorInfo, setopenSubVendorInfo] = useState(false)

  useEffect(() => {
    if (complaintLifeCycle) {
      const temp = [...complaintLifeCycle]?.reverse();
      setlifeCycleData(temp);
    }
  }, [complaintLifeCycle]);

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                Process No.
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                {" "}
                Date and Time
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                {" "}
                ACTION
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                {" "}
                UPDATED BY
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                {" "}
                UPDATED FOR
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                {" "}
                More Info
              </TableCell>
            </TableRow>
          </TableHead>

          {lifeCycleData?.map((lifecycle, index) => {
            var date = moment(lifecycle.time).format("DD/MM/YYYY - h:mm:ss A");
            return (
              <TableBody>
                <TableCell
                  align="center"
                  sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
                >
                  {lifeCycleData?.length - index}.
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
                >
                  {date}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
                >
                  {lifecycle.type === "Notify customer"
                    ? "Notify Complainer"
                    : lifecycle.type}
                </TableCell>

                <TableCell
                  align="center"
                  sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
                >
                  {(lifecycle.type === "Complaint Raised" ||
                    lifecycle.type === "Escalate to level one" ||
                    lifecycle.type === "Escalate to level two" ||
                    lifecycle.type === "Escalate to level three" ||
                    lifecycle.type === "Deadline Exceeded") && (
                    <p>
                      {lifecycle.actionTakerRole === "System automated"
                        ? "System Automated"
                        : lifecycle?.complainer?.name}
                      {lifecycle.actionTakerRole !== "System automated" && (
                        <span
                          style={{
                            // width: "50px",
                            padding: 5,
                            textTransform: "capitalize",
                            marginLeft: 5,
                          }}
                          class={
                            user?.role === "admin"
                              ? "badge bg-primary"
                              : "badge bg-info"
                          }
                        >
                          {lifecycle?.complainer?.role}
                        </span>
                      )}
                    </p>
                  )}
                  {(lifecycle.type === "Change priority" ||
                    lifecycle.type === "Notify vendor" ||
                    lifecycle.type === "Notify customer" ||
                    lifecycle.type === "Vendor visit" ||
                    lifecycle?.type === "Sub-Sub Complaint Category" ||
                    lifecycle.type === "Extend Deadline By Admin") && (
                    <p>
                      {lifecycle?.admin?.name}
                      <span
                        style={{
                          // width: "50px",
                          padding: 5,
                          textTransform: "capitalize",
                          marginLeft: 5,
                        }}
                        class={
                          user?.role === "admin"
                            ? "badge bg-primary"
                            : "badge bg-info"
                        }
                      >
                        {lifecycle?.admin?.role
                          ? lifecycle.admin.role
                          : lifecycle.actionTakerRole}
                      </span>
                    </p>
                  )}
                  {(lifecycle.type === "Extend Deadline" ||
                    lifecycle.type === "Close Complaint Request" ||
                    lifecycle?.type === "Extended Deadline Withdrawn") && (
                    <p>
                      {lifecycle?.vendorId?.name}
                      {lifecycle.actionTakerRole === "Vendor" &&
                        lifecycle.actionTakerRole !== "System automated" && (
                          <span
                            style={{
                              // width: "50px",
                              padding: 5,
                              textTransform: "capitalize",
                              marginLeft: 5,
                            }}
                            class={
                              user?.role === "admin"
                                ? "badge bg-primary"
                                : "badge bg-info"
                            }
                          >
                            Vendor
                          </span>
                        )}
                    </p>
                  )}
                  {(lifecycle.type === "Assign vendor" ||
                    lifecycle.type === "Mark as resolved" ||
                    lifecycle.type === "Close Complaint" ||
                    lifecycle.type === "Reopen Complaint" ||
                    lifecycle.type === "Reassign Vendor" ||
                    lifecycle.type === "Complaint Cancelled" ||
                    lifecycle.type === "Notify Admin" ||
                    lifecycle.type === "Re-Assign a new complaint" ||
                    lifecycle.type === "Deadline Extension Approved" ||
                    lifecycle.type === "Deadline Extension Rejected" ||
                    lifecycle.type === "Complaint Close Approved" ||
                    lifecycle.type === "Complaint Close Rejected") && (
                    <p>
                      {lifecycle.actionTakerRole === "System automated" && (
                        <span>System Automated</span>
                      )}

                      {lifecycle.actionTakerRole !== "System automated" && (
                        <>
                          {lifecycle?.admin?.name
                            ? lifecycle?.admin?.name
                            : lifecycle?.complainer?.name}
                        </>
                      )}
                      {lifecycle.actionTakerRole === "Vendor" &&
                        lifecycle.actionTakerRole !== "System automated" && (
                          <span
                            style={{
                              // width: "50px",
                              padding: 5,
                              textTransform: "capitalize",
                              marginLeft: 5,
                            }}
                            class={
                              user?.role === "admin"
                                ? "badge bg-primary"
                                : "badge bg-info"
                            }
                          >
                            Vendor
                          </span>
                        )}
                      {lifecycle.actionTakerRole !== "Vendor" &&
                        lifecycle.actionTakerRole !== "System automated" && (
                          <span
                            style={{
                              // width: "50px",
                              padding: 5,
                              textTransform: "capitalize",
                              marginLeft: 5,
                            }}
                            class={
                              user?.role === "admin"
                                ? "badge bg-primary"
                                : "badge bg-info"
                            }
                          >
                            {lifecycle?.admin?.role
                              ? lifecycle?.admin?.role
                              : lifecycle?.complainer?.role}
                          </span>
                        )}
                    </p>
                  )}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
                >
                  {(lifecycle.type === "Complaint Raised" ||
                    lifecycle.type === "Change priority" ||
                    lifecycle.type === "Mark as resolved" ||
                    lifecycle.type === "Close Complaint" ||
                    lifecycle.type === "Reopen Complaint" ||
                    lifecycle.type === "Notify customer" ||
                    lifecycle.type === "Complaint Cancelled" ||
                    lifecycle.type === "Re-Assign a new complaint" ||
                    lifecycle?.type === "Extended Deadline Withdrawn" ||
                    lifecycle?.type === "Sub-Sub Complaint Category") && (
                    <p>
                      {lifecycle?.complainer?.name}
                      <span
                        style={{
                          // width: "50px",
                          padding: 5,
                          textTransform: "capitalize",
                          marginLeft: 5,
                        }}
                        class={
                          user?.role === "admin"
                            ? "badge bg-primary"
                            : "badge bg-info"
                        }
                      >
                        {lifecycle?.complainer?.role}
                      </span>
                    </p>
                  )}
                  {(lifecycle.type === "Notify Admin" ||
                    lifecycle.type === "Extend Deadline" ||
                    lifecycle.type === "Close Complaint Request") && (
                    <p>Management</p>
                  )}
                  {(lifecycle.type === "Assign vendor" ||
                    lifecycle.type === "Reassign Vendor" ||
                    lifecycle.type === "Notify vendor" ||
                    lifecycle.type === "Vendor visit" ||
                    lifecycle.type === "Deadline Exceeded" ||
                    lifecycle.type === "Escalate to level one" ||
                    lifecycle.type === "Escalate to level two" ||
                    lifecycle.type === "Escalate to level three" ||
                    lifecycle.type === "Deadline Extension Rejected" ||
                    lifecycle.type === "Deadline Extension Approved" ||
                    lifecycle.type === "Complaint Close Approved" ||
                    lifecycle.type === "Complaint Close Rejected" ||
                    lifecycle.type === "Extend Deadline By Admin") && (
                    <p>
                      {lifecycle?.vendorId?.name}
                      <span
                        style={{
                          // width: "50px",
                          padding: 5,
                          textTransform: "capitalize",
                          marginLeft: 5,
                        }}
                        class={
                          user?.role === "admin"
                            ? "badge bg-primary"
                            : "badge bg-info"
                        }
                      >
                        vendor
                      </span>
                    

                      {
                        complaint?.subVendors?.length > 0  &&
                        <>
                          <br />
                          <span
                          onClick={()=>{
                            setopenSubVendorInfo(true)
                          }}
                          style={{color:"blue", fontFamily: "Poppins, sans-serif",textDecoration:"underline",cursor:"pointer"}}>+{complaint?.subVendors?.length}
                          <span
                        style={{
                          // width: "50px",
                          padding: 5,
                          textTransform: "capitalize",
                          marginLeft: 5,
                        }}
                        class="badge bg-primary"
                      >
                        subvendor
                      </span>
                    
                          </span>
                        </>
                      }
                    </p>
                  )}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
                >
                  <InfoOutlined
                    onClick={() => {
                      setselectedLifeCycleData(lifecycle);
                      setOpenMoreInfoModal(true);
                    }}
                    style={{
                      cursor: "pointer",
                      color: "#2e3094",
                      marginTop: -10,
                    }}
                  />
                </TableCell>
              </TableBody>
            );
          })}
        </Table>
      </TableContainer>
      <ComplaintLifeCycleMoreInfoModal
        openMoreInfoModal={openMoreInfoModal}
        setOpenMoreInfoModal={setOpenMoreInfoModal}
        selectedLifeCycleData={selectedLifeCycleData}
        complaintType={complaintType}
        loader={loader}
        setloader={setloader}
        user={user}
      />
         <ComplaintSubVendorInfoModal
        openSubVendorInfo={openSubVendorInfo}
        setopenSubVendorInfo={setopenSubVendorInfo}
      subVendors={complaint?.subVendors}
      />
    </>
  );
}

export default LifeCycleTable;
