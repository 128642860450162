import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Paper,
  IconButton,
  Divider,
  Select,
  MenuItem,
} from "@material-ui/core";
import useStyles from "./styles";
import { ApartmentOutlined, Close, ErrorOutline } from "@material-ui/icons";
import Modal from "@mui/material/Modal";
import { LoadingOutlined } from "@ant-design/icons";
import { handleFlatName } from "../../../../contextAPI/NamingConvention";
import { AllSocietyRoutes } from "../../../../api/Routes";
import ApiCall from "../../../../api/ApiCall";
import { toast } from "react-toastify";
import { fetchSocietyId } from "../../../../contextAPI/fetchSociety";

function EditFlat(props) {
  const classes = useStyles();
  const {
    isFlatEdit,
    setisFlatEdit,
    user,
    editedFlatDetails,
    allFlatTypes,
    handleGetFloors,
  } = props;
  const [selectedFlatType, setselectedFlatType] = useState("Choose Flat Type");
  const [flatName, setflatName] = useState("");
  const [flatNumber, setflatNumber] = useState("");
  const [loader, setloader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");

  const startApi = () => {
    setloader(true);
    seterrorMessage("");
    setTimeout(() => {
      setloader(false);
    }, 40000);
  };

  const handleEditProperty = async () => {
    if (
      flatName === "" ||
      flatNumber === "" ||
      selectedFlatType === "Choose Flat Type"
    ) {
      seterrorMessage("enter required details first!");
      setloader(false);
    } else {
      startApi();
      let sendData = {
        adminId: user?._id,
        flatId: editedFlatDetails?._id,
        societyId: fetchSocietyId(),
        name: flatName,
        number: flatNumber,
        type: selectedFlatType,
      };

      const res = await ApiCall("post", AllSocietyRoutes.editFlat, sendData);
      if (res?.success) {
        handleGetFloors();
        toast.success(`${handleFlatName()} has been updated successfully!`);
        setloader(false);
        setisFlatEdit(false);
      } else {
        setloader(false);
        seterrorMessage(res.error);
      }
    }
  };
  useEffect(() => {
    if (editedFlatDetails?._id) {
      setflatName(editedFlatDetails?.name);
      setflatNumber(editedFlatDetails?.number);
      setselectedFlatType(editedFlatDetails?.type);
    }
  }, [editedFlatDetails]);

  return (
    <>
      <Modal open={isFlatEdit}>
        <Paper
          className={classes.ModalView}
          style={{
            backgroundColor: "#e6e7f0",
            boxShadow: "2px 2px 8px #000",
            borderRadius: 7,
            height: "auto",
            minWidth: "400px",
            maxWidth: "32vw",
          }}
        >
          <Grid
            container
            style={{ textAlign: "center", justifyContent: "space-between" }}
          >
            <Typography
              style={{
                fontWeight: "normal",
                color: "#000",
                fontWeight: 400,
                fontFamily: "Montserrat, sans-serif",
                textAlign: "center",
                marginLeft: 10,
                fontSize: 17,
                opacity: 1,
              }}
            >
              Edit {handleFlatName()}
            </Typography>
            {!loader && (
              <IconButton
                onClick={() => {
                  setisFlatEdit(false);
                }}
              >
                <Close
                  style={{ color: "#000", fontSize: 20, marginTop: -10 }}
                />
              </IconButton>
            )}
          </Grid>
          <Divider
            style={{ marginTop: 0, marginBottom: 10, backgroundColor: "#000" }}
          />
          <Grid container>
            <Grid container item xs={6}>
              <Typography
                style={{
                  color: "#6c757d",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "600",
                  fontSize: "14px",
                  marginBottom: 2,
                  marginLeft: 4,
                }}
              >
                {handleFlatName()} Name
                <span style={{ color: "#2f45c5", marginLeft: 3 }}>*</span>
              </Typography>
            </Grid>
            <Grid container item xs={6}>
              <Typography
                style={{
                  color: "#6c757d",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "600",
                  fontSize: "14px",
                  marginBottom: 2,
                }}
              >
                {handleFlatName()} Number
                <span style={{ color: "#2f45c5", marginLeft: 3 }}>*</span>
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid container item xs={6}>
              <input
                type="text"
                style={{
                  borderRadius: 10,
                  padding: 10,
                  width: "98%",
                  border: "1px solid #416082",
                  marginBottom: 8,
                  outline: 0,
                }}
                onChange={(event) => setflatName(event.target.value)}
                placeholder="Enter Name"
                value={flatName}
              />
            </Grid>
            <Grid container item xs={6}>
              <input
                type="text"
                style={{
                  borderRadius: 10,
                  padding: 10,
                  width: "98%",
                  border: "1px solid #416082",
                  marginBottom: 8,
                  outline: 0,
                }}
                onChange={(event) => setflatNumber(event.target.value)}
                placeholder="Enter Number"
                value={flatNumber}
              />
            </Grid>
          </Grid>
          <Grid container item xs={6}>
            <Typography
              style={{
                color: "#6c757d",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                fontSize: "14px",
                marginBottom: 5,
              }}
            >
              {handleFlatName()} Type
              <span style={{ color: "#2f45c5", marginLeft: 3 }}>*</span>
            </Typography>
          </Grid>
          <Grid container item xs={12}>
            <Select
              value={selectedFlatType}
              onChange={(event) => setselectedFlatType(event.target.value)}
              style={{
                color: "#212529",
                backgroundColor: "#fff",
                fontWeight: "bold",
                borderRadius: 10,
                height: 50,
                width: "100%",
                fontSize: "13px",
                padding: 8,
                paddingLeft: 6,
                marginBottom: 10,
                border: "1px solid #6c757d",
                textAlign: "center",
                outline: 0,
              }}
            >
              <MenuItem
                selected
                value="Choose Flat Type"
                style={{
                  color: "#6c757d",
                  fontFamily: "Montserrat,sans-serif",
                }}
              >
                Choose {handleFlatName()} Type
              </MenuItem>
              {allFlatTypes?.map((type) => {
                return (
                  <MenuItem
                    selected
                    value={type.name}
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      fontWeight: "bold",
                    }}
                  >
                    &nbsp;{type.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          {errorMessage && (
            <Typography
              style={{
                marginTop: 10,
                color: "red",
                textAlign: "center",
                fontSize: "13px",
                fontWeight: "bold",
              }}
            >
              <ErrorOutline style={{ marginRight: 3 }} />
              {errorMessage}
            </Typography>
          )}
          <Grid container style={{ justifyContent: "center" }}>
            <div
              class="card"
              style={{
                height: 30,
                boxShadow: "0 3px 6px #4a6078",
                width: "160px",
                backgroundColor: "#2f45c5",
                color: "#ffffff",
                cursor: "pointer",
                marginTop: 10,
              }}
            >
              <div class="card-body ">
                <p
                  class="card-text"
                  style={{
                    fontSize: "11px",
                    fontWeight: "bold",
                    fontFamily: "Montserrat, sans-serif",
                    marginTop: "-12px",
                    marginBottom: "20px",
                    textAlign: "center",
                  }}
                  onClick={() => {
                    if (!loader) {
                      handleEditProperty();
                    } else {
                      //NOTHING HAPPENED
                    }
                  }}
                >
                  <>
                    {loader ? (
                      <LoadingOutlined
                        style={{
                          marginRight: 10,
                          fontSize: "15px",
                        }}
                      />
                    ) : (
                      <ApartmentOutlined
                        style={{
                          marginRight: 10,
                          fontSize: "15px",
                        }}
                      />
                    )}

                    {!loader ? `Edit ${handleFlatName()}` : `Updating...`}
                  </>
                </p>
              </div>
            </div>
          </Grid>
        </Paper>
      </Modal>
    </>
  );
}

export default EditFlat;
