import React, { useState, useContext, useEffect } from "react";
import {
  Typography,
  Grid,
  Divider,
  Avatar,
  Modal,
  IconButton,
} from "@material-ui/core";
import useStyles from "../styles";
import { useNavigate, useParams } from "react-router-dom";
import "./style.css";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TablePagination } from "@mui/material";
import Paper from "@mui/material/Paper";
import { Rating } from "@mui/material";
import * as api from "../../../api";
import { AuthContext } from "../../../contextAPI/context";
import { LoadingOutlined } from "@ant-design/icons";
import { BASE_URL } from "../../../utils/base";
import StarIcon from "@mui/icons-material/Star";
import { Close, KeyboardBackspaceRounded } from "@material-ui/icons";
import { resolveErrorMessage } from "../../../services/service";
import { navigateToVendorDetails } from "../../../Navigation/Navigations";

function VendorReviewTable(props) {
  const classes = useStyles();
  const { authState, authDispatch } = useContext(AuthContext);
  const { vendors } = authState;
  const { id } = useParams();
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [pleasewait, setpleasewait] = useState(false);
  const [complaintNumber, setcomplaintNumber] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [complaintCategory, setComplaintCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [address, setAddress] = useState("");
  const [feedback, setFeedback] = useState([]);
  const [averageRating, setAverageRating] = useState(0);
  const [vendorImage, setvendorImage] = useState("");
  const [totalReviews, setTotalReviews] = useState(0);
  const [totalRatings, setTotalRatings] = useState(0);
  const [reviewMessage, setreviewMessage] = useState([]);
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleVendorFeedback = async () => {
    setpleasewait(true);

    try {
      const sendData = {
        vendorId: id,
      };
      const res = await api.fetchVendorById(sendData);
      if (res.data.success) {
        setVendorName(res?.data?.vendor?.vendor?.name);
        setEmail(res?.data?.vendor?.vendor?.email);
        setPhone(res?.data?.vendor?.vendor?.phone);
        setvendorImage(res?.data?.vendor?.vendor?.image);
        setComplaintCategory(res?.data?.vendor?.vendor?.category?.categoryName);
        setSubCategory(res?.data?.vendor?.vendor?.subCategory?.subcategoryName);
        setAddress(res?.data?.vendor?.vendor?.address);
        setFeedback(res?.data?.vendor?.vendor?.feedback);
        setAverageRating(res?.data?.vendor?.vendor?.averageRating);
        setTotalRatings(res?.data?.vendor?.ratingCount);
        setTotalReviews(res?.data?.vendor?.reviewsCount);
        setpleasewait(false);
      }
    } catch (error) {
      setpleasewait(false);
      resolveErrorMessage(error);
    }
  };

  useEffect(async () => {
    handleVendorFeedback();
  }, []);

  const ReviewModal = (
    <div>
      {openReviewModal && (
        <Modal open={openReviewModal}>
          <Paper
            style={{
              backgroundColor: "#e6e7f0",
              boxShadow: "2px 2px 8px #000",
              borderRadius: 7,
              overflow: "hidden",
              maxWidth: "400px",
              minWidth: "300px",
            }}
            className={classes.ModalView}
          >
            <Grid
              container
              style={{
                textAlign: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                style={{
                  color: "#000",
                  fontWeight: 400,
                  fontFamily: "Montserrat, sans-serif",
                  textAlign: "center",
                  marginLeft: 10,
                  fontSize: 17,
                  opacity: 1,
                }}
              >
                Review
              </Typography>
              <IconButton
                onClick={() => {
                  setOpenReviewModal(false);
                }}
              >
                <Close
                  style={{ color: "#000", fontSize: 20, marginTop: -10 }}
                />
              </IconButton>
            </Grid>
            <Divider
              style={{
                marginTop: 0,
                marginBottom: 10,
                backgroundColor: "#000",
              }}
            />
            <Grid container style={{ justifyContent: "center" }}>
              <Typography
                style={{
                  color: "#000000",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "600",
                  fontSize: 16,
                  marginLeft: 4,
                  marginBottom: 8,
                  marginTop: 8,
                }}
              >
                {reviewMessage}
              </Typography>
            </Grid>
          </Paper>
        </Modal>
      )}
    </div>
  );

  return (
    <div className={classes.root}>
      <main className={classes.content2}>
        <div className={classes.toolbar} />
        <div class="card" style={{ borderRadius: 10, marginLeft: 10 }}>
          <div class="card-body">
            <Grid
              container
              style={{
                justifyContent: "space-between",
              }}
            >
              <Grid item lg={6}>
                <Typography
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: 22,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                    }}
                  >
                    Vendor Reviews
                  </div>
                </Typography>
              </Grid>
              <Grid
                container
                item
                lg={6}
                style={{ justifyContent: "flex-end" }}
              >
                <div
                  class="card"
                  style={{
                    height: 30,
                    boxShadow: "0 3px 6px #4a6078",
                    width: "100px",
                    backgroundColor: "#2f45c5",
                    color: "#ffffff",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(navigateToVendorDetails({vendorId:id}));
                  }}
                >
                  <div class="card-body ">
                    <p
                      class="card-text"
                      style={{
                        fontSize: "11px",
                        fontWeight: "bold",
                        fontFamily: "Montserrat, sans-serif",
                        marginTop: "-12px",
                        marginBottom: "20px",
                        textAlign: "center",
                      }}
                    >
                      <KeyboardBackspaceRounded
                        style={{
                          marginRight: 10,
                          fontSize: "15px",
                        }}
                      />
                      BACK
                    </p>
                  </div>
                </div>
              </Grid>
              {/* <button
                className={classes.addButton}
                onClick={() => {
                  navigate(`/vendorprofile/${id}`);
                }}
                style={{
                  fontSize: 13,
                  position: "relative",
                  backgroundColor: "#2f45c5",
                  fontFamily: "Poppins, sans-serif",
                  color: "#fff",
                  borderRadius: 5,
                }}
              >
                BACK
              </button> */}
              <div class="card-container">
                {!pleasewait && (
                  <Grid container>
                    <Grid item xs={4}>
                      <img
                        class="round"
                        src={
                          vendorImage
                            ? `${BASE_URL}/user/${vendorImage}`
                            : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                        }
                        alt="user"
                        height="100px"
                        width="100px"
                        style={{ marginTop: 6 }}
                      />
                      <h3
                        style={{
                          color: "#fff",
                          fontSize: 18,
                          marginTop: 5,
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {vendorName}&nbsp;
                      </h3>
                      <p>
                        <AttachEmailIcon
                          style={{ fontSize: 15, marginRight: 5 }}
                        />
                        {email}
                        <br />
                        <LocalPhoneIcon
                          style={{ fontSize: 15, marginRight: 5 }}
                        />
                        {phone}
                      </p>

                      {/* <Rating
                        name="simple-controlled"
                        readOnly
                        value={averageRating}
                      /> */}
                      <span
                        class="owner"
                        style={{
                          marginLeft: 6,
                          textTransform: "capitalize",
                        }}
                      >
                        {Math.round(averageRating * 10) / 10}
                        <StarIcon style={{ fontSize: 20, marginBottom: 2 }} />
                      </span>

                      <Typography
                        style={{
                          color: "#fff",
                          fontFamily: "Poppins, sans-serif",
                          marginTop: 5,
                          fontSize: 12,
                        }}
                      >
                        {totalRatings} Ratings & {totalReviews} reviews
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography
                        style={{
                          color: "#ffff",
                          fontWeight: "normal",
                          fontSize: 15,
                          marginTop: 18,
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Complaint Category : {complaintCategory}
                      </Typography>
                      <br></br>
                      <Typography
                        style={{
                          color: "#ffff",
                          fontWeight: "normal",
                          fontSize: 15,
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Sub Category : {subCategory}
                      </Typography>
                      <br></br>
                      <Typography
                        style={{
                          color: "#ffff",
                          fontWeight: "normal",
                          fontSize: 15,
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Address : {address}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                <div class="skills" style={{ backgroundColor: "#fff" }}>
                  <h6
                    style={{
                      color: "#000",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: 23,
                      fontWeight: "normal",
                    }}
                  >
                    Reviews
                  </h6>
                </div>
              </div>

              <TableContainer
                component={Paper}
                className={classes.tableContainer}
              >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        className={classes.tableHeaderCell}
                        sx={{
                          color: "white",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Sr. No.
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableHeaderCell}
                        sx={{
                          color: "white",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Review By
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableHeaderCell}
                        sx={{
                          color: "white",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Complaint Number
                      </TableCell>

                      <TableCell
                        align="center"
                        className={classes.tableHeaderCell}
                        sx={{
                          color: "white",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Review Message
                      </TableCell>
                      <TableCell
                        className={classes.tableHeaderCell}
                        align="center"
                        sx={{
                          color: "white",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Review Star
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!pleasewait ? (
                      feedback
                        ?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        ?.map((row, index) => (
                          <TableRow key={row.index}>
                            <TableCell
                              align="center"
                              sx={{
                                color: "black",
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              {rowsPerPage * page - 1 + index + 2}.
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                              sx={{
                                color: "black",
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              <Grid
                                container
                                style={{ justifyContent: "center" }}
                              >
                                <Avatar
                                  alt={row.admin}
                                  // src={Profilepic}
                                  className={classes.avatar}
                                />
                              </Grid>
                              <Typography className={classes.textname}>
                                {row.admin}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                color: "black",
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              {row.complaint.complaintNumber}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                color: "black",
                                fontFamily: "Poppins, sans-serif",
                              }}
                              title={row.feedback_message}
                            >
                              {row?.feedback_message.length > 35 ? (
                                <>
                                  {row?.feedback_message.slice(0, 35)}{" "}
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      textDecoration: "underline",
                                      color: "#1e2082",
                                      fontFamily: "Poppins, sans-serif",
                                      fontWeight: "bold",
                                    }}
                                    onClick={() => {
                                      setreviewMessage(row?.feedback_message);
                                      setOpenReviewModal(true);
                                    }}
                                  >
                                    see more
                                  </span>
                                </>
                              ) : (
                                row.feedback_message
                              )}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                color: "black",
                                fontFamily: "Poppins, sans-serif",
                              }}
                            >
                              <span
                                style={{
                                  marginLeft: 6,
                                  textTransform: "capitalize",
                                  background: "#febb0b",
                                  borderRadius: "5px",
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                  padding: "3px 7px",
                                }}
                              >
                                {row.stars}
                                <StarIcon
                                  style={{ fontSize: 15, marginBottom: 3 }}
                                />
                              </span>
                              {/* <Rating
                                name="simple-controlled"
                                readOnly
                                value={row.stars}
                              /> */}
                            </TableCell>
                          </TableRow>
                        ))
                    ) : (
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        <LoadingOutlined
                          style={{
                            fontSize: 50,
                            color: "#2f3aa3",
                            fontWeight: "normal",
                          }}
                        />
                      </div>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            {feedback?.length >= 10 && (
              <TablePagination
                rowsPerPageOptions={[10, 15, 25]}
                component="div"
                count={feedback?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}

            {feedback?.length === 0 && (
              <Grid container style={{ justifyContent: "center" }}>
                <section
                  style={{
                    height: "70vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <h1
                    style={{
                      fontSize: "2rem",
                      textTransform: "uppercase",
                      textAlign: "center",
                      color: "#2f45c5",
                      marginTop: "-40px",
                    }}
                  >
                    No Reviews
                  </h1>
                </section>
              </Grid>
            )}
          </div>
        </div>
        {ReviewModal}
      </main>
    </div>
  );
}

export default VendorReviewTable;
