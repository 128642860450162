import React, { useState } from "react";
import {
  Typography,
  Grid,
  Paper,
  Button,
} from "@material-ui/core";
import Modal from "@mui/material/Modal";
import { toast } from "react-toastify";
import { LoadingOutlined } from "@ant-design/icons";
import { ComplaintUpdateProcessRoutes } from "../../../../../api/Routes";
import ApiCall from "../../../../../api/ApiCall";
import { QuestionMark } from "@mui/icons-material";
import { ErrorOutline } from "@material-ui/icons";

const ChangePriorityModal = (props) => {
  const {
    complaint,
    selectProcess,
    setselectProcess,
    setupdateProcecssModal,
    user,
    handlegetComplaintById,
  } = props;
  const [errorMessage, seterrorMessage] = useState("");
  const [loader, setloader] = useState(false);

  const handleClose = () => {
    seterrorMessage("");
    setselectProcess("Select Process");
  };

  const callingApi = () => {
    setloader(true);
    seterrorMessage("");
    setTimeout(() => {
      setloader(false);
    }, 40000);
  };

  const handleReset = () => {
    handlegetComplaintById();
    setupdateProcecssModal(false);
    setselectProcess("Select Process");
    seterrorMessage("");
    setloader(false);
  };

  const handleChangePriority = async () => {
    callingApi();
    const sendData = {
      complaintId: complaint?._id,
      priority: complaint?.priority === "high" ? "low" : "high",
    };
    const sendData2 = {
      complaintId: complaint?._id,
      complainerId: complaint?.complainer._id,
      complaintLifeCycle: "Change priority",
      priority: complaint?.priority === "high" ? "low" : "high",
      adminId: user?._id,
    };
    const res2 = await ApiCall(
      "post",
      ComplaintUpdateProcessRoutes.complaintLifeCycle,
      sendData2
    );
    if (res2?.success) {
      const res = await ApiCall(
        "post",
        ComplaintUpdateProcessRoutes.changePriority,
        sendData
      );
      handleReset();
      toast.success("Priority Changed Successfully");
    } else {
      setloader(false);
      seterrorMessage(res2.error);
    }
  };
  return (
    <>
      {selectProcess === "Change Priority" && (
        <Modal open={selectProcess === "Change Priority"}>
          <Paper
            style={{
              position: "absolute",
              top: "50%",
              left: "55%",
              transform: "translate(-50%, -50%)",
              padding: 20,
              outline: 0,
              width: 450,
              backgroundColor: "#e6e7f0",
              boxShadow: "2px 2px 8px #000",
              borderRadius: 7,
            }}
          >
            <Typography
              style={{
                color: "#6c757d",
                fontSize: 19,
                textAlign: "center",
                fontWeight: "bold",
                fontFamily: "Montserrat, sans-serif",
                marginBottom: 7,
                textTransform: "uppercase",
              }}
            >
              {`ARE YOU SURE YOU WANT TO CHANGE PRIORITY TO ${
                complaint?.priority === "high" ? "LOW" : "HIGH"
              }`}
            </Typography>
            <Typography style={{ textAlign: "center" }}>
              <QuestionMark style={{ fontSize: 70, color: "#1e2082" }} />
            </Typography>

            <Typography
              style={{
                color: "#6c757d",
                fontSize: 14,
                textAlign: "center",
                fontFamily: "Montserrat, sans-serif",
                marginTop: 5,
              }}
            >
              You can confirm by clicking below.
            </Typography>
            {loader && (
              <Typography
                style={{
                  color: "#6c757d",
                  fontSize: 14,
                  textAlign: "center",
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: 5,
                }}
              >
                Please wait ... &nbsp;
                <LoadingOutlined style={{ fontSize: 27, color: "#2A265F" }} />
              </Typography>
            )}
            {errorMessage && (
              <Typography
                style={{
                  marginTop: 8,
                  color: "red",
                  textAlign: "center",
                  fontSize: "13px",
                  fontWeight: "bold",
                }}
              >
                <ErrorOutline style={{ marginRight: 3 }} />
                {errorMessage}
              </Typography>
            )}
            <Grid container style={{ justifyContent: "space-evenly" }}>
              <Button
              disabled={loader}
                onClick={handleClose}
                style={{
                  backgroundColor: "#C60C30",
                  color: "#fff",
                  marginTop: "12px",
                  padding: 8,
                  width: 180,
                }}
              >
                CANCEL
              </Button>
              <Button
              disabled={loader}
                onClick={handleChangePriority}
                style={{
                  backgroundColor: "#1e2082",
                  color: "#fff",
                  marginTop: "12px",
                  padding: 8,
                  width: 180,
                }}
              >
                CONFIRM
              </Button>
            </Grid>
          </Paper>
        </Modal>
      )}
    </>
  );
};

export default ChangePriorityModal;
