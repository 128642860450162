import React, { useContext, useEffect, useState } from "react";
import useStyles from "./styles";
import { Grid, Typography } from "@material-ui/core";
import GroupTable from "./GroupTable";
import RefreshIcon from "@mui/icons-material/Refresh";
import { LoadingOutlined } from "@ant-design/icons";
import AddIcon from "@mui/icons-material/Add";
import AddGroup from "./AddGroup";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import ApiCall from "../../../api/ApiCall";
import { AllGroupRoutes } from "../../../api/Routes";
import BanterLoader from "../../../GlobalComponents/Loaders/BanterLoader";
import { Pagination } from "@mui/material";
import { AuthContext } from "../../../contextAPI/context";
import { handleGetCheckModulePermission } from "../../../contextAPI/checkModulesPermission";

function Group() {
  const classes = useStyles();
  const { authState, authDispatch } = useContext(AuthContext);
  const { usermoduleswithpermissions } = authState;
  const [loader, setLoader] = useState(false);
  const [allGroups, setAllGroups] = useState([]);
  const [addGroup, setAddGroup] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = React.useState(0);
  const [pageLimit] = useState(30);
  const [isReadWrite, setIsReadWrite] = useState(false);

  useEffect(async () => {
    if (usermoduleswithpermissions) {
      setIsReadWrite(
        await handleGetCheckModulePermission(usermoduleswithpermissions, "Area")
      );
    }
  }, [usermoduleswithpermissions]);

  const handlePageChange = async (event, value) => {
    setPage(value);
  };

  const handleGetGroups = async () => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 50000);
    const data = {
      filters: {
        societyId: fetchSocietyId(),
      },
      page: page,
      limit: pageLimit,
    };
    const res = await ApiCall("post", AllGroupRoutes.getGroup, data);
    if (res?.success) {
      setLoader(false);
      setAllGroups(res?.addressGroup?.data);
      setCount(res?.addressGroup?.totalPages);
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    handleGetGroups();
  }, [page]);

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <div className={classes.toolbar} />

        <Grid item lg={12} sm={12} xs={12}>
          <div class="card" style={{ borderRadius: 6 }}>
            <div class="card-body">
              <Grid
                container
                style={{
                  justifyContent: "space-between",
                }}
              >
                <Grid>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 22,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                    }}
                  >
                    Group
                    {!loader ? (
                      <RefreshIcon
                        title="refresh Resident Page"
                        onClick={handleGetGroups}
                        style={{
                          marginLeft: 5,
                          cursor: "pointer",
                          color: "#000",
                        }}
                      />
                    ) : (
                      <LoadingOutlined
                        title="laoding..."
                        style={{ marginLeft: 5, color: "#000" }}
                      />
                    )}
                  </Typography>
                </Grid>
                <Grid>
                  {isReadWrite && (
                    <div
                      class="card"
                      style={{
                        height: 30,
                        boxShadow: "0 3px 6px #4a6078",
                        width: "150px",
                        backgroundColor: "#2f45c5",
                        color: "#ffffff",
                        cursor: "pointer",
                        marginTop: 7,
                      }}
                    >
                      <div class="card-body">
                        <p
                          class="card-text"
                          style={{
                            fontSize: "11px",
                            fontWeight: "bold",
                            fontFamily: "Montserrat, sans-serif",
                            marginTop: "-12px",
                            marginBottom: "20px",
                            textAlign: "center",
                          }}
                          onClick={() => {
                            setAddGroup(true);
                          }}
                        >
                          <AddIcon
                            style={{
                              marginRight: 10,
                              fontSize: "15px",
                            }}
                          />
                          Add Group
                        </p>
                      </div>
                    </div>
                  )}
                </Grid>
              </Grid>

              {!loader && allGroups?.length > 0 && (
                <Grid style={{ marginTop: 16, marginBottom: 10 }}>
                  <GroupTable
                    allGroups={allGroups}
                    page={page}
                    pageLimit={pageLimit}
                  />
                </Grid>
              )}

              {allGroups?.length === 0 && !loader && (
                <Grid container style={{ justifyContent: "center" }}>
                  <Typography
                    style={{
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                      fontSize: 17,
                      marginTop: 7,
                      fontWeight: "bolder",
                    }}
                  >
                    No Groups
                  </Typography>
                </Grid>
              )}
              {allGroups?.length > 0 && !loader && (
                <Grid container style={{ justifyContent: "center" }}>
                  <Pagination
                    className={classes.pagination}
                    count={count}
                    color="primary"
                    page={page}
                    onChange={handlePageChange}
                  />
                </Grid>
              )}
            </div>
          </div>
        </Grid>
        <AddGroup
          addGroup={addGroup}
          setAddGroup={setAddGroup}
          handleGetGroups={handleGetGroups}
        />
      </main>
      {loader && <BanterLoader />}
    </div>
  );
}

export default Group;
