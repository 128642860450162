import { responsiveProperty } from "@mui/material/styles/cssUtils";
import moment from "moment";
import ApiCall from "../api/ApiCall";
import {
  AllComplaintRoutes,
  AllSettingRoutes,
  AllSocietyRoutes,
  EntryPoints,
  ModuleAccessRoutes,
} from "../api/Routes";

//******* START API CALL ********/
export const startApiCall = (seterrorMessage, setLoader) => {
  {
    seterrorMessage !== null && seterrorMessage("");
  }
  setLoader(true);
  setTimeout(() => {
    setLoader(false);
  }, 50000);
};

//GET COMPLAINT CATEGORIES
export const getComplaintCategories = async (data) => {
  let res = await ApiCall("post", AllComplaintRoutes.getCategories, data);
  return res.success ? res.category : [];
};

//GET COMPLAINT SUB-CATEGORIES
export const getComplaintSubCategories = async (data) => {
  let res = await ApiCall("post", AllComplaintRoutes.getSubCategories, data);
  return res.success ? res.sub_category : [];
};

//GET SOCIETY SECTORS
export const getSocietySectors = async (data) => {
  let res = await ApiCall("post", AllSocietyRoutes.getSector, data);
  return res.success ? res : [];
};
//GET SOCIETY FLOORS
export const getSocietyFloors = async (data) => {
  let res = await ApiCall("post", AllSocietyRoutes.getFloors, data);
  return res.success ? res.floors : [];
};
//GET SOCIETY FLATS
export const getSocietyFlats = async (data) => {
  let res = await ApiCall("post", AllSocietyRoutes.getFlats, data);
  return res.success ? res : [];
};

//GET SOCIETY VENDORS
export const getSocietyVendors = async (data) => {
  let res = await ApiCall("post", AllSocietyRoutes.getVendors, data);
  return res.success ? res.vendor : [];
};

//GET ALL PERMISSION MODULES
export const getAllPermissionModules = async (data) => {
  let res = await ApiCall("post", ModuleAccessRoutes.checkModuleAccess, data);
  return res.success ? res.member ?? [] : [];
};
//GET ALL CONFIGURATION
export const getSocietyConfiguration = async (data) => {
  let res = await ApiCall("post", EntryPoints.configuration, data);
  return res.success ? res.config ?? [] : [];
};

//GET ALL SOCIETIES
export const getAllSocieties = async (data) => {
  let res = await ApiCall("post", AllSocietyRoutes.getSocieties, data);
  return res.success ? res.society ?? [] : [];
};

export const getAllPropertyFlatType = async (data) => {
  let res = await ApiCall("post", AllSettingRoutes.serachType, data);
  return res.success ? res.Type : [];
};

//CHECK MEMBER TYPE SUPER SUPER ADMIN
export const isMemberTypeS2Admin = (userRole) => {
  return userRole === "s2admin" ? true : false;
};

//GET MONTH
export const getMonthName = (value, isName) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return isName ? monthNames[value] : monthNames.indexOf(value);
};

//GET MONTHWISE RANGE {FORMAT EXAMPLE: 2023-07-19}
export const getMonthWiseDateRange = (month, year) => {
  let getMonthIndex = getMonthName(month, false);
  let startDate = new Date(year, getMonthIndex, 1);
  let endDate = new Date(year, getMonthIndex + 1, 0);
  const dateRange = {
    startDate: moment(startDate).format("YYYY-MM-DD"),
    endDate: moment(endDate).format("YYYY-MM-DD"),
  };
  return dateRange;
};

// RETURN VENDOR FEEDBACK AS PER STAR
export const handleVendorFeedbackAsPerRating = (rating, status) => {
  let feedBackMsg = "";
  if (status === "cancel") {
    feedBackMsg = "Not Applicable";
  } else if (status === "assigned") {
    feedBackMsg = "";
  } else {
    if (rating === 0) {
      feedBackMsg = "";
    }
    if (rating === 1) {
      feedBackMsg = "Poor";
    }
    if (rating === 2) {
      feedBackMsg = "Satisfactory";
    }
    if (rating === 3) {
      feedBackMsg = "Good";
    }
    if (rating >= 4) {
      feedBackMsg = "Very Good";
    }
  }

  return feedBackMsg;
};

//ACHIVER FOR CSV
export const handleCompareDateForSLA = (deadline, achive, status) => {
  let deadlineDate = new Date(deadline);
  let achiveDate = new Date(achive);
  let msg = "";
  if (status === "unassigned") {
    msg = "Pending";
  } else {
    if (achiveDate <= deadlineDate) {
      msg = "Achieved";
    } else {
      msg = "Not Achieved";
    }
  }
  return msg;
};

//Get complaint Status

export const getStatusClass = (status) => {
  switch (status) {
    case "unassigned":
      return "complaint-status-unassigned";
    case "assigned":
      return "complaint-status-assigned";
    case "resolved":
      return "complaint-status-resolved";
    case "closed":
      return "complaint-status-closed";
    case "cancel":
      return "complaint-status-cancel";
    default:
      return "";
  }
};
