// ******* COMPLAINT ROUTES *****
let complaintPrefix = "/complaints";

// -------- HELPDESK ------
export const navigateInitialHelpdesk = (data) => {
  return `${complaintPrefix}/helpdesk`;
};

export const navigateToComplaintTableRoute = (data) => {
  return `${complaintPrefix}/complaints/${data.complaintType}`;
};

export const navigateToComplaintDetails = (data) => {
  return `${complaintPrefix}/complaintdetails/${data.complaintType}/${data.complaintId}`;
};

// -------- CATEGORY ------
export const navigateToComplaintCategory = (data) => {
  return `${complaintPrefix}/complaintcategory`;
};
export const navigateToComplaintSubCategory = (data) => {
  return `${complaintPrefix}/subcategory/${data.categoryId}/${data.categoryName}`;
};
export const navigateToComplaintSubSubCategory = (data) => {
  return `${complaintPrefix}/subsubcategory/${data?.categoryId}/${data?.categoryName}/${data.subcategoryId}/${data.subcategoryName}`;
};
export const navigateToAdvanceVendorSetting = (data) => {
  return `${complaintPrefix}/advancevendor/${data.categoryName}/${data.categoryId}`;
};
export const navigateToPrevVendorSetting = (data) => {
  return `${complaintPrefix}/prevsettings/${data.categoryName}/${data.categoryId}`;
};
export const navigateExistingToPrevVendorSetting = (data) => {
  return `${complaintPrefix}/prevsettings/${data.categoryName}/${data.categoryId}/${data.sectorName}`;
};

export const navigateToComplaintArea = (data) => {
  return `${complaintPrefix}/area`;
};

export const navigateToComplaintSubArea = (data) => {
  return `${complaintPrefix}/subarea/${data.areaId}/${data.areaName}`;
};

// ******* APPROVAL ROUTES *****
export const navigateToComplaintApproval = (data) => {
  return `${complaintPrefix}/approval`;
};

// ******* COMMUNICATION ROUTES *****
let communicationPrefix = "/communication";

// -------- NOTICE ------
export const navigateInitialNotice = (data) => {
  return `${communicationPrefix}/allnotices`;
};

export const navigateToNoticeDetails = (data) => {
  return `${communicationPrefix}/noticeDetails/${data.noticeId}`;
};

// -------- DISCUSSION ------
export const navigateInitialDiscussions = (data) => {
  return `${communicationPrefix}/alldiscussions`;
};

export const navigateToDiscussionDetails = (data) => {
  return `${communicationPrefix}/individualdiscusscion/${data.disucssionId}`;
};

// -------- MEETING ------
export const navigateInitialMeeting = (data) => {
  return `${communicationPrefix}/meeting`;
};

export const navigateToMeetingDetails = (data) => {
  return `${communicationPrefix}/meetingdetails/${data.meetingId}`;
};

// -------- POLL ------
export const navigateInitialPoll = (data) => {
  return `${communicationPrefix}/allpoll`;
};

export const navigateToPollDetails = (data) => {
  return `${communicationPrefix}/polldetails/${data.pollId}`;
};

// ******* GATE MANAGEMENT ROUTES *****
let gatemanagementPrefix = "/gatemanagement";

// -------- VISITOR/STAFF/RESIDENT ------
export const navigateInitialVisitor = (data) => {
  return `${gatemanagementPrefix}/${data.type}`;
};

export const navigateToLog = (data) => {
  return `${gatemanagementPrefix}/${data.type}/logs/${data.id}`;
};

// ******* MIS DASHBOARD ROUTES *****
let misPrefix = "/misdashboard";

// -------- DETAILS VIEW ------
export const navigateInitialDetailsView = (data) => {
  return `${misPrefix}/detailsview`;
};
// -------- QUICK VIEW ------
export const navigateInitialQuickView = (data) => {
  return `${misPrefix}/quickview`;
};

export const navigateToGraph = (data) => {
  return `${misPrefix}/${data.type}`;
};

// ******* PEOPLE ROUTES *****
let peoplePrefix = "/people";

// -------- ADMIN ------
export const navigateToAdmin = (data) => {
  return `${peoplePrefix}/admin`;
};
// -------- MANAGER ------
export const navigateToManagerRequest = (data) => {
  return `${peoplePrefix}/manager/request`;
};
// -------- MANAGER ------
export const navigateToManager = (data) => {
  return `${peoplePrefix}/manager`;
};

// -------- RESIDENT ------
export const navigateToResident = (data) => {
  return `${peoplePrefix}/${data.status}/residents`;
};
export const navigateToResidentFamilyMember = (data) => {
  return `${peoplePrefix}/familymember/${data.residentId}`;
};
export const navigateToResidentRequestHistory = (data) => {
  return `${peoplePrefix}/request/history`;
};

// -------- MEMBER ------
export const navigateToMember = (data) => {
  return `${peoplePrefix}/membercommittee`;
};
export const navigateToAddMember = (data) => {
  return `${peoplePrefix}/addmember`;
};

export const navigateToReadWriteAccess = (data) => {
  return `${peoplePrefix}/readwriteaccess/${data.memberId}`;
};

// -------- VENDOR ------
export const navigateToVendor = (data) => {
  return `${peoplePrefix}/vendors`;
};
export const navigateToApproveVendor = (data) => {
  return `${peoplePrefix}/vendorapprove`;
};
export const navigateToDeletedVendor = (data) => {
  return `${peoplePrefix}/deletedvendors`;
};
export const navigateToVendorReview = (data) => {
  return `${peoplePrefix}/vendorreviewpage/${data.vendorId}`;
};
export const navigateToVendorDetails = (data) => {
  return `${peoplePrefix}/vendorprofile/${data.vendorId}`;
};
export const navigateToVendorComplaints = (data) => {
  return `${peoplePrefix}/vendorcomplaints/${data.complaintStatus}/${data.vendorId}`;
};

// ******* SOCIETY SETTINGS ROUTES *****
let societySettingsPrefix = "/settings";

// -------- SOCIETY MANAGEMENT ------
export const navigateToSocietyManagement = (data) => {
  return `${societySettingsPrefix}/societymanagement`;
};
export const navigateToProperty = (data) => {
  return `${societySettingsPrefix}/properties/${data.societyName}`;
};
export const navigateToFlats = (data) => {
  return `${societySettingsPrefix}/flats/${data.societyName}/${data.propertyId}/${data.propertyName}`;
};
export const navigateToFlatTimeline = (data) => {
  return `${societySettingsPrefix}/timeline/${data.societyName}/${data.propertyName}/${data.propertyId}/${data.flatName}/${data.flatId}`;
};

export const navigateToAddSocietyRequest = (data) => {
  return `${societySettingsPrefix}/society/requests`;
};

export const navigateToPropertyResident = (data) => {
  return `${societySettingsPrefix}/flats/${data.societyName}/${data.propertyId}/propertyResident/${data?.propertyName}`;
};

// -------- SOCIETY TYPE ------
export const navigateToSocietyType = (data) => {
  return `${peoplePrefix}/societytype`;
};

// ******* HELP ROUTES *****
let helpPrefix = "/help";

// -------- DAILY HELP ------
export const navigateToDailyHelp = (data) => {
  return `${helpPrefix}/dailyhelp`;
};
export const navigateToDailyHelpDetails = (data) => {
  return `${helpPrefix}/dailyhelpdetails/${data.staffType}`;
};

// -------- EMERGENCY NUMBER ------
export const navigateToEmergencyNumber = (data) => {
  return `${helpPrefix}/emergencynumber`;
};

// -------- LOCAL DIRECTORY ------
export const navigateToLocalDirectory = (data) => {
  return `${helpPrefix}/localdirectory`;
};

export const navigateToLocalDirectoryDetails = (data) => {
  return `${helpPrefix}/localdirectorydetails/${data.categoryname}/${data.directoryId}`;
};

// ********** S2 ADMIN ***********
let s2adminPrefix = "/s2admin";
export const navigateToS2AdminSocietyRequest = () => {
  return `${s2adminPrefix}/societyrequest/admin`;
};

// -------- CONFIGURATION -----------
export const navigateToConfiguration = (data) => {
  return `${s2adminPrefix}/configuration`;
};

// ----------- ADMIN APPROVAL -----------

export const navigateToApprovedApprovals = (data) => {
  return `${s2adminPrefix}/adminapproval`;
};

export const navigateToPendingApprovals = (data) => {
  return `${s2adminPrefix}/adminapproval/pending`;
};

export const navigateToAdminDetails = (data) => {
  return `${s2adminPrefix}/details/${data.adminId}`;
};

// ------------- SOCIETY REQUESTS -----------

// -------- Group ------\
export const navigateToGroups = (data) => {
  return `${peoplePrefix}/group`;
};

export const navigateToGroupDetails = (data) => {
  return `${peoplePrefix}/groupdetails/${data.grpId}`;
};
