import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import {
  Typography,
  Grid,
  Paper,
  IconButton,
  Divider,
} from "@material-ui/core";
import useStyles from "../styles";
import { Add, AddAPhoto, Close } from "@material-ui/icons";
import ChooseCategoryDropDown from "../../../GlobalComponents/DropDowns/ChooseCategoryDropDown";
import { LoadingOutlined } from "@ant-design/icons";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import { toast } from "react-toastify";
import { AllVendorRoutes } from "../../../api/Routes";
import ApiCall from "../../../api/ApiCall";
import { resolveErrorMessage } from "../../../services/service";
import ChooseVendorDropDown from "../../../GlobalComponents/DropDowns/ChooseVendorDropDown";
import MultipleSubCategory from "./MultipleSubCategory";

function AddSubVendor({
  addSubVendorModal,
  setaddSubVendorModal,
  handleGetVendor,
}) {
  const classes = useStyles();
  const [loader, setloader] = useState(false);
  const [subVendorName, setsubVendorName] = useState("");
  const [subVendorNumber, setsubVendorNumber] = useState("");
  const [subVendorEmail, setsubVendorEmail] = useState("");
  const [subVendorAddress, setsubVendorAddress] = useState("");
  const [Image, setImage] = useState("");
  const [vendor, setvendor] = useState([]);
  const [errorMsg, seterrorMsg] = useState("");
  const [subVendorCategory, setsubVendorCategory] = useState([]);
  const [subVendorSubCategory, setsubVendorSubCategory] = useState([]);
  const [searchKey, setsearchKey] = useState("");
  const [vendorImage, setvendorImage] = useState(
    "https://github.com/OlgaKoplik/CodePen/blob/master/profile.jpg?raw=true"
  );
  const [subVendorAttachFile, setsubVendorAttachFile] = useState("");

  const ImgUpload = ({ onChange, src }) => (
    <label htmlFor="photo-upload">
      <div className={classes.imgWrap}>
        <img
          for="photo-upload"
          src={src}
          height="80px"
          width="80px"
          style={{
            position: "absolute",
          }}
        />

        <input
          id="photo-upload"
          type="file"
          onChange={onChange}
          accept="image/*"
          style={{
            width: 0,
            outline: 0,
            backgroundColor: "#4b8edb",
          }}
        />
      </div>
    </label>
  );

  const handleAddSubVendor = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    if (subVendorNumber.length > 10) {
      setloader(false);
      seterrorMsg("Number Must be 10 character long");
    } else if (!subVendorCategory) {
      setloader(false);
      seterrorMsg("Please Select a Category");
    } else if (!subVendorSubCategory) {
      setloader(false);
      seterrorMsg("Please Select a Subcategory");
    } else {
      const sendData = {
        name: subVendorName,
        email: subVendorEmail,
        phone: subVendorNumber,
        categories: subVendorCategory?.map((cat) => cat?._id),
        subCategories: subVendorSubCategory?.map((sub) => sub?._id),
        image: Image,
        address: subVendorAddress,
        details: "",
        societyId: fetchSocietyId(),
        isSubVendor: true,
        mainVendorId: vendor?._id,
      };
      const res = await ApiCall("post", AllVendorRoutes.addVendor, sendData);
      if (res?.success) {
        toast.success("Sub Vendor Added Successfully");
        handleReset();
        handleGetVendor();
      } else {
        setloader(false);
        seterrorMsg(resolveErrorMessage(res.error));
      }
    }
  };

  const photoUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    setImage(file);
    reader.onloadend = () => {
      setvendorImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleReset = () => {
    setaddSubVendorModal(false);
    seterrorMsg("");
    setvendorImage(
      "https://github.com/OlgaKoplik/CodePen/blob/master/profile.jpg?raw=true"
    );
    setsubVendorName("");
    setsubVendorNumber("");
    setsubVendorEmail("");
    setsubVendorAddress("");
    setsubVendorCategory([]);
    setsubVendorSubCategory([]);
    setvendor([]);
    setloader(false);
  };

  return (
    <Modal open={addSubVendorModal}>
      <Paper
        style={{
          backgroundColor: "#e6e7f0",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
          overflow: "hidden",
          // width: "45vw",
          minWidth: "500px",
          maxWidth: "50vw",
        }}
        className={classes.ModalView}
      >
        <Grid
          container
          style={{
            textAlign: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              fontWeight: "normal",
              color: "#000",
              textAlign: "center",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: "400",
              marginLeft: 10,
              fontSize: 17,
              opacity: 1,
            }}
          >
            Add Sub Vendor
          </Typography>
          {!loader && (
            <IconButton
              onClick={() => {
                handleReset();
              }}
            >
              <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
            </IconButton>
          )}
        </Grid>
        <Divider
          style={{
            marginTop: 0,
            marginBottom: 10,
            backgroundColor: "#000",
          }}
        />
        <div style={{ height: "75vh", overflow: "auto" }}>
          <Typography
            style={{
              color: "#283f59",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: "800",
              marginTop: 2,
              marginLeft: -10,
              marginBottom: 5,
              textAlign: "center",
            }}
          >
            <AddAPhoto
              style={{
                fontSize: 22,
                color: "#2f3aa3",
                marginTop: -7,
                marginRight: 6,
              }}
            />
            Upload Photo
          </Typography>
          <Grid container style={{ justifyContent: "center" }}>
            <ImgUpload onChange={photoUpload} src={vendorImage} />
          </Grid>
          <Grid container style={{ marginLeft: -10 }}>
            <Grid container item xs={6}>
              <Typography
                style={{
                  color: "#283f59",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "600",
                  marginTop: 2,
                  marginLeft: 15,
                  marginBottom: 5,
                }}
              >
                Name
              </Typography>
            </Grid>
            <Grid container item xs={6}>
              <Typography
                style={{
                  color: "#283f59",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "600",
                  marginTop: 2,
                  marginLeft: 15,
                  marginBottom: 5,
                }}
              >
                Contact No.
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid container item xs={6}>
              <input
                style={{
                  borderRadius: 10,
                  padding: 10,
                  width: "98%",
                  border: "1px solid #416082",
                  marginBottom: 8,
                  outline: 0,
                }}
                onChange={(event) => setsubVendorName(event.target.value)}
                value={subVendorName}
                placeholder="Enter Name"
              />
            </Grid>
            <Grid container item xs={6}>
              <input
                type="number"
                style={{
                  borderRadius: 10,
                  padding: 10,
                  width: "98%",
                  border: "1px solid #416082",
                  marginBottom: 8,
                  outline: 0,
                }}
                value={subVendorNumber}
                onChange={(event) => setsubVendorNumber(event.target.value)}
                placeholder="Enter Phone Number"
              />
            </Grid>
          </Grid>

          <Grid container style={{ marginLeft: -10 }}>
            <Grid container item xs={6}>
              <Typography
                style={{
                  color: "#283f59",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "600",
                  marginTop: 2,
                  marginLeft: 15,
                  marginBottom: 5,
                }}
              >
                Email
              </Typography>
            </Grid>
            <Grid container item xs={6}>
              <Typography
                style={{
                  color: "#283f59",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "600",
                  marginTop: 2,
                  marginLeft: 15,
                  marginBottom: 5,
                }}
              >
                Address
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid container item xs={6}>
              <input
                type="email"
                style={{
                  borderRadius: 10,
                  padding: 10,
                  width: "98%",
                  border: "1px solid #416082",
                  marginBottom: 8,
                  outline: 0,
                }}
                value={subVendorEmail}
                onChange={(event) => setsubVendorEmail(event.target.value)}
                placeholder="Enter Email"
              />
            </Grid>
            <Grid container item xs={6}>
              <input
                style={{
                  borderRadius: 10,
                  padding: 10,
                  width: "98%",
                  border: "1px solid #416082",
                  marginBottom: 8,
                  outline: 0,
                }}
                value={subVendorAddress}
                onChange={(event) => setsubVendorAddress(event.target.value)}
                placeholder="Enter Address"
              />
            </Grid>
          </Grid>
          <Grid container style={{ marginLeft: -10 }}>
            <Grid container item xs={6}>
              <Typography
                style={{
                  color: "#283f59",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "600",
                  marginTop: 2,
                  marginLeft: 15,
                  marginBottom: 5,
                }}
              >
                Main Vendor
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid container item xs={6}>
              <ChooseVendorDropDown
                vendor={vendor}
                setVendor={setvendor}
                setsearchKey={setsearchKey}
                searchKey={searchKey}
              />
            </Grid>
          </Grid>
          <Grid container style={{ marginLeft: -10 }}>
            <Grid container item xs={6}>
              <Typography
                style={{
                  color: "#283f59",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "600",
                  marginTop: 2,
                  marginLeft: 15,
                  marginBottom: 5,
                }}
              >
                Category
              </Typography>
            </Grid>
            <Grid container item xs={6}>
              <Typography
                style={{
                  color: "#283f59",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "600",
                  marginTop: 2,
                  marginLeft: 15,
                  marginBottom: 5,
                }}
              >
                Subcategory
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid container item xs={6}>
              <ChooseCategoryDropDown
                setCategory={setsubVendorCategory}
                category={subVendorCategory}
                isMultiple={true}
                searchKey={searchKey}
                setsearchKey={setsearchKey}
              />
            </Grid>
            <Grid container item xs={6}>
              <MultipleSubCategory
                setSubCategory={setsubVendorSubCategory}
                subCategory={subVendorSubCategory}
                isMultiple={true}
                searchKey={searchKey}
                setsearchKey={setsearchKey}
                category={subVendorCategory}
              />
            </Grid>
          </Grid>

          <Typography
            style={{
              color: "#283f59",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: "600",
              marginTop: 10,
              marginLeft: 10,
              marginBottom: 5,
              fontSize: 14,
            }}
          >
            Upload Documents
          </Typography>
          <Grid container>
            <IconButton>
              <AddAPhoto
                style={{
                  fontSize: 30,
                  color: "#2f3aa3",
                  marginTop: -10,
                }}
              />
            </IconButton>
            <input
              type="file"
              onChange={(event) => {
                setsubVendorAttachFile(event.target.files[0]);
              }}
              id="img"
              name="img"
              style={{
                color: "#000",
                width: "90%",
                padding: 10,
                border: "1px solid #6c757d",
                borderRadius: 10,
                marginBottom: 6,
              }}
              value={subVendorAttachFile}
              multiple
            ></input>
          </Grid>
        </div>
        {/* <Grid container>
        <Grid container item xs={8}>
          <Typography
            style={{
              color: "#283f59",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: "600",
              marginTop: 2,
              marginLeft: 5,
              marginBottom: 4,
              marginLeft: 8,
            }}
          >
            Detail
          </Typography>
        </Grid>
      </Grid> */}
        {/* <Grid container>
        <Grid container item xs={12}>
          <TextArea
            className="custom"
            style={{
              height: 100,
              borderRadius: 10,
            }}
            onChange={(event) => setvendorDetails(event.target.value)}
            placeholder="Enter Detail Here.."
          />
        
        </Grid>
      </Grid> */}
        {errorMsg && (
          <Typography
            style={{ marginTop: 10, color: "red", textAlign: "center" }}
          >
            {errorMsg}
          </Typography>
        )}
        <Grid container style={{ justifyContent: "center" }}>
          <div
            class="card"
            style={{
              height: 35,
              boxShadow: "0 3px 6px #7D858D",
              width: "155px",
              backgroundColor: "#2f45c5",
              color: "#ffffff",
              cursor: "pointer",
              textAlign: "center",
              marginTop: "10px",
            }}
            onClick={() => {
              if (!loader) {
                handleAddSubVendor();
              } else {
                //NOTHING HAPPENED
              }
            }}
          >
            <div class="card-body ">
              <p
                class="card-text"
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: "-10px",
                  marginBottom: "10px",
                }}
              >
                <>
                  {loader ? (
                    <LoadingOutlined
                      style={{
                        marginRight: 10,
                        fontSize: "15px",
                      }}
                    />
                  ) : (
                    <Add
                      style={{
                        marginRight: 10,
                        fontSize: "15px",
                      }}
                    />
                  )}
                  {!loader ? "Add Vendor" : `Adding...`}
                </>
              </p>
            </div>
          </div>
        </Grid>
      </Paper>
    </Modal>
  );
}

export default AddSubVendor;
