import React, { useState, useContext, useEffect } from "react";
import { Typography, Grid } from "@material-ui/core";
import useStyles from "./styles";
import AddComplaint from "./AddComplaint/AddComplaint";
import { AuthContext } from "../../contextAPI/context";
import * as api from "../../api";
import moment from "moment";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import AddIcon from "@mui/icons-material/Add";
import { AddCircle, Cancel, ArrowRightAlt } from "@material-ui/icons";
import ComplaintGraph from "../Complaints/ComplaintGraph";
import { fetchSocietyId } from "../../contextAPI/fetchSociety";
import { handleGetCheckModulePermission } from "../../contextAPI/checkModulesPermission";
import { RestartAlt } from "@mui/icons-material";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import { resolveErrorMessage } from "../../services/service";
import SuccessAddComplaint from "./AddComplaint/SuccessAddComplaint";
import BanterLoader from "../../GlobalComponents/Loaders/BanterLoader";
import ComplaintBarGraph from "./ComplaintBarGrap";
import HeplDeskComplaintTypeCard from "./HeplDeskComplaintTypeCard";
import VerifiedIcon from "@mui/icons-material/Verified";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import { navigateToComplaintTableRoute } from "../../Navigation/Navigations";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function HelpDesk() {
  const classes = useStyles();
  var currentDate = moment(new Date()).format("YYYY-MM-DD");
  const currentMonthStartDate = moment(currentDate)
    .startOf("month")
    .format("YYYY-MM-DD");
  const currentMonthEndDate = moment(currentDate)
    .endOf("month")
    .format("YYYY-MM-DD");
  const [addComplaintModal, setaddComplaintModal] = useState(false);
  const { authState, authDispatch } = useContext(AuthContext);
  const { usermoduleswithpermissions } = authState;
  const [graphStatus, setgraphStatus] = useState("all");
  const [date, setdate] = useState(currentMonthStartDate);
  const [endDate, setendDate] = useState(currentMonthEndDate);
  const [statusGraph, setstatusGraph] = useState([]);
  const [name, setname] = useState([]);
  const [newComplaint, setnewComplaint] = useState([]);
  const [assignComplaint, setassignComplaint] = useState([]);
  const [closeComplaint, setcloseComplaint] = useState([]);
  const [resolveComplaint, setresolveComplaint] = useState([]);
  const [cancelComplaint, setcancelComplaint] = useState([]);
  const [loader, setloader] = useState(false);
  const [isReadWrite, setIsReadWrite] = useState(false);
  const [statusComplaint, setstatusComplaint] = useState(0);
  const [complaintCount, setcomplaintCount] = useState([]);
  const [isReset, setisReset] = useState(false);
  const [successComplaintModal, setSuccessComplaintModal] = useState(false);

  useEffect(async () => {
    if (usermoduleswithpermissions) {
      setIsReadWrite(
        await handleGetCheckModulePermission(
          usermoduleswithpermissions,
          "Helpdesk"
        )
      );
    }
  }, [usermoduleswithpermissions]);

  useEffect(async () => {
    handleCountComplaint();
  }, []);

  const handleCountComplaint = async () => {
    try {
      const res = await api.countComplaint({
        params: { societyId: fetchSocietyId() },
      });
      if (res?.data?.success) {
        setcomplaintCount(res.data.allCounts);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleReset = () => {
    setdate(currentMonthStartDate);
    setendDate(currentMonthEndDate);
    setgraphStatus("all");
    setisReset(true);
  };
  const handleComplaintGraph = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 60000);
    try {
      const sendData = {
        societyId: fetchSocietyId(),
        startDate: date,
        endDate: endDate,
        status: graphStatus,
      };
      const res = await api.getComplaintCount(sendData);
      if (res?.data?.success) {
        const dailyCounts = res?.data?.complaints?.dailyCounts;
        const totalCounts = res?.data?.complaints?.totalCounts;

        setstatusGraph(dailyCounts);
        let temp2 = [];
        let temp3 = [];
        let temp4 = [];
        let temp5 = [];
        let temp6 = [];

        temp2.push(totalCounts?.unassigned);
        temp3.push(totalCounts?.assigned);
        temp4.push(totalCounts?.canceled);
        temp5.push(totalCounts?.resolved);
        temp6.push(totalCounts?.closed);
        setnewComplaint(temp2);
        setassignComplaint(temp3);
        setcancelComplaint(temp4);
        setresolveComplaint(temp5);
        setcloseComplaint(temp6);
        setstatusComplaint(totalCounts);
        setisReset(false);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      resolveErrorMessage(error);
    }
  };

  useEffect(() => {
    handleComplaintGraph();
  }, [graphStatus, isReset]);

  const handleFilterComplaint = () => {
    localStorage.setItem("pageNumber", 1);
    localStorage.setItem("startdate", "");
    localStorage.setItem("enddate", "");
    localStorage.setItem("searchType", "Complaint Number");
    localStorage.setItem("searchKey", "");
    localStorage.setItem("complaintCategory", "");
    localStorage.setItem("complaintSubCategory", "");
    localStorage.setItem("sectorForFilter", "Select Sector");
    localStorage.setItem("propertyForFilter", "Select Property");
    localStorage.setItem("floorForFilter", "Select Floor");
    localStorage.setItem("flatForFilter", "Select Flat");
    localStorage.setItem("setVendorId", "");
    localStorage.setItem("setVendorName", "");
    localStorage.setItem("categoryForFilter", "");
    localStorage.setItem("subcategoryForFilter", "");
    localStorage.setItem("categoryDetails", "");
    localStorage.setItem("subcategoryDetails", "");
  };

  const handleRefresh = () => {
    handleCountComplaint();
    handleComplaintGraph();
  };

  return (
    <div className={classes.root}>
      <main
        className={classes.content}
        style={{
          opacity: loader ? 0.4 : "",
          pointerEvents: loader ? "none" : "",
          overflow: loader ? "hidden" : "auto",
          height: "100vh",
        }}
      >
        <div className={classes.toolbar} />

        <Grid item lg={12} sm={12} xs={12}>
          <div class="card" style={{ borderRadius: 6, minHeight: "89vh" }}>
            <div class="card-body">
              <Grid
                container
                style={{
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: 22,
                    fontFamily: "Montserrat, sans-serif",
                    color: "#6c757d",
                  }}
                >
                  Welcome to Complaint Management System
                </Typography>

                {isReadWrite && (
                  <div
                    style={{
                      color: "#ffffff",
                      cursor: "pointer",
                      border: "1px solid #E1E1E1",
                      borderRadius: "10px",
                    }}
                    onClick={() => {
                      setaddComplaintModal(true);
                    }}
                  >
                    <div>
                      <p
                        class="card-text"
                        style={{
                          fontSize: "15px",
                          fontFamily: "Poppins",
                          textAlign: "center",
                          color: "#03045E",
                          padding: "12px 10px",
                          fontWeight: 500,
                        }}
                      >
                        <AddIcon
                          style={{
                            marginRight: 3,
                            fontSize: "15px",
                          }}
                        />
                        Add Complaint
                      </p>
                    </div>
                  </div>
                )}
                {/* <div
                  class="card"
                  style={{
                    height: 30,
                    boxShadow: "0 3px 6px #4a6078",
                    width: "170px",
                    backgroundColor: "#2f45c5",
                    color: "#ffffff",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("/complaintreports");
                  }}
                >
                  <div class="card-body ">
                    <p
                      class="card-text"
                      style={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        fontFamily: "Montserrat, sans-serif",
                        marginTop: "-12px",
                      }}
                    >
                      <i class="fa fa-plus"></i>&nbsp; Complaint Reports
                    </p>
                  </div>
                </div> */}
              </Grid>
              <HeplDeskComplaintTypeCard
                handleFilterComplaint={handleFilterComplaint}
                complaintCount={complaintCount}
              />
              <Grid
                container
                style={{ justifyContent: "space-between", marginTop: 20 }}
              >
                <Grid container item xs={3}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 17,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                    }}
                  >
                    Mini Dashboard (
                    {date === ""
                      ? moment(new Date()).format("DD-MM-YY") + " / Today"
                      : date}
                    )
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={9}
                  style={{ justifyContent: "flex-end" }}
                >
                  <Typography
                    container
                    style={{
                      color: "#6c757d",
                      textAlign: "left",
                      fontFamily: "Montserrat, sans-serif",
                      fontWeight: "bold",
                    }}
                  >
                    Start Date :&nbsp;
                    <input
                      type="date"
                      style={{
                        borderRadius: 6,
                        padding: 8,
                        border: "2px solid #6c757d",
                        marginBottom: 8,
                        outline: 0,
                        height: 40,
                        width: "140px",
                        marginRight: 6,
                      }}
                      value={
                        date === ""
                          ? moment(new Date()).format("YYYY-MM-DD")
                          : date
                      }
                      max={moment(new Date()).format("YYYY-MM-DD")}
                      onChange={(e) => setdate(e.target.value)}
                    />
                    End Date :&nbsp;
                    <input
                      type="date"
                      style={{
                        borderRadius: 6,
                        padding: 8,
                        border: "2px solid #6c757d",
                        marginBottom: 8,
                        outline: 0,
                        height: 40,
                        width: "140px",
                        marginRight: 6,
                      }}
                      value={
                        endDate === ""
                          ? moment(new Date()).format("YYYY-MM-DD")
                          : endDate
                      }
                      max={moment(new Date()).format("YYYY-MM-DD")}
                      onChange={(e) => setendDate(e.target.value)}
                    />
                    {/* &nbsp; Time :&nbsp;
                    <input
                      type="time"
                      style={{
                        borderRadius: 6,
                        padding: 8,
                        border: "2px solid #6c757d",
                        marginBottom: 8,
                        outline: 0,
                        height: 40,
                      }}
                      value={startTime}
                      onChange={(e) => setstartTime(e.target.value)}
                    />
                    &nbsp;To&nbsp;
                    <input
                      type="time"
                      style={{
                        borderRadius: 6,
                        padding: 8,
                        border: "2px solid #6c757d",
                        marginBottom: 8,
                        outline: 0,
                        height: 40,
                      }}
                      disabled={startTime === ""}
                      value={endTime}
                      onChange={(e) => setendTime(e.target.value)}
                    /> */}
                  </Typography>
                  <div
                    style={{
                      height: 32,
                      backgroundColor: "#E9E9FF",
                      cursor: "pointer",
                      marginLeft: 10,
                      borderRadius: "10px",
                      marginTop: 5,
                    }}
                  >
                    <div>
                      <p
                        class="card-text"
                        style={{
                          fontSize: "15px",
                          fontWeight: 400,
                          fontFamily: "Poppins",
                          textAlign: "center",
                          color: "#03045E",
                          marginTop: "5px",
                          padding: "0px 15px",
                        }}
                        onClick={handleComplaintGraph}
                      >
                        <ManageSearchIcon
                          style={{
                            marginRight: 5,
                            fontSize: "20px",
                          }}
                        />
                        Apply
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      height: 32,
                      backgroundColor: "#E9E9FF",
                      cursor: "pointer",
                      marginLeft: 10,
                      borderRadius: "10px",
                      marginLeft: 10,
                      marginTop: 5,
                    }}
                  >
                    <div>
                      <p
                        class="card-text"
                        style={{
                          fontSize: "15px",
                          fontWeight: 400,
                          fontFamily: "Poppins",
                          textAlign: "center",
                          color: "#03045E",
                          marginTop: "5px",
                          padding: "0px 15px",
                        }}
                        onClick={handleReset}
                      >
                        <RestartAlt
                          style={{
                            marginRight: 10,
                            fontSize: "20px",
                          }}
                        />
                        RESET
                      </p>
                    </div>
                  </div>
                </Grid>

                {/* <Grid
                  container
                  style={{
                    justifyContent: "flex-start",
                    marginTop: 5,
                    gridGap: "8px",
                  }}
                >
                  <div
                    class="card"
                    style={{
                      height: 34,
                      boxShadow: "0 3px 6px #4a6078",
                      width: "180px",
                      color: "#ffffff",
                      cursor: "pointer",
                      borderTop: "2px solid #2f45c5",
                    }}
                    onClick={() => {
                      setgraphStatus("all");
                    }}
                  >
                    <div class="card-body ">
                      <p
                        class="card-text"
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          fontFamily: "Montserrat, sans-serif",
                          marginTop: "-12px",
                          marginBottom: "20px",
                          textAlign: "center",
                          color: "#000",
                        }}
                      >
                        All Complaints{" "}
                        <span style={{ fontSize: "15px" }}>
                          {statusComplaint?.unassigned +
                            statusComplaint?.assigned +
                            statusComplaint?.resolved +
                            statusComplaint?.closed +
                            statusComplaint?.canceled}
                        </span>
                        {graphStatus === "all" && (
                          <CheckCircle
                            style={{
                              color: "rgb(36 202 38)",
                              fontSize: "15px",
                              marginLeft: 2,
                              marginTop: -4,
                            }}
                          />
                        )}
                      </p>
                    </div>
                  </div>
                  <div
                    class="card"
                    style={{
                      height: 34,
                      boxShadow: "0 3px 6px #4a6078",
                      width: "180px",
                      color: "#ffffff",
                      cursor: "pointer",
                      borderTop: "2px solid #2f45c5",
                    }}
                    onClick={() => {
                      setgraphStatus("unassigned");
                    }}
                  >
                    <div class="card-body ">
                      <p
                        class="card-text"
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          fontFamily: "Montserrat, sans-serif",
                          marginTop: "-12px",
                          marginBottom: "20px",
                          textAlign: "center",
                          color: "#000",
                        }}
                      >
                        Unassigned{" "}
                        <span style={{ fontSize: "14px" }}>
                          {statusComplaint?.unassigned}
                        </span>
                        {graphStatus === "unassigned" && (
                          <CheckCircle
                            style={{
                              color: "rgb(36 202 38)",
                              fontSize: "14px",
                              marginLeft: 2,
                              marginTop: -4,
                            }}
                          />
                        )}
                      </p>
                    </div>
                  </div>
                  <div
                    class="card"
                    style={{
                      height: 34,
                      boxShadow: "0 3px 6px #4a6078",
                      width: "160px",
                      color: "#ffffff",
                      cursor: "pointer",
                      borderTop: "2px solid #2f45c5",
                    }}
                    onClick={() => {
                      setgraphStatus("assigned");
                    }}
                  >
                    <div class="card-body ">
                      <p
                        class="card-text"
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          fontFamily: "Montserrat, sans-serif",
                          marginTop: "-12px",
                          marginBottom: "20px",
                          textAlign: "center",
                          color: "#000",
                        }}
                      >
                        Assigned{" "}
                        <span style={{ fontSize: "15px" }}>
                          {statusComplaint?.assigned}
                        </span>
                        {graphStatus === "assigned" && (
                          <CheckCircle
                            style={{
                              color: "rgb(36 202 38)",
                              fontSize: "15px",
                              marginLeft: 2,
                              marginTop: -4,
                            }}
                          />
                        )}
                      </p>
                    </div>
                  </div>
                  <div
                    class="card"
                    style={{
                      height: 34,
                      boxShadow: "0 3px 6px #4a6078",
                      width: "160px",
                      color: "#ffffff",
                      cursor: "pointer",
                      borderTop: "2px solid #2f45c5",
                    }}
                    onClick={() => {
                      setgraphStatus("resolved");
                    }}
                  >
                    <div class="card-body ">
                      <p
                        class="card-text"
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          fontFamily: "Montserrat, sans-serif",
                          marginTop: "-12px",
                          marginBottom: "20px",
                          textAlign: "center",
                          color: "#000",
                        }}
                      >
                        Resolved{" "}
                        <span style={{ fontSize: "15px" }}>
                          {statusComplaint?.resolved}
                        </span>
                        {graphStatus === "resolved" && (
                          <CheckCircle
                            style={{
                              color: "rgb(36 202 38)",
                              fontSize: "15px",
                              marginLeft: 2,
                              marginTop: -4,
                            }}
                          />
                        )}
                      </p>
                    </div>
                  </div>
                  <div
                    class="card"
                    style={{
                      height: 34,
                      boxShadow: "0 3px 6px #4a6078",
                      width: "160px",
                      color: "#ffffff",
                      cursor: "pointer",
                      borderTop: "2px solid #2f45c5",
                    }}
                    onClick={() => {
                      setgraphStatus("closed");
                    }}
                  >
                    <div class="card-body ">
                      <p
                        class="card-text"
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          fontFamily: "Montserrat, sans-serif",
                          marginTop: "-12px",
                          marginBottom: "20px",
                          textAlign: "center",
                          color: "#000",
                        }}
                      >
                        Closed{" "}
                        <span style={{ fontSize: "15px" }}>
                          {statusComplaint?.closed}
                        </span>
                        {graphStatus === "closed" && (
                          <CheckCircle
                            style={{
                              color: "rgb(36 202 38)",
                              fontSize: "15px",
                              marginLeft: 2,
                              marginTop: -4,
                            }}
                          />
                        )}
                      </p>
                    </div>
                  </div>
                  <div
                    class="card"
                    style={{
                      height: 34,
                      boxShadow: "0 3px 6px #4a6078",
                      width: "160px",
                      color: "#ffffff",
                      cursor: "pointer",
                      borderTop: "2px solid #2f45c5",
                    }}
                    onClick={() => {
                      setgraphStatus("cancel");
                    }}
                  >
                    <div class="card-body ">
                      <p
                        class="card-text"
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          fontFamily: "Montserrat, sans-serif",
                          marginTop: "-12px",
                          marginBottom: "20px",
                          textAlign: "center",
                          color: "#000",
                        }}
                      >
                        Cancelled{" "}
                        <span style={{ fontSize: "15px" }}>
                          {statusComplaint?.canceled}
                        </span>
                        {graphStatus === "cancel" && (
                          <CheckCircle
                            style={{
                              color: "rgb(36 202 38)",
                              fontSize: "15px",
                              marginLeft: 2,
                              marginTop: -4,
                            }}
                          />
                        )}
                      </p>
                    </div>
                  </div>
                </Grid> */}
              </Grid>
              <Grid
                container
                style={{ justifyContent: "center", marginTop: 16 }}
              >
                <Grid item xs={12} sm={12} lg={10}>
                  {/* <ComplaintGraph
                    data={statusGraph}
                    name={name}
                    new={newComplaint}
                    assign={assignComplaint}
                    cancel={cancelComplaint}
                    resolve={resolveComplaint}
                    close={closeComplaint}
                  /> */}
                  <ComplaintBarGraph
                    data={statusGraph}
                    name={name}
                    new={newComplaint}
                    assign={assignComplaint}
                    cancel={cancelComplaint}
                    resolve={resolveComplaint}
                    close={closeComplaint}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>

        <AddComplaint
          addComplaintModal={addComplaintModal}
          setaddComplaintModal={setaddComplaintModal}
          handleRefresh={handleRefresh}
          setSuccessComplaintModal={setSuccessComplaintModal}
        />
        {successComplaintModal && (
          <SuccessAddComplaint
            successComplaintModal={successComplaintModal}
            setSuccessComplaintModal={setSuccessComplaintModal}
          />
        )}
      </main>
      {loader && <BanterLoader />}
    </div>
  );
}

export default HelpDesk;
