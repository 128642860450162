import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Grid } from "@material-ui/core";
import { fetchSocietyId } from "../../contextAPI/fetchSociety";
import { CheckCircle } from "@material-ui/icons";
import { getSocietyVendors } from "../../GlobalFunctions/GlobalFunctions";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    fontWeight: "bold",
    fontFamily: "Montserrat, sans-serif",
  },
  clearIndicator: {
    right: "-4px",
    marginTop: -8,
  },
}));

function ChooseSubVendor(props) {
  const { setsubVendor, subVendor, isMultiple, isDisabled, category, vendor } =
    props;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [vendors, setvendors] = useState([]);
  const [totalCount, settotalCount] = useState(0);
  const [searchKey, setsearchKey] = useState("");
  const [pageForVendor, setpageForVendor] = useState(1);

  useEffect(() => {
    if (vendor?._id) {
      handleGetVendors();
    }
  }, [pageForVendor, searchKey, vendor]);

  const handleGetVendors = async () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 40000);
    let sendData = {
      societyId: fetchSocietyId(),
      pageNumber: pageForVendor,
      searchKey,
      pageLimit: 50,
      mainVendorId: vendor?._id,
      category: category ? category?._id : "",
      isSubVendor: true,
    };
    const res = await getSocietyVendors(sendData);
    if (pageForVendor === 1) {
      setvendors(res?.vendor);
      settotalCount(res?.count);
    } else {
      setvendors((prev) => [...prev, ...res?.vendor]);
      settotalCount(res?.count);
    }
    setLoading(false);
  };

  const handleScroll = (e) => {
    const target = e.target;
    if (
      target.scrollTop + target.offsetHeight + 1 >= target.scrollHeight &&
      !loading &&
      totalCount !== vendors?.length
    ) {
      setpageForVendor((prev) => prev + 1);
    }
  };
  return (
    <Grid container item xs={12}>
      <Autocomplete
        multiple={isMultiple}
        onChange={(e, value) => {
          setsubVendor(value);
        }}
        id="country-select-demo"
        style={{
          width: "98%",
          backgroundColor: "#fff",
        }}
        freeSolo
        value={subVendor}
        classes={classes}
        options={vendors}
        disabled={!vendor?._id}
        autoHighlight
        getOptionLabel={(option) => `${option?._id ? option?.name : ""}`}
        getOptionSelected={(option, value) => option._id === value._id}
        isOptionEqualToValue={(option, value) => option._id === value._id}
        renderOption={(props, option) => {
          return (
            <div>
              <li {...props} key={option?._id}>
                {subVendor?.some((vendor) => vendor._id === option._id) && (
                  <CheckCircle
                    style={{
                      color: "rgb(36 202 38)",
                      fontSize: "14px",
                      marginRight: "5px",
                    }}
                  />
                )}
                {option.name}
              </li>
            </div>
          );
        }}
        renderInput={(params) => (
          <TextField
            required
            {...params}
            onChange={(e) => {
              setpageForVendor(1);
              setsearchKey(e.target.value);
            }}
            onBlur={() => {
              setsearchKey("");
            }}
            label={loading ? "Loading..." : `Select Sub-Vendor (${totalCount})`}
            inputProps={{
              ...params.inputProps,
            }}
          />
        )}
        ListboxProps={{
          onScroll: handleScroll,
        }}
      />
    </Grid>
  );
}

export default ChooseSubVendor;
