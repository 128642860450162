import { Typography, Grid, Paper } from "@material-ui/core";
import React, { useState, useContext } from "react";
import useStyles from "../styles";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import { AuthContext } from "../../../contextAPI/context";
import { COMPLAINT } from "../../../contextAPI/actions/auth.actions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ContentCopy } from "@mui/icons-material";
import { AssignmentTurnedIn } from "@material-ui/icons";
import { toast } from "react-toastify";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { navigateToComplaintDetails } from "../../../Navigation/Navigations";
import { getStatusClass } from "../../../GlobalFunctions/GlobalFunctions";

function CancelComplaintsTable(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { authState, authDispatch } = useContext(AuthContext);
  const { allComplaints, page, complaintType } = props;
  const location = useLocation();
  const pathName = location.pathname;
  const localPageNumber = parseInt(localStorage.getItem("pageNumber"));

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table
          sx={{ minWidth: 650 }}
          aria-label="simple table"
          style={{ tableLayout: "fixed" }}
        >
          <TableHead>
            <TableRow container>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                {" "}
                Sr. No.
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                {" "}
                Complaint ID
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                {" "}
                Complaint Title
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                {" "}
                Raised Time
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                {" "}
                Cancel Time
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                {" "}
                Complainer
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                {" "}
                Address
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                {" "}
                Category
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                {" "}
                Subcategory
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                {" "}
                Deadline
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                {" "}
                Priority
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableHeaderCell}
                sx={{ color: "white", fontFamily: "Poppins, sans-serif" }}
              >
                {" "}
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          {allComplaints?.map((complaint, index) => {
            var raisedDate = moment(complaint.createdAt).format(
              "DD/MM/YYYY - h:mm A"
            );
            var cancelDate = moment(complaint.updatedAt).format(
              "DD/MM/YYYY - h:mm A"
            );
            return (
              <TableBody>
                <TableCell
                  align="center"
                  sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
                >
                  #{(localPageNumber - 1) * 50 + (index + 1)}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
                >
                  <Grid container style={{ marginLeft: -35, width: 140 }}>
                    <Typography
                      style={{
                        textDecoration: "underline",
                        fontSize: 13,
                        cursor: "pointer",
                        color: "#42628a",
                        fontWeight: "bold",
                      }}
                      onClick={() => {
                        navigate(
                          navigateToComplaintDetails({
                            complaintType,
                            complaintId: complaint?._id,
                          })
                        );
                      }}
                    >
                      {complaint.complaintNumber}&nbsp;
                    </Typography>
                    <CopyToClipboard
                      text={complaint.complaintNumber}
                      onCopy={() => {
                        toast.success("Copy to Clipboard", {
                          position: "top-right",
                          autoClose: 500,
                          hideProgressBar: false,
                          closeOnClick: true,
                        });
                      }}
                    >
                      <ContentCopy
                        style={{
                          fontSize: 12,
                          marginTop: 3,
                          marginLeft: 3,
                          color: "#5a80ad",
                          cursor: "pointer",
                        }}
                      />
                    </CopyToClipboard>
                  </Grid>
                </TableCell>
                <TableCell
                  title={complaint?.complaintTitle}
                  align="center"
                  sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
                >
                  {complaint.complaintTitle?.length > 35
                    ? complaint.complaintTitle.slice(0, 35).concat("...")
                    : complaint.complaintTitle}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
                >
                  {raisedDate}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
                >
                  {cancelDate}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
                >
                  <p>
                    {complaint?.complainer?.name}
                    <span
                      style={{
                        padding: 5,
                        textTransform: "capitalize",
                        marginLeft: 5,
                      }}
                      class={
                        complaint?.complainer?.role === "admin" ||
                        complaint?.complainer?.role === "familymember"
                          ? "badge bg-primary"
                          : "badge bg-info"
                      }
                    >
                      {complaint?.complainer?.role}
                    </span>
                  </p>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "black",
                    fontFamily: "Poppins, sans-serif",
                    cursor: "pointer",
                  }}
                >
                  {complaint?.property?.name +
                    "-" +
                    complaint?.floor?.name +
                    "-" +
                    complaint?.flat?.number}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "black",
                    fontFamily: "Poppins, sans-serif",
                    cursor: "pointer",
                  }}
                >
                  {complaint?.category?.categoryName}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "black",
                    fontFamily: "Poppins, sans-serif",
                    cursor: "pointer",
                  }}
                >
                  {complaint?.subcomplaintCategory?.subcategoryName}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "black",
                    fontFamily: "Poppins, sans-serif",
                    cursor: "pointer",
                  }}
                >
                  {complaint?.finalDeadline
                    ? moment(complaint.finalDeadline).format(
                        "DD/MM/YYYY - h:mm A"
                      )
                    : "-"}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "black",
                    fontFamily: "Poppins, sans-serif",
                    cursor: "pointer",
                  }}
                >
                  <span
                    style={{
                      width: "55px",
                      padding: 7,
                      fontSize: 10,
                      textTransform: "uppercase",
                    }}
                    class={
                      complaint.priority === "low"
                        ? "badge bg-danger"
                        : "badge bg-success"
                    }
                  >
                    {complaint.priority}
                  </span>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "black",
                    fontFamily: "Poppins",
                    cursor: "pointer",
                  }}
                >
                  <span
                    className={`complaint-status ${getStatusClass(
                      complaint?.status
                    )}`}
                  >
                    {complaint?.status}
                  </span>
                </TableCell>
              </TableBody>
            );
          })}
        </Table>
      </TableContainer>
    </>
  );
}

export default CancelComplaintsTable;
