import React, { useState, useEffect, useContext } from "react";
import AdminTable from "./AdminTable/AdminTable";
import { Typography, Grid } from "@material-ui/core";
import useStyles from "./styles";
import * as api from "../../api";
import { fetchSocietyId } from "../../contextAPI/fetchSociety";
import { resolveErrorMessage } from "../../services/service";
import Loading from "../../contextAPI/Loading";
import RefreshIcon from "@mui/icons-material/Refresh";
import { LoadingOutlined } from "@ant-design/icons";
import { AllResidentRoutes } from "../../api/Routes";
import { toast } from "react-toastify";
import ApiCall from "../../api/ApiCall";
import MakeManagerModal from "../People/Resident/ApprovedRequests/MakeManagerModal";
import ConfirmModal from "../../contextAPI/ConfirmModal";
import { AuthContext } from "../../contextAPI/context";
import { handleGetCheckModulePermission } from "../../contextAPI/checkModulesPermission";

function Admin() {
  const classes = useStyles();
  const { authState } = useContext(AuthContext);
  const { usermoduleswithpermissions, plant } = authState;
  const [loader, setloader] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const [admins, setadmins] = useState([]);
  const [searchKey, setsearchKey] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  const [isReadWrite, setIsReadWrite] = useState(false);

  //MANGER
  const [loader2, setloader2] = useState(false);
  const [addManager, setaddManager] = useState(false);
  const [removeManager, setremoveManager] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState("SelectGroup");
  const [managerData, setmanagerData] = useState([]);

  useEffect(async () => {
    if (usermoduleswithpermissions?.length) {
      setIsReadWrite(
        await handleGetCheckModulePermission(
          usermoduleswithpermissions,
          "Admin"
        )
      );
    }
  }, [usermoduleswithpermissions]);

  const rejectConfirmation = () => {
    setaddManager(false);
    seterrorMessage("");
    setremoveManager(false);
    setSelectedGroup("SelectGroup");
  };

  //Remove Manager
  const handleRemoveManger = async () => {
    setloader2(true);
    seterrorMessage("");
    setTimeout(() => {
      setloader2(false);
    }, 40000);

    const data = {
      userId: managerData?.userId,
      addressGroupId: managerData?.managerSocieties?.[0]?.addressGroupId,
    };
    const res = await ApiCall("post", AllResidentRoutes.removeManager, data);
    if (res?.success) {
      rejectConfirmation();
      handleGetAllAdmins();
      toast.success("Manager removed successfully!");
      setloader2(false);
    } else {
      seterrorMessage(res?.error);
      setloader2(false);
    }
  };

  //Make Manager
  const handleMakeManger = async () => {
    setloader2(true);
    seterrorMessage("");
    setTimeout(() => {
      setloader2(false);
    }, 40000);
    const data = {
      userId: managerData?.userId,
      addressGroupId: selectedGroup,
      societyId: fetchSocietyId(),
    };
    const res = await ApiCall("post", AllResidentRoutes.makeManager, data);
    if (res?.success) {
      rejectConfirmation();
      handleGetAllAdmins();
      toast.success("Manager assigned successfully!");
      setloader2(false);
    } else {
      seterrorMessage(res?.error);
      setloader2(false);
    }
  };

  const handleGetAllAdmins = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      const sendData = {
        role: "admin",
        societyId: fetchSocietyId(),
        searchKey,
      };
      const res = await api.getAllUserFromSociety(sendData);
      if (res?.data?.success) {
        setadmins(res?.data?.persons?.admins);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };
  useEffect(() => {
    handleGetAllAdmins();
  }, []);

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <div className={classes.toolbar} />

        <Grid item lg={12} sm={12} xs={12}>
          <div class="card" style={{ borderRadius: 6 }}>
            <div class="card-body">
              <Grid
                container
                style={{
                  justifyContent: "space-between",
                }}
              >
                <Grid>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 22,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#6c757d",
                    }}
                  >
                    All Admin{" "}
                    {!loader ? (
                      <RefreshIcon
                        title="Refresh All Admins"
                        onClick={handleGetAllAdmins}
                        style={{
                          marginLeft: 5,
                          cursor: "pointer",
                          color: "#000",
                        }}
                      />
                    ) : (
                      <LoadingOutlined
                        title="laoding..."
                        style={{ marginLeft: 5, color: "#000" }}
                      />
                    )}
                  </Typography>
                </Grid>
              </Grid>

              <Grid style={{ marginTop: 16, marginRight: 20 }}>
                <AdminTable
                  admins={admins}
                  loader={loader}
                  setaddManager={setaddManager}
                  setremoveManager={setremoveManager}
                  setmanagerData={setmanagerData}
                  isReadWrite={isReadWrite}
                  plant={plant}
                />
              </Grid>
            </div>
          </div>
        </Grid>
      </main>
      {addManager && (
        <MakeManagerModal
          addManager={addManager}
          setaddManager={setaddManager}
          selectedGroup={selectedGroup}
          setSelectedGroup={setSelectedGroup}
          handleMakeManger={handleMakeManger}
          errorMessage={errorMessage}
          loader2={loader2}
          rejectConfirmation={rejectConfirmation}
        />
      )}
      {removeManager && (
        <ConfirmModal
          title={`${"ARE YOU SURE YOU WANT TO REMOVE SELECTED USER FROM MANAGER"}`}
          description="You can confirm by clicking below."
          loader={loader2}
          handleConfirmation={handleRemoveManger}
          rejectConfirmation={rejectConfirmation}
          isOpen={removeManager}
          errorMsg={errorMessage}
        />
      )}
      {loader && <Loading loader={loader} />}
    </div>
  );
}

export default Admin;
