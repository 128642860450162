import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Grid } from "@material-ui/core";
import { fetchSocietyId } from "../../contextAPI/fetchSociety";
import { getComplaintSubCategories } from "../../GlobalFunctions/GlobalFunctions";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    fontWeight: "bold",
    fontFamily: "Montserrat, sans-serif",
  },
  clearIndicator: {
    right: "-4px",
    marginTop: -8,
  },
}));
function ChooseSubCategoryDropDown(props) {
  const {
    setsearchKey,
    searchKey,
    setSubCategory,
    subCategory,
    isMultiple,
    category,
    isDisabled,
  } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [subCategories, setsubCategories] = useState([]);
  const [totalCount, settotalCount] = useState(0);
  const [pageForSubCategory, setpageForSubCategory] = useState(1);

  useEffect(() => {
    if (category?._id) {
      handleGetSubCategories();
    }
  }, [pageForSubCategory, searchKey, category]);

  const handleGetSubCategories = async () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 40000);
    let sendData = {
      societyId: fetchSocietyId(),
      categoryId: [category?._id],
      pageNumber: pageForSubCategory,
      searchKey,
    };
    const res = await getComplaintSubCategories(sendData);
    if (pageForSubCategory === 1) {
      setsubCategories(res?.allsubcategories);
      settotalCount(res?.totalPages);
    } else {
      setsubCategories((prev) => [...prev, ...res?.allsubcategories]);
      settotalCount(res?.totalPages);
    }
    setLoading(false);
  };

  const handleScroll = (e) => {
    const target = e.target;
    if (
      target.scrollTop + target.offsetHeight + 1 >= target.scrollHeight &&
      !loading &&
      totalCount !== subCategories?.length
    ) {
      setpageForSubCategory((prev) => prev + 1);
    }
  };

  return (
    <Grid container item xs={12}>
      <Autocomplete
        multiple={isMultiple}
        disabled={!category || isDisabled}
        onChange={(e, value) => {
          setSubCategory(value);
        }}
        id="country-select-demo"
        style={{
          width: "98%",
          backgroundColor: "#fff",
        }}
        freeSolo
        classes={classes}
        options={subCategories}
        autoHighlight
        value={subCategory}
        getOptionLabel={(option) =>
          `${option?._id ? option.subcategoryName : ""}`
        }
        renderOption={(props, option) => {
          return (
            <div>
              <li {...props} key={option.id}>
                {option.subcategoryName}
              </li>
            </div>
          );
        }}
        renderInput={(params) => (
          <TextField
            required
            {...params}
            onChange={(e) => {
              setpageForSubCategory(1);
              setsearchKey(e.target.value);
            }}
            onBlur={() => {
              setsearchKey("");
            }}
            label={
              loading ? "Loading..." : `Select SubCategory (${totalCount})`
            }
            inputProps={{
              ...params.inputProps,
            }}
          />
        )}
        ListboxProps={{
          onScroll: handleScroll,
        }}
      />
    </Grid>
  );
}

export default ChooseSubCategoryDropDown;
