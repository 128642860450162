import {
  Divider,
  Grid,
  IconButton,
  Modal,
  Paper,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import moment from "moment";
import React, { useContext, useState } from "react";
import StarIcon from "@mui/icons-material/Star";
import useStyles from "../styles";
import { useNavigate } from "react-router";
import { navigateToComplaintDetails } from "../../../../Navigation/Navigations";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { AuthContext } from "../../../../contextAPI/context";

const ComplaintLifeCycleMoreInfoModal = (props) => {
  const classes = useStyles();
  const {
    openMoreInfoModal,
    setOpenMoreInfoModal,
    selectedLifeCycleData,
    complaintType,
    loader,
    setloader,
    user,
  } = props;
  const navigate = useNavigate();
  const { authState } = useContext(AuthContext);
  const { plant } = authState;

  async function handleOnClick() {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      await navigate(
        navigateToComplaintDetails({
          complaintType,
          complaintId: selectedLifeCycleData?.subComplaintId,
        })
      );
      window.location.reload();
      setloader(false);
    } catch (error) {
      console.error("Navigation error:", error);
      setloader(false);
    }
  }

  return (
    <Modal open={openMoreInfoModal}>
      <Paper
        style={{
          backgroundColor: "#e6e7f0",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
          maxWidth: "550px",
          minWidth: "300px",
          height: "auto",
        }}
        className={classes.ModalView}
      >
        <Grid
          container
          style={{
            textAlign: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              color: "#000",
              textAlign: "center",
              fontWeight: "bold",
              marginLeft: 10,
              fontSize: 17,
              opacity: 1,
            }}
          >
            Process Details
          </Typography>

          <IconButton
            onClick={() => {
              setOpenMoreInfoModal(false);
            }}
          >
            <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
          </IconButton>
        </Grid>
        <Divider
          style={{
            marginTop: 0,
            marginBottom: 10,
            backgroundColor: "#000",
          }}
        />
        <Grid container>
          <Grid item xs={6}>
            <Typography
              style={{
                fontSize: 16,
                fontFamily: "Montserrat, sans-serif",
                color: "#000",
                fontWeight: "bold",
              }}
            >
              &nbsp;Date and Time :
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              style={{
                fontSize: 16,
                fontFamily: "Montserrat, sans-serif",
                color: "#6c757d",
                fontWeight: "bold",
              }}
            >
              {moment(selectedLifeCycleData.time).format(
                "DD/MM/YYYY - h:mm:ss A"
              )}
            </Typography>
          </Grid>
        </Grid>
        {(selectedLifeCycleData.type === "Assign vendor" ||
          selectedLifeCycleData.type === "Reassign Vendor") && (
          <Grid container style={{ marginTop: 8 }}>
            <Grid item xs={6}>
              <Typography
                style={{
                  fontSize: 16,
                  fontFamily: "Montserrat, sans-serif",
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                &nbsp;Deadline Hours :
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                style={{
                  fontSize: 16,
                  fontFamily: "Montserrat, sans-serif",
                  color: "#6c757d",
                  fontWeight: "bold",
                }}
              >
                {selectedLifeCycleData.deadline
                  ? selectedLifeCycleData.deadline
                  : "NA"}
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid container style={{ marginTop: 8 }}>
          <Grid item xs={6}>
            <Typography
              style={{
                fontSize: 16,
                fontFamily: "Montserrat, sans-serif",
                color: "#000",
                fontWeight: "bold",
              }}
            >
              &nbsp;Action :
            </Typography>
          </Grid>
          {selectedLifeCycleData.type === "Change priority" ? (
            <Grid item xs={6}>
              <Typography
                style={{
                  fontSize: 16,
                  fontFamily: "Montserrat, sans-serif",
                  color: "#6c757d",
                  fontWeight: "bold",
                }}
              >
                {selectedLifeCycleData.type} to {selectedLifeCycleData.priority}
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={6}>
              <Typography
                style={{
                  fontSize: 16,
                  fontFamily: "Montserrat, sans-serif",
                  color: "#6c757d",
                  fontWeight: "bold",
                }}
              >
                {selectedLifeCycleData.type}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid container style={{ marginTop: 8 }}>
          <Grid item xs={6}>
            <Typography
              style={{
                fontSize: 16,
                fontFamily: "Montserrat, sans-serif",
                color: "#000",
                fontWeight: "bold",
              }}
            >
              &nbsp;Updated By :
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {(selectedLifeCycleData.type === "Complaint Raised" ||
              selectedLifeCycleData.type === "Escalate to level one" ||
              selectedLifeCycleData.type === "Escalate to level two" ||
              selectedLifeCycleData.type === "Escalate to level three" ||
              selectedLifeCycleData.type === "Deadline Exceeded" ||
              selectedLifeCycleData.type === "Notify Admin") && (
              <Typography
                style={{
                  color: "grey",
                  fontWeight: "bold",
                  textTransform: "capitalize",
                }}
              >
                {selectedLifeCycleData.actionTakerRole ===
                  "System automated" && <span>System Automated</span>}
                {selectedLifeCycleData.actionTakerRole !==
                  "System automated" && (
                  <>
                    {selectedLifeCycleData.complainer.name} (
                    {selectedLifeCycleData.complainer.role})
                  </>
                )}
              </Typography>
            )}
            {(selectedLifeCycleData.type === "Change priority" ||
              selectedLifeCycleData.type === "Notify vendor" ||
              selectedLifeCycleData.type === "Notify customer" ||
              selectedLifeCycleData.type === "Vendor visit" ||
              selectedLifeCycleData?.type === "Sub-Sub Complaint Category") && (
              <Typography
                style={{
                  color: "grey",
                  fontWeight: "bold",
                  textTransform: "capitalize",
                }}
              >
                {selectedLifeCycleData.admin.name} (
                {selectedLifeCycleData?.admin?.role
                  ? selectedLifeCycleData.admin.role
                  : selectedLifeCycleData.actionTakerRole}
                )
              </Typography>
            )}
            {(selectedLifeCycleData.type === "Assign vendor" ||
              selectedLifeCycleData.type === "Mark as resolved" ||
              selectedLifeCycleData.type === "Close Complaint" ||
              selectedLifeCycleData.type === "Reopen Complaint" ||
              selectedLifeCycleData.type === "Reassign Vendor" ||
              selectedLifeCycleData.type === "Complaint Cancelled" ||
              selectedLifeCycleData.type === "Re-Assign a new complaint" ||
              selectedLifeCycleData.type === "Deadline Extension Rejected" ||
              selectedLifeCycleData.type === "Deadline Extension Approved" ||
              selectedLifeCycleData.type === "Complaint Close Approved" ||
              selectedLifeCycleData.type === "Complaint Close Rejected" ||
              selectedLifeCycleData?.type === "Extend Deadline By Admin") && (
              <Typography
                style={{
                  color: "grey",
                  fontWeight: "bold",
                  textTransform: "capitalize",
                }}
              >
                {selectedLifeCycleData.actionTakerRole !==
                  "System automated" && (
                  <>
                    {selectedLifeCycleData?.admin?.name
                      ? selectedLifeCycleData.admin.name
                      : selectedLifeCycleData.complainer.name}{" "}
                    {selectedLifeCycleData.actionTakerRole === "Vendor" ? (
                      "(Vendor)"
                    ) : (
                      <>
                        (
                        {selectedLifeCycleData?.admin?.role
                          ? selectedLifeCycleData.admin.role
                          : selectedLifeCycleData.complainer.role}
                        )
                      </>
                    )}
                  </>
                )}
                {selectedLifeCycleData.actionTakerRole ==
                  "System automated" && <>System Automated</>}
              </Typography>
            )}
            {(selectedLifeCycleData.type === "Extend Deadline" ||
              selectedLifeCycleData.type === "Close Complaint Request") && (
              <p>
                {selectedLifeCycleData?.vendorId?.name}
                {selectedLifeCycleData.actionTakerRole === "Vendor" &&
                  selectedLifeCycleData.actionTakerRole !==
                    "System automated" && (
                    <span
                      style={{
                        // width: "50px",
                        padding: 5,
                        textTransform: "capitalize",
                        marginLeft: 5,
                      }}
                      class={
                        user?.role === "admin"
                          ? "badge bg-primary"
                          : "badge bg-info"
                      }
                    >
                      Vendor
                    </span>
                  )}
              </p>
            )}
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: 8 }}>
          <Grid item xs={6}>
            <Typography
              style={{
                fontSize: 16,
                fontFamily: "Montserrat, sans-serif",
                color: "#000",
                fontWeight: "bold",
              }}
            >
              &nbsp;Updated For :
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {(selectedLifeCycleData.type === "Complaint Raised" ||
              selectedLifeCycleData.type === "Change priority" ||
              selectedLifeCycleData.type === "Mark as resolved" ||
              selectedLifeCycleData.type === "Close Complaint" ||
              selectedLifeCycleData.type === "Reopen Complaint" ||
              selectedLifeCycleData.type === "Notify customer" ||
              selectedLifeCycleData.type === "Complaint Cancelled" ||
              selectedLifeCycleData.type === "Re-Assign a new complaint" ||
              selectedLifeCycleData?.type === "Extended Deadline Withdrawn" ||
              selectedLifeCycleData?.type === "Sub-Sub Complaint Category") && (
              <Typography
                style={{
                  color: "grey",
                  fontWeight: "bold",
                  textTransform: "capitalize",
                }}
              >
                {selectedLifeCycleData.complainer.name} (
                {selectedLifeCycleData.complainer.role})
              </Typography>
            )}
            {(selectedLifeCycleData.type === "Notify Admin" ||
              selectedLifeCycleData.type === "Extend Deadline" ||
              selectedLifeCycleData.type === "Close Complaint Request") && (
              <Typography
                style={{
                  color: "grey",
                  fontWeight: "bold",
                  textTransform: "capitalize",
                }}
              >
                Management
              </Typography>
            )}
            {(selectedLifeCycleData.type === "Assign vendor" ||
              selectedLifeCycleData.type === "Reassign Vendor" ||
              selectedLifeCycleData.type === "Notify vendor" ||
              selectedLifeCycleData.type === "Vendor visit" ||
              selectedLifeCycleData.type === "Escalate to level one" ||
              selectedLifeCycleData.type === "Escalate to level two" ||
              selectedLifeCycleData.type === "Escalate to level three" ||
              selectedLifeCycleData.type === "Deadline Exceeded" ||
              selectedLifeCycleData.type === "Deadline Extension Rejected" ||
              selectedLifeCycleData.type === "Deadline Extension Approved" ||
              selectedLifeCycleData.type === "Complaint Close Approved" ||
              selectedLifeCycleData.type === "Complaint Close Rejected" ||
              selectedLifeCycleData.type === "Extend Deadline By Admin") && (
              <Typography
                style={{
                  color: "grey",
                  fontWeight: "bold",
                  textTransform: "capitalize",
                }}
              >
                {selectedLifeCycleData.vendorId.name} (Vendor)
              </Typography>
            )}
          </Grid>
        </Grid>
        {(selectedLifeCycleData.description ||
          selectedLifeCycleData.reasonDesc) && (
          <Grid container style={{ marginTop: 8 }}>
            <Grid item xs={6}>
              <Typography
                style={{
                  fontSize: 16,
                  fontFamily: "Montserrat, sans-serif",
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                &nbsp;Message :
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              className="scrollable"
              style={{ maxHeight: "200px", overflow: "auto" }}
            >
              <Typography
                style={{
                  fontSize: 16,
                  fontFamily: "Montserrat, sans-serif",
                  color: "#6c757d",
                  fontWeight: "bold",
                }}
              >
                {selectedLifeCycleData.description
                  ? selectedLifeCycleData.description
                  : selectedLifeCycleData.reasonDesc
                  ? selectedLifeCycleData.reasonDesc
                  : "-"}
              </Typography>
            </Grid>
          </Grid>
        )}
        {(selectedLifeCycleData.feedBack_message ||
          selectedLifeCycleData.vendorRating > 0) && (
          <Grid container style={{ marginTop: 8 }}>
            <Grid item xs={6}>
              <Typography
                style={{
                  fontSize: 16,
                  fontFamily: "Montserrat, sans-serif",
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                &nbsp;Ratings & Review Message :
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              className="scrollable"
              style={{ maxHeight: "200px", overflow: "auto" }}
            >
              <Typography
                style={{
                  fontSize: 16,
                  fontFamily: "Montserrat, sans-serif",
                  color: "#6c757d",
                  fontWeight: "bold",
                }}
              >
                <span
                  style={{
                    marginLeft: 6,
                    textTransform: "capitalize",
                    background: "#febb0b",
                    borderRadius: "5px",
                    fontSize: "12px",
                    fontWeight: "bold",
                    padding: "3px 7px",
                  }}
                >
                  {selectedLifeCycleData.vendorRating
                    ? selectedLifeCycleData.vendorRating
                    : "No Rating"}
                  <StarIcon style={{ fontSize: 20, marginBottom: 2 }} />
                </span>
                &nbsp;
                {selectedLifeCycleData.feedBack_message
                  ? selectedLifeCycleData.feedBack_message
                  : "No Review"}{" "}
              </Typography>
            </Grid>
          </Grid>
        )}
        {selectedLifeCycleData.type === "Re-Assign a new complaint" && (
          <Grid container style={{ marginTop: 8 }}>
            <Grid item xs={6}>
              <Typography
                style={{
                  fontSize: 16,
                  fontFamily: "Montserrat, sans-serif",
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                &nbsp;Sub Complaint Id :
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                style={{
                  fontSize: 16,
                  fontFamily: "Montserrat, sans-serif",
                  color: "#03045e",
                  fontWeight: "bold",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleOnClick();
                }}
              >
                {selectedLifeCycleData?.subComplaintNumber}
                <OpenInNewIcon
                  style={{ fontSize: "10px", marginTop: "-5px" }}
                />
              </Typography>
            </Grid>
          </Grid>
        )}
        {plant && selectedLifeCycleData?.extendDeadline && (
          <Grid container style={{ marginTop: 8 }}>
            <Grid item xs={6}>
              <Typography
                style={{
                  fontSize: 16,
                  fontFamily: "Montserrat, sans-serif",
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                &nbsp;Extended Deadline :
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                style={{
                  fontSize: 16,
                  fontFamily: "Montserrat, sans-serif",
                  color: "#6c757d",
                  fontWeight: "bold",
                }}
              >
                {selectedLifeCycleData?.extendDeadline} hours
              </Typography>
            </Grid>
          </Grid>
        )}
        {plant &&
          selectedLifeCycleData?.image?.length > 0 &&
          selectedLifeCycleData?.image?.[0] !== "" && (
            <Grid container style={{ marginTop: 8 }}>
              <Grid item xs={6}>
                <Typography
                  style={{
                    fontSize: 16,
                    fontFamily: "Montserrat, sans-serif",
                    color: "#000",
                    fontWeight: "bold",
                    marginTop: 5,
                  }}
                >
                  &nbsp;Image :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <img
                  src={selectedLifeCycleData?.image?.[0]}
                  height="60px"
                  width="70px"
                />
              </Grid>
            </Grid>
          )}
      </Paper>
    </Modal>
  );
};

export default ComplaintLifeCycleMoreInfoModal;
