import React, { useState, useContext } from "react";
import {
  Typography,
  Grid,
  Paper,
  IconButton,
  Divider,
} from "@material-ui/core";
import useStyles from "../styles";
import Modal from "@mui/material/Modal";
import { Close, AddAPhoto } from "@material-ui/icons";
import { AuthContext } from "../../../contextAPI/context";
import { resolveErrorMessage } from "../../../services/service";
import * as api from "../../../api";
import { toast } from "react-toastify";
import { VENDORS } from "../../../contextAPI/actions/auth.actions";
import { BASE_URL } from "../../../utils/base";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import EditIcon from "@mui/icons-material/Edit";
import { LoadingOutlined } from "@ant-design/icons";
import ChooseCategoryDropDown from "../../../GlobalComponents/DropDowns/ChooseCategoryDropDown";
import MultipleSubCategory from "../AddVendor/MultipleSubCategory";

function EditVendor(props) {
  const classes = useStyles();
  const { authDispatch } = useContext(AuthContext);
  const { editVendorModal, seteditVendorModal, vendor, handleGetVendor } =
    props;
  const [vendorImage, setvendorImage] = useState(
    "https://github.com/OlgaKoplik/CodePen/blob/master/profile.jpg?raw=true"
  );
  const {
    name,
    email,
    phone,
    details,
    address,
    categories,
    subcategories,
    category,
    subCategory,
    image,
  } = vendor;
  const [vendorName, setvendorName] = useState(name);
  const [vendorNumber, setvendorNumber] = useState(phone);
  const [vendorEmail, setvendorEmail] = useState(email);
  const [vendorAddress, setvendorAddress] = useState(address);
  const [vendorDetails, setvendorDetails] = useState(details);
  const [ImageEdit, setImageEdit] = useState(image);
  const [updateImg, setupdateImg] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const [loader, setloader] = useState(false);
  const [subVendorCategory, setsubVendorCategory] = useState(categories);
  const [subVendorSubCategory, setsubVendorSubCategory] =
    useState(subcategories);
  const [searchKey, setsearchKey] = useState("");

  const handleEditVendor = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      const sendData = {
        name: vendorName,
        email: vendorEmail,
        phone: vendorNumber,
        address: vendorAddress,
        societyId: fetchSocietyId(),
        details: vendorDetails || "-",
        image: ImageEdit,
        vendorId: vendor._id,
        categories: subVendorCategory?.map((cat) => cat._id) || [],
        subcategories: subVendorSubCategory?.map((sub) => sub._id) || [],
      };

      const res = await api.editVendor(sendData);
      if (res?.data?.success) {
        let updatedVendors = res.data.society.vendors.filter((vn) => {
          return vn.delete === false;
        });

        authDispatch({
          type: VENDORS,
          payload: updatedVendors,
        });
        handleGetVendor();
        toast.success("Vendor Edited Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        seteditVendorModal(false);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };

  const ImgUpload = ({ onChange, src }) => (
    <label htmlFor="photo-upload">
      <div className={classes.imgWrap}>
        <img
          for="photo-upload"
          src={src}
          height="80px"
          width="80px"
          style={{
            position: "absolute",
          }}
        />

        <input
          id="photo-upload"
          type="file"
          accept="image/*"
          onChange={onChange}
          style={{
            width: 0,
            outline: 0,
            backgroundColor: "#4b8edb",
          }}
        />
      </div>
    </label>
  );

  const photoUpload = (e) => {
    setupdateImg(true);
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    setImageEdit(file);
    reader.onloadend = () => {
      setvendorImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <Modal open={editVendorModal}>
      <Paper
        style={{
          backgroundColor: "#e6e7f0",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
          // width: "50vw",
          overflow: "hidden",
          minWidth: "500px",
          maxWidth: "50vw",
        }}
        className={classes.ModalView}
      >
        <Grid
          container
          style={{
            textAlign: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              color: "#000",
              textAlign: "center",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: "400",
              marginLeft: 10,
              fontSize: 17,
              opacity: 1,
            }}
          >
            {vendor?.isSubVendor ? "Edit Sub-Vendor" : "Edit Vendor"}
          </Typography>
          {!loader && (
            <IconButton
              onClick={() => {
                seteditVendorModal(false);
              }}
            >
              <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
            </IconButton>
          )}
        </Grid>
        <Divider
          style={{
            marginTop: 0,
            marginBottom: 10,
            backgroundColor: "#000",
          }}
        />
        <Typography
          style={{
            color: "#283f59",
            fontFamily: "Montserrat, sans-serif",
            fontWeight: "800",
            marginTop: 2,
            marginLeft: -10,
            marginBottom: 5,
            textAlign: "center",
          }}
        >
          <AddAPhoto
            style={{
              fontSize: 22,
              color: "#2f3aa3",
              marginTop: -7,
              marginRight: 6,
            }}
          />
          Upload Photo
        </Typography>
        <Grid container style={{ justifyContent: "center" }}>
          <ImgUpload
            onChange={photoUpload}
            src={
              ImageEdit && !updateImg
                ? `${BASE_URL}/user/${ImageEdit}`
                : vendorImage
            }
          />
        </Grid>
        <Grid container style={{ marginLeft: -10 }}>
          <Grid container item xs={6}>
            <Typography
              style={{
                color: "#283f59",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                marginTop: 2,
                marginLeft: 15,
                marginBottom: 5,
              }}
            >
              Name
            </Typography>
          </Grid>
          <Grid container item xs={6}>
            <Typography
              style={{
                color: "#283f59",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                marginTop: 2,
                marginLeft: 15,
                marginBottom: 5,
              }}
            >
              Contact No.
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid container item xs={6}>
            <input
              style={{
                borderRadius: 10,
                padding: 10,
                width: "98%",
                border: "1px solid #416082",
                marginBottom: 8,
                outline: 0,
              }}
              onChange={(event) => setvendorName(event.target.value)}
              placeholder="Enter Name"
              value={vendorName}
            />
          </Grid>
          <Grid container item xs={6}>
            <input
              type="number"
              style={{
                borderRadius: 10,
                padding: 10,
                width: "98%",
                border: "1px solid #416082",
                marginBottom: 8,
                outline: 0,
              }}
              onChange={(event) => setvendorNumber(event.target.value)}
              placeholder="Enter Phone Number"
              value={vendorNumber}
            />
          </Grid>
        </Grid>

        <Grid container style={{ marginLeft: -10 }}>
          <Grid container item xs={6}>
            <Typography
              style={{
                color: "#283f59",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                marginTop: 2,
                marginLeft: 15,
                marginBottom: 5,
              }}
            >
              Email
            </Typography>
          </Grid>
          <Grid container item xs={6}>
            <Typography
              style={{
                color: "#283f59",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                marginTop: 2,
                marginLeft: 15,
                marginBottom: 5,
              }}
            >
              Address
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid container item xs={6}>
            <input
              type="email"
              style={{
                borderRadius: 10,
                padding: 10,
                width: "98%",
                border: "1px solid #416082",
                marginBottom: 8,
                outline: 0,
              }}
              disabled
              onChange={(event) => setvendorEmail(event.target.value)}
              placeholder="Enter Email"
              value={vendorEmail}
            />
          </Grid>
          <Grid container item xs={6}>
            <input
              style={{
                borderRadius: 10,
                padding: 10,
                width: "98%",
                border: "1px solid #416082",
                marginBottom: 8,
                outline: 0,
              }}
              onChange={(event) => setvendorAddress(event.target.value)}
              placeholder="Enter Address"
              value={vendorAddress}
            />
          </Grid>
        </Grid>

        {/* <Grid container>
          <Grid container item xs={8}>
            <Typography
              style={{
                color: "#283f59",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                marginTop: 2,
                marginLeft: 5,
                marginBottom: 4,
                marginLeft: 8,
              }}
            >
              Detail
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid container item xs={12}>
            <TextArea
              className="custom"
              style={{
                height: 100,
                borderRadius: 10,
              }}
              onChange={(event) => setvendorDetails(event.target.value)}
              placeholder="Enter Detail Here.."
              value={vendorDetails}
            />
          
          </Grid>
        </Grid> */}
        <Grid container style={{ marginLeft: -10 }}>
          <Grid container item xs={6}>
            <Typography
              style={{
                color: "#283f59",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                marginTop: 2,
                marginLeft: 15,
                marginBottom: 5,
              }}
            >
              Category
            </Typography>
          </Grid>
          <Grid container item xs={6}>
            {vendor?.isVendor && (
              <Typography
                style={{
                  color: "#283f59",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "600",
                  marginTop: 2,
                  marginLeft: 15,
                  marginBottom: 5,
                }}
              >
                Subcategory
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid container item xs={6}>
            <ChooseCategoryDropDown
              setCategory={setsubVendorCategory}
              category={subVendorCategory}
              isMultiple={true}
              searchKey={searchKey}
              setsearchKey={setsearchKey}
            />
          </Grid>
          {vendor?.isSubVendor && (
            <Grid container item xs={6}>
              <MultipleSubCategory
                setSubCategory={setsubVendorSubCategory}
                subCategory={subVendorSubCategory}
                isMultiple={true}
                searchKey={searchKey}
                setsearchKey={setsearchKey}
                category={subVendorCategory}
              />
            </Grid>
          )}
        </Grid>
        {errorMsg && (
          <Typography
            style={{ marginTop: 10, color: "red", textAlign: "center" }}
          >
            {errorMsg}
          </Typography>
        )}
        <Grid container style={{ justifyContent: "center" }}>
          <div
            class="card"
            style={{
              height: 35,
              boxShadow: "0 3px 6px #7D858D",
              width: "155px",
              backgroundColor: "#2f45c5",
              color: "#ffffff",
              cursor: "pointer",
              textAlign: "center",
              marginTop: "10px",
            }}
            onClick={() => {
              if (!loader) {
                handleEditVendor();
              } else {
                //NOTHING HAPPENED
              }
            }}
          >
            <div class="card-body ">
              <p
                class="card-text"
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: "-10px",
                  marginBottom: "10px",
                }}
              >
                <>
                  {loader ? (
                    <LoadingOutlined
                      style={{
                        marginRight: 10,
                        fontSize: "15px",
                      }}
                    />
                  ) : (
                    <EditIcon
                      style={{
                        marginRight: 10,
                        fontSize: "15px",
                      }}
                    />
                  )}
                  {!loader ? "Update Vendor" : `Updating...`}
                </>
              </p>
            </div>
          </div>
        </Grid>
      </Paper>
    </Modal>
  );
}

export default EditVendor;
