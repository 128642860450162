import React, { useState } from "react";
import {
  Typography,
  Grid,
  Checkbox,
  Paper,
  IconButton,
  Divider,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Modal from "@mui/material/Modal";
import { Input } from "antd";
import { BASE_URL } from "../../../../../utils/base";
import { LoadingOutlined } from "@ant-design/icons";
import ApiCall from "../../../../../api/ApiCall";
import { ComplaintUpdateProcessRoutes } from "../../../../../api/Routes";
import { toast } from "react-toastify";

function NotifyVendor(props) {
  const {
    complaint,
    selectProcess,
    setselectProcess,
    classes,
    user,
    handlegetComplaintById,
    setupdateProcecssModal,
  } = props;

  const [message, setmessage] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  const [sendEmail, setsendEmail] = useState(false);
  const { TextArea } = Input;
  const [loader, setloader] = useState(false);

  const callingApi = () => {
    setloader(true);
    seterrorMessage("");
    setTimeout(() => {
      setloader(false);
    }, 40000);
  };

  const handleReset = () => {
    handlegetComplaintById();
    setupdateProcecssModal(false);
    setsendEmail(false);
    setselectProcess("Select Process");
    setmessage("");
    seterrorMessage("");
    setloader(false);
  };

  const handleNotifyVendorOrComplainer = async () => {
    if (message.trim() === "" || message?.length < 8) {
      seterrorMessage("Enter a message of at least 8 characters");
    } else {
      callingApi();

      let sendData = {};
      let sendData2 = {};
      if (selectProcess === "Notify Vendor") {
        sendData = {
          complaintId: complaint?._id,
          vendorId: complaint?.vendor?._id,
          message,
        };
        sendData2 = {
          complaintId: complaint?._id,
          complainerId: complaint?.complainer?._id,
          complaintLifeCycle: "Notify vendor",
          vendorId: complaint?.vendor?._id,
          adminId: user?._id,
          description: message,
        };
      } else {
        sendData = {
          complaintId: complaint?._id,
          userId: complaint?.complainer?._id,
          message,
        };
        sendData2 = {
          complaintId: complaint?._id,
          complainerId: complaint?.complainer?._id,
          complaintLifeCycle: "Notify customer",
          adminId: user?._id,
          description: message,
        };
      }

      const res2 = await ApiCall(
        "post",
        ComplaintUpdateProcessRoutes.complaintLifeCycle,
        sendData2
      );
      if (res2?.success) {
        if (sendEmail) {
          let res;
          if (selectProcess === "Notify Vendor") {
            res = await ApiCall(
              "post",
              ComplaintUpdateProcessRoutes.notifyVendor,
              sendData
            );
          } else {
            res = await ApiCall(
              "post",
              ComplaintUpdateProcessRoutes.notifyComplainer,
              sendData
            );
          }
        }

        handleReset();
        toast.success(
          selectProcess === "Notify Vendor"
            ? "Vendor Notified Successfully"
            : "Complainer Notified Successfully"
        );
      } else {
        setloader(false);
        seterrorMessage(res2.error);
      }
    }
  };

  return (
    <Modal
      open={
        selectProcess === "Notify Vendor" ||
        selectProcess === "Notify Complainer"
      }
    >
      <Paper
        style={{
          backgroundColor: "#e6e7f0",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
          overflow: "auto",
          minWidth: "300px",
          maxWidth: "29vw",
          pointerEvents: loader ? "none" : "",
        }}
        className={classes.ModalView}
      >
        <Grid
          container
          style={{
            textAlign: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              color: "#000",
              textAlign: "center",
              fontWeight: "normal",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: "400",
              marginLeft: 10,
              fontSize: 17,
              opacity: 1,
            }}
          >
            {selectProcess}
          </Typography>

          <IconButton
            onClick={() => {
              if (!loader) {
                seterrorMessage("");
                setmessage("");
                setselectProcess("Select Process");
              }
            }}
          >
            {!loader && (
              <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
            )}
          </IconButton>
        </Grid>
        <Divider
          style={{
            marginTop: 0,
            marginBottom: 10,
            backgroundColor: "#000",
          }}
        />
        <Grid container style={{ justifyContent: "center" }}>
          <Grid
            container
            style={{
              padding: 6,
              borderRadius: 6,
              backgroundColor: "#4b8edb",
              color: "#fff",
              textAlign: "center",
              border: "2px solid #283f59",
              width: 300,
              marginBottom: 10,
            }}
          >
            {selectProcess === "Notify Vendor" ? (
              <>
                <Grid item xs={2}>
                  <img
                    class="round"
                    src={
                      complaint?.vendor?.image
                        ? `${BASE_URL}/user/${complaint?.vendor?.image}`
                        : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                    }
                    alt="user"
                    height="50px"
                    width="50px"
                    style={{ borderRadius: 50 }}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      textDecoration: "underline",
                    }}
                  >
                    {complaint?.vendor?.name}
                  </Typography>
                  <Typography style={{ fontSize: 13 }}>
                    {complaint?.vendor?.email}
                  </Typography>
                  <Typography style={{ fontSize: 13 }}>
                    {complaint?.vendor?.phone}
                  </Typography>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={2}>
                  <img
                    class="round"
                    src={
                      complaint?.complainer?.image
                        ? `${BASE_URL}/user/${complaint?.complainer?.image}`
                        : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                    }
                    alt="user"
                    height="50px"
                    width="50px"
                    style={{ borderRadius: 50 }}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      textDecoration: "underline",
                    }}
                  >
                    {complaint?.complainer?.name}-{complaint?.complainer?.role}
                  </Typography>
                  <Typography style={{ fontSize: 13 }}>
                    {complaint?.complainer?.email}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <TextArea
          style={{
            marginTop: 10,
            height: 100,
            borderRadius: 10,
          }}
          maxLength={500}
          placeholder="Type Some Message"
          value={message}
          onChange={(event) => setmessage(event.target.value)}
        />
        {/* <Grid container>
          <Checkbox
            value={sendEmail}
            checked={sendEmail}
            size="small"
            onChange={() => {
              setsendEmail(!sendEmail);
            }}
            style={{ color: "#4b8edb" }}
          />
          <Typography
            style={{
              color: "#283f59",
              marginTop: 7.5,
              fontWeight: "bold",
              fontFamily: "Montserrat, sans-serif",
              fontSize: 15,
            }}
          >
            Check for send message on email
          </Typography>
        </Grid> */}
        {errorMessage && (
          <Typography
            style={{
              textAlign: "center",
              color: "red",
              fontWeight: "bold",
              marginTop: 10,
              fontSize: "12px",
            }}
          >
            {errorMessage}
          </Typography>
        )}
        <Grid container style={{ justifyContent: "center" }}>
          <div
            class="card"
            style={{
              height: 35,
              boxShadow: "0 3px 6px #7D858D",
              width: "140px",
              backgroundColor: "#2f45c5",
              color: "#ffffff",
              cursor: "pointer",
              marginLeft: 5,
              marginTop: 20,
            }}
            onClick={() => {
              handleNotifyVendorOrComplainer();
            }}
          >
            <div class="card-body ">
              <p
                class="card-text"
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: "-10px",
                  textAlign: "center",
                }}
              >
                {loader ? (
                  <LoadingOutlined
                    style={{
                      marginRight: 10,
                      fontSize: "15px",
                    }}
                  />
                ) : (
                  ""
                )}
                {!loader ? "Submit" : `Submitting...`}
              </p>
            </div>
          </div>
        </Grid>
      </Paper>
    </Modal>
  );
}

export default NotifyVendor;
