import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { Input } from "antd";
import useStyles from "../styles";
import { AuthContext } from "../../../contextAPI/context";
import { resolveErrorMessage } from "../../../services/service";
import * as api from "../../../api";
import { toast } from "react-toastify";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import ApiCall from "../../../api/ApiCall";
import { AllComplaintRoutes } from "../../../api/Routes";
import AddMoreComplaintModal from "./AddMoreComplaintModal";
import CategoryModalForMultipleComplaint from "./CategoryModalForMultipleComplaint";
import AddSingleComplaint from "./AddSingleComplaint";
import { Divider, Grid, IconButton, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Drawer } from "antd";
import { handleUploadInDirectAws } from "../../../utils/awsUpload";

function AddComplaint(props) {
  const classes = useStyles();
  const { authState } = useContext(AuthContext);
  const { user, plant } = authState;
  const {
    addComplaintModal,
    setaddComplaintModal,
    handleRefresh,
    setSuccessComplaintModal,
  } = props;
  const [complaintType, setcomplaintType] = useState("personal");
  const [complaintPriority, setcomplaintPriority] = useState("low");
  const [complainerId, setcomplainerId] = useState(user?._id);
  const [behalfComplaint, setbehalfComplaint] = useState(false);
  const [searchedComplainer, setsearchedComplainer] = useState([]);
  const [complainTitle, setcomplainTitle] = useState("");
  const [complainDesc, setcomplainDesc] = useState("");
  const [errorMsg, seterrorMsg] = useState("");
  const { TextArea } = Input;
  const [ComplaintAddress, setComplaintAddress] = useState("");
  const [errorMsg2, seterrorMsg2] = useState("");
  const [complainerRole, setcomplainerRole] = useState(
    user?.role === "admin" ||
      user?.role === "s2admin" ||
      user?.role === "s1admin"
      ? "Management"
      : "User"
  );
  const [findUserloader, setfindUserloader] = useState(false);

  const [floor, setfloor] = useState("Select Floor");
  const [flat, setflat] = useState("Select Flat");
  var date = new Date();
  var date1 = moment(date).format("DD-MM-YY");
  const [loader, setloader] = useState(false);
  const [addedComplaint, setaddedComplaint] = useState([]);
  const [addMoreComplaint, setaddMoreComplaint] = useState(false);
  const [errorMsg3, seterrorMsg3] = useState("");
  const [addSubMoreComplaintModal, setaddSubMoreComplaintModal] =
    useState(false);

  //selection / property Modal
  const [property, setproperty] = useState([]);
  const [searchKey, setsearchKey] = useState("");
  const [propertySearch, setpropertySearch] = useState("");
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);

  //Area & Sub Area
  const [selectedArea, setselectedArea] = useState([]);
  const [selectedSubArea, setselectedSubArea] = useState([]);

  // IMAGE
  const [image1, setimage1] = useState({ data: "", file: "" });
  const [image2, setimage2] = useState({ data: "", file: "" });

  const handleAddMoreComplaint = () => {
    seterrorMsg3("");
    let isError = false;
    addedComplaint?.map((com) => {
      if (
        com?.category?.categoryName === category?.categoryName &&
        com?.subcategory?.subcategoryName === subCategory?.subcategoryName
      ) {
        isError = true;
        seterrorMsg3(
          "you can not add complaint with same category and subcategory"
        );
      }
    });
    if (!isError) {
      setaddedComplaint([
        ...addedComplaint,
        {
          category: category,
          subcategory: subCategory,
          desc: complainDesc,
        },
      ]);
      setCategory([]);
      setSubCategory([]);
      setcomplainDesc("");
      setaddSubMoreComplaintModal(false);
    }
  };

  const handleRemoveComplaint = (index) => {
    addedComplaint.splice(index, 1);
    setaddedComplaint([...addedComplaint]);
  };
  const checkValidation = () => {
    if (
      complainTitle === "" ||
      complainDesc === "" ||
      category?.length === 0 ||
      subCategory?.length === 0 ||
      property?.length === 0 ||
      floor?.length === 0 ||
      flat?.length === 0 ||
      (plant && selectedArea?.length === 0) ||
      (plant && selectedSubArea?.length === 0)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleReset = () => {
    seterrorMsg("");
    setaddComplaintModal(false);
    setaddedComplaint([]);
    setcomplainDesc("");
    setbehalfComplaint(false);
    setcomplainerId(user?._id);
    setsearchedComplainer([]);
    setcomplainTitle("");
    setaddMoreComplaint(false);
    setcomplaintType("personal");
    setproperty([]);
    setfloor("Select Floor");
    setflat("Select Flat");
    setCategory([]);
    setSubCategory([]);
    setselectedArea([]);
    setselectedSubArea([]);
    setloader(false);
    setimage1({ data: "", file: "" });
    setimage2({ data: "", file: "" });
  };

  useEffect(() => {
    if (!behalfComplaint) {
      setfloor("Select Floor");
      setflat("Select Flat");
    }
  }, [property]);

  useEffect(() => {
    setSubCategory([]);
  }, [category]);
  useEffect(() => {
    setselectedSubArea([]);
  }, [selectedArea]);

  const handleAddComplaint = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    if (checkValidation() === false) {
      seterrorMsg("all * fields are required");
      setloader(false);
    } else {
      try {
        let isApi = true;
        let temp = [];
        if (image1?.data !== "" && image2?.data !== "") {
          try {
            const upload1 = await handleUploadInDirectAws(image1);
            const upload2 = await handleUploadInDirectAws(image2);
            temp.push(upload1.Location);
            temp.push(upload2.Location);
          } catch (error) {
            isApi = false;
            seterrorMsg(
              "Something went wrong. Image uploading failed , try again after sometimes."
            );
            setloader(false);
          }
        } else if (image1?.data !== "") {
          try {
            const upload = await handleUploadInDirectAws(image1);
            temp.push(upload.Location);
          } catch (error) {
            isApi = false;
            seterrorMsg(
              "Something went wrong. Image uploading failed , try again after sometimes."
            );
            setloader(false);
          }
        }
        if (isApi) {
          const sendData = {
            complaintTitle: complainTitle,
            complainerId:
              complainerRole === "User"
                ? searchedComplainer?._id
                : complainerId,
            complainCreaterId: user._id,
            categoryId: category?._id,
            description: complainDesc,
            societyId: fetchSocietyId(),
            subcomplaintCategoryId: subCategory?._id,
            complaintType: complaintType,
            priority: complaintPriority,
            complaintAddress: ComplaintAddress
              ? ComplaintAddress
              : "No Address",
            complainerRole: complainerRole,
            date: date1,
            image: temp,
            area: selectedArea?._id,
            subArea: selectedSubArea?._id,
            zone: selectedSubArea?.zone,
          };
          if (property?._id !== undefined) {
            sendData.propertyId = property?._id;
          }
          if (flat?._id !== undefined) {
            sendData.flatId = flat?._id;
          }

          // const formData = new FormData();
          // formData.append("complaintTitle", complainTitle);

          // formData.append(
          //   "complainerId",
          //   complainerRole === "User" ? searchedComplainer?._id : complainerId
          // );
          // formData.append("complainCreaterId", user._id);
          // formData.append("categoryId", category?._id);
          // formData.append("description", complainDesc);
          // formData.append("societyId", fetchSocietyId());
          // formData.append("subcomplaintCategoryId", subCategory?._id);
          // formData.append("complaintType", complaintType);
          // formData.append("priority", complaintPriority);
          // if (flat?._id !== undefined) {
          //   formData.append("flatId", flat?._id);
          // }
          // if (property?._id !== undefined) {
          //   formData.append("propertyId", property?._id);
          // }

          // formData.append(
          //   "complaintAddress",
          //   ComplaintAddress ? ComplaintAddress : "No Address"
          // );
          // formData.append("complainerRole", complainerRole);
          // formData.append("date", date1);

          // const tempString = JSON.stringify(temp);
          // formData.append("image", tempString);

          const res = await api.createComplaint(sendData);
          if (res?.data?.success) {
            setloader(false);
            setSuccessComplaintModal(true);
            toast.success("Add Complaint Successfully", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
            });
            handleRefresh();
            handleReset();
          }
        }
      } catch (error) {
        seterrorMsg(resolveErrorMessage(error));
        setloader(false);
      }
    }
  };

  const handleMultipleComplaint = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      addedComplaint?.map(async (com) => {
        const data = {
          complaintTitle: complainTitle.toString(),
          complainerId:
            complainerRole === "User" ? searchedComplainer?._id : complainerId,
          complainCreaterId: user._id,
          categoryId: com.category?._id,
          description: com.desc,
          societyId: fetchSocietyId(),
          subcomplaintCategoryId: com.subcategory?._id,
          complaintType: complaintType,
          priority: complaintPriority,
          flatId: flat?._id,
          propertyId: property?._id,
          complaintAddress: ComplaintAddress ? ComplaintAddress : "No Address",
          complainerRole: complainerRole,
          date: date1,
          area: selectedArea?._id,
          subArea: selectedSubArea?._id,
          zone: selectedSubArea?.zone,
        };

        const res = await api.createComplaint(data);
        if (res?.data?.success) {
          toast.success(`Add Multiple Complaint Successfully`, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
          });
          setloader(false);
        }
        setaddMoreComplaint(false);
        setaddSubMoreComplaintModal(false);
        setSuccessComplaintModal(true);
        handleRefresh();
        handleReset();
      });

      // addedComplaint?.map(async (com, index) => {
      //   const formData = new FormData();
      //   formData.append("complaintTitle", complainTitle.toString());
      //   formData.append(
      //     "complainerId",
      //     complainerRole === "User" ? searchedComplainer?._id : complainerId
      //   );
      //   formData.append("complainCreaterId", user._id);
      //   formData.append("categoryId", com.category?._id);
      //   formData.append("description", com.desc);
      //   formData.append("societyId", fetchSocietyId());
      //   formData.append("subcomplaintCategoryId", com.subcategory?._id);
      //   formData.append("complaintType", complaintType);
      //   formData.append("priority", complaintPriority);
      //   if (flat?._id !== undefined) {
      //     formData.append("flatId", flat?._id);
      //   }
      //   if (property?._id !== undefined) {
      //     formData.append("propertyId", property?._id);
      //   }

      //   formData.append(
      //     "complaintAddress",
      //     ComplaintAddress ? ComplaintAddress : "No Address"
      //   );
      //   formData.append("complainerRole", complainerRole);
      //   formData.append("date", date1);
      //   formData.append("area", selectedArea?._id);
      //   formData.append("subArea", selectedSubArea?._id);
      //   formData.append("subArea", selectedSubArea?.zone);

      //   const res = await api.createComplaint(formData);
      //   if (res?.data?.success) {
      //     toast.success(`Add Multiple Complaint Successfully`, {
      //       position: "top-right",
      //       autoClose: 2000,
      //       hideProgressBar: false,
      //       closeOnClick: true,
      //     });
      //     setloader(false);
      //   }
      //   setaddMoreComplaint(false);
      //   setaddSubMoreComplaintModal(false);
      //   setSuccessComplaintModal(true);
      //   handleRefresh();
      //   handleReset();
      // });
    } catch (error) {
      seterrorMsg(resolveErrorMessage(error));
      setloader(false);
    }
  };

  const handleFindUser = async () => {
    setfindUserloader(true);
    setTimeout(() => {
      setfindUserloader(false);
    }, 40000);
    seterrorMsg2("");
    const res = await ApiCall(
      "post",
      AllComplaintRoutes.searchUserInAddComplaint,
      {
        userId: complainerId,
      }
    );
    if (res?.success) {
      setsearchedComplainer(res?.searchUser);
      setproperty(res.searchUser?.property);
      setfloor(res.searchUser?.floor);
      setflat(res.searchUser?.flat);
      setcomplainerRole(
        res.searchUser.role === "admin" ? "Management" : "User"
      );
      setfindUserloader(false);
    } else {
      setfindUserloader(false);
      seterrorMsg2(res.error);
    }
  };

  return (
    <>
      <Drawer
        width={610}
        placement="right"
        closable={true}
        open={addComplaintModal}
        onClose={handleReset}
        style={{ overflow: "hidden", height: "100vh" }}
      >
        <Grid
          container
          style={{
            textAlign: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              fontWeight: "normal",
              color: "#000000",
              fontFamily: "Poppins",
              textAlign: "center",
              marginLeft: 10,
              fontSize: 17,
              opacity: 1,
              fontSize: "20px",
            }}
          >
            {addMoreComplaint
              ? "Add multiple complaint in same address"
              : "Add Complaint"}
          </Typography>

          <IconButton
            onClick={() => {
              handleReset();
            }}
          >
            {!loader && (
              <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
            )}
          </IconButton>
        </Grid>
        <Divider
          style={{
            marginTop: 0,
            marginBottom: 10,
            backgroundColor: "#000",
          }}
        />
        {/* SINGLE COMPLAINT MODAL*/}
        {!addMoreComplaint && (
          <AddSingleComplaint
            addComplaintModal={addComplaintModal}
            classes={classes}
            addMoreComplaint={addMoreComplaint}
            loader={loader}
            complaintType={complaintType}
            setcomplaintType={setcomplaintType}
            setComplaintAddress={setComplaintAddress}
            setcomplainerId={setcomplainerId}
            setbehalfComplaint={setbehalfComplaint}
            behalfComplaint={behalfComplaint}
            setproperty={setproperty}
            setfloor={setfloor}
            setflat={setflat}
            user={user}
            seterrorMsg2={seterrorMsg2}
            handleFindUser={handleFindUser}
            checkValidation={checkValidation}
            handleAddMoreComplaint={handleAddMoreComplaint}
            addSubMoreComplaintModal={addSubMoreComplaintModal}
            handleReset={handleReset}
            setcomplainTitle={setcomplainTitle}
            complainerId={complainerId}
            searchedComplainer={searchedComplainer}
            errorMsg2={errorMsg2}
            property={property}
            floor={floor}
            flat={flat}
            TextArea={TextArea}
            setcomplainDesc={setcomplainDesc}
            errorMsg={errorMsg}
            searchKey={searchKey}
            setsearchKey={setsearchKey}
            setCategory={setCategory}
            category={category}
            seterrorMsg={seterrorMsg}
            handleAddComplaint={handleAddComplaint}
            subCategory={subCategory}
            setSubCategory={setSubCategory}
            setaddMoreComplaint={setaddMoreComplaint}
            complainTitle={complainTitle}
            complainDesc={complainDesc}
            setsearchedComplainer={setsearchedComplainer}
            findUserloader={findUserloader}
            setcomplaintPriority={setcomplaintPriority}
            complaintPriority={complaintPriority}
            image1={image1}
            setimage1={setimage1}
            image2={image2}
            setimage2={setimage2}
            isPlant={plant}
            selectedArea={selectedArea}
            setselectedArea={setselectedArea}
            selectedSubArea={selectedSubArea}
            setselectedSubArea={setselectedSubArea}
            setpropertySearch={setpropertySearch}
            propertySearch={propertySearch}
          />
        )}

        {/* MULTIPLE COMPLAINT MODAL*/}
        {addMoreComplaint && (
          <AddMoreComplaintModal
            complainTitle={complainTitle}
            addMoreComplaint={addMoreComplaint}
            property={property}
            floor={floor}
            flat={flat}
            handleRemoveComplaint={handleRemoveComplaint}
            handleMultipleComplaint={handleMultipleComplaint}
            addedComplaint={addedComplaint}
            setaddSubMoreComplaintModal={setaddSubMoreComplaintModal}
            loader={loader}
            addComplaintModal={addComplaintModal}
          />
        )}
      </Drawer>

      {/* ADD CATEGORY OR SUBCATEGORY FOR MULTIPLE COMPLAINT */}

      <CategoryModalForMultipleComplaint
        addSubMoreComplaintModal={addSubMoreComplaintModal}
        seterrorMsg3={seterrorMsg3}
        setCategory={setCategory}
        setSubCategory={setSubCategory}
        setcomplainDesc={setcomplainDesc}
        setaddSubMoreComplaintModal={setaddSubMoreComplaintModal}
        loader={loader}
        category={category}
        searchKey={searchKey}
        setsearchKey={setsearchKey}
        complainDesc={complainDesc}
        errorMsg3={errorMsg3}
        handleAddMoreComplaint={handleAddMoreComplaint}
        classes={classes}
        subCategory={subCategory}
        TextArea={TextArea}
      />
    </>
  );
}

export default AddComplaint;
