import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

function VendorWorkGraph(props) {
  const options = {
    tooltips: { enabled: false },
    hover: { mode: null },
  };
  const data = {
    labels: [`Within Time ${props.onTime}`, `Delayed work ${props.notOnTime}`],
    datasets: [
      {
        label: "Within Time , Delayed Work",
        data: [props.onTime, props.notOnTime],
        backgroundColor: ["#2f3aa3", "#4b8edb"],
        borderColor: ["#fff", "#fff"],
        borderWidth: 1,
      },
    ],
  };
  return (
    <div style={{ height: "250px" }}>
      <Pie
        data={data}
        options={{
          responsive: true,
          maintainAspectRatio: false,
        }}
      />
    </div>
  );
}

export default VendorWorkGraph;
