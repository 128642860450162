import React, { useEffect, useState } from "react";
import {
  Typography,
  Paper,
  Grid,
  Modal,
  IconButton,
  Divider,
} from "@material-ui/core";
import { Close, ErrorOutline } from "@material-ui/icons";
import SearchAddress from "../SearchAddress/SearchAddress";
import ChooseFloorDropDown from "../DropDowns/ChooseFloorDropDown";
import ChooseFlatDropDown from "../DropDowns/ChooseFlatDropDown";
import ChooseSectorDropDown from "../DropDowns/ChooseSectorDropDown";
import { handlePropertyName } from "../../contextAPI/NamingConvention";

function AddressFilterModal(props) {
  const {
    isOpenAddressFilterModal,
    setisOpenAddressFilterModal,
    sector,
    setsector,
    property,
    setproperty,
    floor,
    setfloor,
    flat,
    setflat,
    isLocal,
    isSector,
    handleFilterByAddress,
  } = props;

  const [errorMsg, seterrorMsg] = useState("");
  const [searchKey, setsearchKey] = useState("");

  const handleReset = () => {
    setisOpenAddressFilterModal(false);
    seterrorMsg("");
    if (isSector) {
      setsector("Select Sector");
    }
    setproperty("Select Property");
    setfloor("Select Floor");
    setflat("Select Flat");
  };

  const checkInvalid = () => {
    if (
      (property || property === null) &&
      floor === "Select Floor" &&
      flat === "Select Flat" &&
      property !== "Select Property"
    ) {
      return property?._id ? false : true;
    }
  };

  const handleFilteredWithLocalSave = () => {
    if (checkInvalid()) {
      seterrorMsg("Please select valid details");
    } else {
      localStorage.setItem("sectorForFilter", sector);
      localStorage.setItem(
        "propertyForFilter",
        property?._id ? property?._id : property
      );
      localStorage.setItem(
        "propertyDetails",
        property?.name ? property?.name : property
      );
      localStorage.setItem("floorForFilter", floor?._id ? floor?._id : floor);
      localStorage.setItem("floorName", floor?.name ? floor?.name : floor);
      localStorage.setItem("flatForFilter", flat?._id ? flat?._id : flat);
      localStorage.setItem("flatName", flat?.name ? flat?.name : flat);
      handleFilterByAddress();
      setisOpenAddressFilterModal(false);
    }
  };
  useEffect(() => {
    seterrorMsg("");
    setfloor("Select Floor");
    setflat("Select Flat");
  }, [property]);

  return (
    <Modal open={isOpenAddressFilterModal}>
      <Paper
        style={{
          position: "absolute",
          top: "45%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          padding: 20,
          outline: 0,
          width: 550,
          backgroundColor: "#e6e7f0",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
        }}
      >
        <Grid
          container
          stickyHeaders={true}
          style={{
            textAlign: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              color: "#000000",
              fontWeight: 500,
              fontFamily: "Poppins",
              fontSize: "20px",
              textAlign: "center",
              marginLeft: 10,
              fontSize: 17,
              opacity: 1,
            }}
          >
            Search By Address
          </Typography>

          <IconButton
            onClick={() => {
              if (!isLocal) {
                setisOpenAddressFilterModal(false);
              } else {
                handleReset();
              }
            }}
          >
            <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
          </IconButton>
        </Grid>
        <Divider
          style={{
            marginTop: 0,
            marginBottom: 10,
            backgroundColor: "#000",
          }}
        />

        <Grid
          container
          style={{ justifyContent: "space-between", marginTop: 8 }}
        >
          {isSector && (
            <Grid item xs={6}>
              <Typography
                style={{
                  color: "#979797",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "18px",
                  marginLeft: 4,
                  marginBottom: 4,
                  marginTop: 8,
                }}
              >
                Sector :&nbsp;
              </Typography>
            </Grid>
          )}

          <Grid item xs={6}>
            <Typography
              style={{
                color: "#979797",
                fontFamily: "Poppins",
                fontWeight: "400",
                fontSize: "18px",
                marginLeft: 4,
                marginBottom: 4,
                marginTop: 8,
              }}
            >
              {handlePropertyName()} :&nbsp;
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          style={{ justifyContent: "space-between", marginTop: 8 }}
        >
          {isSector && (
            <Grid item xs={6}>
              <ChooseSectorDropDown
                sector={sector}
                setsector={setsector}
                isLocal={isLocal}
              />
            </Grid>
          )}

          <Grid item xs={isSector ? 6 : 12}>
            <SearchAddress
              searchKey={searchKey}
              setsearchKey={setsearchKey}
              setPropertyId={setproperty}
              propertyId={property}
              isMultiple={false}
              sector={sector}
              isLocal={isLocal}
            />
          </Grid>
        </Grid>
        <Grid
          container
          style={{ justifyContent: "space-between", marginTop: 8 }}
        >
          <Grid item xs={6}>
            <Typography
              style={{
                color: "#979797",
                fontFamily: "Poppins",
                fontWeight: "400",
                fontSize: "18px",
                marginLeft: 4,
                marginBottom: 4,
                marginTop: 8,
              }}
            >
              Floor :&nbsp;
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              style={{
                color: "#979797",
                fontFamily: "Poppins",
                fontWeight: "400",
                fontSize: "18px",
                marginLeft: 4,
                marginBottom: 4,
                marginTop: 8,
              }}
            >
              Flat :&nbsp;
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          style={{ justifyContent: "space-between", marginTop: 8 }}
        >
          <Grid item xs={6}>
            <ChooseFloorDropDown
              property={property}
              floor={floor}
              setfloor={setfloor}
              isLocal={isLocal}
            />
          </Grid>
          <Grid item xs={6}>
            <ChooseFlatDropDown
              floor={floor}
              setflat={setflat}
              flat={flat}
              isLocal={isLocal}
            />
          </Grid>
        </Grid>
        {/* {isSector && (
          <Grid
            container
            style={{ justifyContent: "flex-start", marginTop: 8 }}
          >
            <Grid container item xs={4}>
              <Typography
                style={{
                  color: "#283f59",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "800",
                  fontSize: 14,
                  marginLeft: 4,
                  marginBottom: 4,
                  marginTop: 15,
                }}
              >
                Sector :
              </Typography>
            </Grid>
            <Grid container item xs={8}>
              <ChooseSectorDropDown
                sector={sector}
                setsector={setsector}
                isLocal={isLocal}
              />
            </Grid>
          </Grid>
        )}
        <Grid container style={{ justifyContent: "flex-start", marginTop: 8 }}>
          <Grid container item xs={4}>
            <Typography
              style={{
                color: "#283f59",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "800",
                fontSize: 14,
                marginLeft: 4,
                marginBottom: 4,
                marginTop: 15,
              }}
            >
              Property :
            </Typography>
          </Grid>
          <Grid container item xs={8}>
            <SearchAddress
              searchKey={searchKey}
              setsearchKey={setsearchKey}
              setPropertyId={setproperty}
              propertyId={property}
              isMultiple={false}
              sector={sector}
              isLocal={isLocal}
            />
          </Grid>
        </Grid>
        <Grid container style={{ justifyContent: "flex-start", marginTop: 8 }}>
          <Grid container item xs={4}>
            <Typography
              style={{
                color: "#283f59",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "800",
                fontSize: 14,
                marginLeft: 4,
                marginBottom: 4,
                marginTop: 15,
              }}
            >
              Floor :
            </Typography>
          </Grid>
          <Grid container item xs={8}>
            <ChooseFloorDropDown
              property={property}
              floor={floor}
              setfloor={setfloor}
              isLocal={isLocal}
            />
          </Grid>
        </Grid>
        <Grid container style={{ justifyContent: "flex-start", marginTop: 8 }}>
          <Grid container item xs={4}>
            <Typography
              style={{
                color: "#283f59",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "800",
                fontSize: 14,
                marginLeft: 4,
                marginBottom: 4,
                marginTop: 15,
              }}
            >
              Flat :
            </Typography>
          </Grid>
          <Grid container item xs={8}>
            <ChooseFlatDropDown
              floor={floor}
              setflat={setflat}
              flat={flat}
              isLocal={isLocal}
            />
          </Grid>
        </Grid> */}
        {errorMsg && (
          <Typography
            style={{
              marginTop: 10,
              color: "red",
              textAlign: "center",
              fontSize: "13px",
              fontWeight: "bold",
            }}
          >
            <ErrorOutline style={{ marginRight: 3 }} />
            {errorMsg}
          </Typography>
        )}
        <Grid container style={{ justifyContent: "center" }}>
          <div
            class="card"
            style={{
              backgroundColor: "#3862DD",
              borderRadius: 10,
              color: "#ffffff",
              cursor: "pointer",
              marginLeft: 5,
              marginTop: 20,
            }}
            onClick={() => {
              if (isLocal) {
                handleFilteredWithLocalSave();
              } else {
                if (checkInvalid()) {
                  seterrorMsg("Please select valid details");
                } else {
                  setisOpenAddressFilterModal(false);
                }
              }
            }}
          >
            <div class="card-body ">
              <p
                class="card-text"
                style={{
                  fontSize: "18px",
                  fontWeight: 500,
                  fontFamily: "Poppins",
                  textAlign: "center",
                  padding: "1px 20px",
                }}
              >
                {/* <SearchIcon
                  style={{
                    marginRight: 10,
                    fontSize: "25px",
                  }}
                /> */}
                {!isLocal ? "Close" : "Search"}
              </p>
            </div>
          </div>
        </Grid>
      </Paper>
    </Modal>
  );
}

export default AddressFilterModal;
