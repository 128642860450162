import {
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Paper,
  Select,
  Typography,
} from "@material-ui/core";
import {
  Close,
  CloudDownloadOutlined,
  SentimentDissatisfied,
} from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import ApiCall from "../../../api/ApiCall";
import { ComplaintUpdateProcessRoutes } from "../../../api/Routes";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import { Apartment, Category, FilterList, History } from "@mui/icons-material";
import moment from "moment";
import { ExportJsonCsv } from "react-export-json-csv";
import { LoadingOutlined } from "@ant-design/icons";
import {
  handleCompareDateForSLA,
  handleVendorFeedbackAsPerRating,
} from "../../../GlobalFunctions/GlobalFunctions";
import { complaintModalCsvHeader } from "../../../GlobalFunctions/Const-Values";
import Papa from "papaparse";

function CsvForComplaintModal(props) {
  const {
    downloadCsvModalOpen,
    setdownloadCsvModalOpen,
    user,
    valueRef,
    fetchComplaints,
    setfetchComplaints,
    plant
  } = props;
  var date = moment(new Date()).format("YYYY-MM-DD");

  const extraHeadersForPlant = [
    {
      key: "area",
      name: "Area",
    },
    {
      key: "subarea",
      name: "Subarea",
    },
    {
      key: "zone",
      name: "Zone",
    },
  ];

  const complaintStatus = [
    "All",
    "Assigned",
    "Unassigned",
    "Resolved",
    "Closed",
    "Cancel",
  ];

  const [selectStatus, setselectStatus] = useState("All");
  const [csvData, setcsvData] = useState([]);
  var newDate = moment(new Date()).add(-1, "months").format("YYYY-MM-DD");
  const [errorMessage, seterrorMessage] = useState("");
  // FOR DOWNLOAD
  const pageSize = 511;
  const [page, setpage] = useState(1);
  const [totalCounts, settotalCounts] = useState(0);
  const [loader, setloader] = useState(false);

  const resetData = () => {
    seterrorMessage("");
    setfetchComplaints([]);
    setcsvData([]);
    valueRef.current = true;
    setdownloadCsvModalOpen(false);
    setpage(1);
    settotalCounts(0);
    setselectStatus("All");
    setloader(false);
  };

  const createCsvData = (allcomplaints) => {
    setloader(true);
    let temp = [];
    allcomplaints?.map((cl, index) => {
      var complaintCreatedDate = moment(cl.createdAt).format("DD/MM/YYYY");
      var complaintCreatedTime = moment(cl.createdAt).format("h:mm:ss A");
      var complaintAssingedDate = moment(cl.assignedTime).format(
        "DD/MM/YYYY - h:mm A"
      );
      var complaintClosedFullDate = moment(cl.closedTime).format(
        "DD/MM/YYYY - h:mm A"
      );
      var complaintClosedDate = moment(cl.closedTime).format("DD/MM/YYYY");
      var complaintClosedTime = moment(cl.closedTime).format("h:mm:ss A");

      let formattedComplaintNumber = String(cl?.complaintNumber).trim();
      let complaintData = {
        number:`\t${cl?.complaintNumber?.toString()}`,
        index: index + 1,
        createdDate: complaintCreatedDate,
        createdTime: complaintCreatedTime,
        complainer: cl?.complainer?.name,
        complaintFor: cl?.property?.isResidential
          ? "RESIDENTIAL BUILDINGS"
          : "COMPANY/PUBLIC BUILDINGS",
        buildingType: cl?.property?.type,
        unit: cl?.property?.name?.includes("Road")
          ? cl?.flat?.number
          : cl?.flat?.name,
        occupancy: "",
        contactNo: cl?.complainer?.phone,
        empId: cl?.complainerEmpId ? cl?.complainerEmpId : "",
        outsideNo: "",
        empName: cl?.complainer?.name,
        outsiderName: "",
        quarterStatus: "",
        category: cl?.category?.categoryName,
        categoryType: cl?.subcomplaintCategory?.subcategoryName,
        priority: cl?.priority,
        details: cl?.description,
        remarks: "",
        assignmentStatus: cl?.vendor ? "ASSIGNED" : "",
        assignmentRemarks: "",
        contractor: cl?.vendor?.name, //populate hoyega
        closingRemarks: cl?.closingRemarks ? cl?.closingRemarks : "",
        finalStatus: cl?.status,
        quality: handleVendorFeedbackAsPerRating(
          cl?.complaintRating,
          cl?.status
        ),
        action: cl?.vendor ? "process" : "",
        assignDate: cl?.assignedTime ? complaintAssingedDate : "",
        closeDate: cl?.status === "closed" ? complaintClosedFullDate : "",
        closedDt: cl?.status === "closed" ? complaintClosedDate : "",
        closedTm: cl?.status === "closed" ? complaintClosedTime : "",
        status: cl?.status,
        address: cl?.property?.name?.includes("Road")
          ? cl?.flat?.number
          : cl?.flat?.name,
        sector: cl?.sector,
        expiryDt: cl?.finalDeadline
          ? moment(cl.finalDeadline).format("DD/MM/YYYY")
          : "",
        expiryTime: cl?.finalDeadline
          ? moment(cl.finalDeadline).format("h:mm:ss A")
          : "",
        goal: handleCompareDateForSLA(
          cl.finalDeadline,
          cl?.status === "resolved"
            ? cl?.resolveTime
            : cl?.status === "closed"
            ? cl?.closedTime
            : cl?.cancelledTime,
          cl?.status
        ),
        expiryTargetDate: cl?.finalDeadline
          ? moment(cl.finalDeadline).format("DD/MM/YYYY - h:mm A")
          : "",
        expiryDays: cl?.subcomplaintCategory?.deadline,
        itemType: "Item",
        path: `https://dashboard.sociohood.com/complaints/complaintdetails/${selectStatus?.toLocaleLowerCase()}/${
          cl?._id
        }`,
        complainDate: "",
      }
      if(plant){
        complaintData.area = cl?.area?.name || "";
        complaintData.subarea = cl?.subArea?.name || "";
        complaintData.zone = cl?.zone || "";
      }
      console.log("complasi",complaintData);
      return temp.push(complaintData)
    });
    setcsvData([...temp]);
    setloader(false);
  };

  const callApi = async () => {
    seterrorMessage("");
    setloader(true);
    if (valueRef.current === null) {
      let totalPages = totalCounts;
      let page = 2;
      const sendData = {
        status: selectStatus === "All" ? "" : selectStatus?.toLocaleLowerCase(),
        societyId: fetchSocietyId(),
        startDate:
          localStorage.getItem("startdate") === ""
            ? newDate
            : localStorage.getItem("startdate"),
        endDate:
          localStorage.getItem("enddate") === ""
            ? date
            : localStorage.getItem("enddate"),
        categoryId:
          localStorage.getItem("categoryForFilter") !== "Select Category"
            ? localStorage.getItem("categoryForFilter")
            : "",

        subcategoryId:
          localStorage.getItem("subcategoryForFilter") !== "Select Subcategory"
            ? localStorage.getItem("subcategoryForFilter")
            : "",
        property:
          localStorage.getItem("propertyForFilter") !== "Select Property"
            ? localStorage.getItem("propertyForFilter")
            : "",

        floor:
          localStorage.getItem("floorForFilter") !== "Select Floor"
            ? localStorage.getItem("floorForFilter")
            : "",

        flat:
          localStorage.getItem("flatForFilter") !== "Select Flat"
            ? localStorage.getItem("flatForFilter")
            : "",
        pageNumber: 1,
        pageSize: pageSize,
        adminId: user._id,
      };
      const res = await ApiCall(
        "post",
        ComplaintUpdateProcessRoutes.getDataForCsv,
        sendData
      );

      if (res.success) {
        settotalCounts(res.complaint.totalPages);
        totalPages = res.complaint.count;
        setloader(false);
        if (res.complaint.complaints?.length === 0) {
          seterrorMessage("No complaints found for export");
        }
        setfetchComplaints(res.complaint.complaints);
        createCsvData(res.complaint.complaints);
      }

      while (page <= totalPages && valueRef.current === null) {
        const sendData2 = {
          status:
            selectStatus === "All" ? "" : selectStatus?.toLocaleLowerCase(),
          societyId: fetchSocietyId(),
          startDate:
            localStorage.getItem("startdate") === ""
              ? newDate
              : localStorage.getItem("startdate"),
          endDate:
            localStorage.getItem("enddate") === ""
              ? date
              : localStorage.getItem("enddate"),
          categoryId:
            localStorage.getItem("categoryForFilter") !== "Select Category"
              ? localStorage.getItem("categoryForFilter")
              : "",

          subcategoryId:
            localStorage.getItem("subcategoryForFilter") !==
            "Select Subcategory"
              ? localStorage.getItem("subcategoryForFilter")
              : "",
          property:
            localStorage.getItem("propertyForFilter") !== "Select Property"
              ? localStorage.getItem("propertyForFilter")
              : "",

          floor:
            localStorage.getItem("floorForFilter") !== "Select Floor"
              ? localStorage.getItem("floorForFilter")
              : "",

          flat:
            localStorage.getItem("flatForFilter") !== "Select Flat"
              ? localStorage.getItem("flatForFilter")
              : "",
          pageNumber: page,
          pageSize: pageSize,
          adminId: user._id,
        };
        const res = await ApiCall(
          "post",
          ComplaintUpdateProcessRoutes.getDataForCsv,
          sendData2
        );

        if (res.success) {
          setfetchComplaints((prev) => [...prev, ...res.complaint.complaints]);
          setloader(false);
        }
        page++;
        setpage(page);
      }
    }
  };

  useEffect(() => {
    createCsvData(fetchComplaints);
  }, [page]);

  const handleExportCSV = () => {
    const csv = Papa.unparse({
      fields: ["complaintNumber"], // Specify field names
      data: csvData.map((item) => ({
        complaintNumber: item.complaintNumber.toString(), // Ensure complaint number is a string
      })),
    });

    // Create a blob with the CSV data
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    // Create a temporary anchor element to trigger the download
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute("download", "complaints.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Modal
      open={downloadCsvModalOpen}
      // style={{ pointerEvents: loader ? "none" : "" }}
    >
      <Paper
        style={{
          backgroundColor: "#e6e7f0",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
          overflow: "hidden",
          width: 580,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          padding: 10,
          outline: 0,
        }}
      >
        <Grid
          container
          style={{
            textAlign: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              color: "#000",
              fontWeight: 400,
              fontFamily: "Montserrat, sans-serif",
              textAlign: "center",
              marginLeft: 10,
              fontSize: 17,
              opacity: 1,
            }}
          >
            Download Complaint as CSV
          </Typography>

          <IconButton onClick={resetData}>
            <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
          </IconButton>
        </Grid>
        <Divider
          style={{
            marginTop: 0,
            marginBottom: 4,
            backgroundColor: "#000",
          }}
        />

        <Typography
          style={{
            color: "#283f59",
            fontFamily: "Montserrat, sans-serif",
            fontWeight: "800",
            fontSize: 15,
            textAlign: "center",
            marginTop: 4,
          }}
        >
          <FilterList style={{ color: "#6c757d" }} />
          &nbsp; Filter Applied
        </Typography>
        <Grid
          style={{
            border: "1px solid #6c757d",
            borderRadius: 8,
            padding: 6,
            marginTop: 12,
          }}
        >
          <Grid container style={{ justifyContent: "flex-end" }}>
            <Select
              value={selectStatus}
              onChange={(event) => {
                setselectStatus(event.target.value);
              }}
              style={{
                color: "#212529",
                backgroundColor: "#fff",
                fontWeight: "bold",
                borderRadius: 6,
                width: "150px",
                height: 30,
                fontSize: "13px",
                padding: 8,
                paddingLeft: 6,
                border: "1px solid #6c757d",
                textAlign: "center",
                marginBottom: 10,
              }}
            >
              {complaintStatus?.map((status) => {
                return (
                  <MenuItem
                    selected
                    style={{
                      fontWeight: "bold",
                      fontFamily: "Montserrat,sans-serif",
                    }}
                    value={status}
                  >
                    {status}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid container style={{ justifyContent: "space-between" }}>
            <Grid item xs={6}>
              <Typography
                style={{
                  color: "#283f59",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "800",
                  fontSize: 14,
                }}
              >
                <Apartment style={{ color: "#6c757d", fontSize: "15px" }} />
                &nbsp; Address :&nbsp;
                {localStorage.getItem("propertyForFilter") &&
                localStorage.getItem("propertyForFilter") !== "Select Property"
                  ? localStorage.getItem("propertyDetails")
                  : "NA"}
                {localStorage.getItem("floorForFilter") &&
                  localStorage.getItem("floorForFilter") !== "Select Floor" &&
                  localStorage.getItem("floorName")}{" "}
                {localStorage.getItem("flatForFilter") &&
                  localStorage.getItem("flatForFilter") !== "Select Flat" &&
                  +"- " + localStorage.getItem("flatName")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                style={{
                  color: "#283f59",
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "800",
                  fontSize: 14,
                }}
              >
                <Category style={{ color: "#6c757d", fontSize: "15px" }} />
                &nbsp; Category :&nbsp;
                {localStorage.getItem("categoryForFilter") &&
                localStorage.getItem("categoryForFilter") !== "Select Category"
                  ? localStorage.getItem("categoryDetails")
                  : "NA"}
                {localStorage.getItem("subcategoryForFilter") &&
                  localStorage.getItem("subcategoryForFilter") !==
                    "Select Subcategory" &&
                  +"- " + localStorage.getItem("subcategoryDetails")}{" "}
              </Typography>
            </Grid>
          </Grid>
          <Typography
            style={{
              color: "#283f59",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: "800",
              fontSize: 14,
              marginTop: 10,
            }}
          >
            <History style={{ color: "#6c757d", fontSize: "16px" }} />
            &nbsp;StartDate :{" "}
            {localStorage.getItem("startdate") === ""
              ? newDate
              : localStorage.getItem("startdate")}{" "}
            &nbsp; EndDate :{" "}
            {localStorage.getItem("enddate") === ""
              ? date
              : localStorage.getItem("enddate")}
            &nbsp; Status : {selectStatus}
          </Typography>
        </Grid>
        {errorMessage !== "" && (
          <Typography
            style={{
              fontFamily: "Montserrat, sans-serif",
              color: "#2f45c5",
              fontSize: 16,
              marginTop: 7,
              fontWeight: "bolder",
              textAlign: "center",
            }}
          >
            <SentimentDissatisfied style={{ color: "rgb(239 13 39)" }} />
            &nbsp; Sorry, there are no complaints for dowaload.
          </Typography>
        )}
        {/* **************** PROGRESS BAR ************** */}
        {fetchComplaints?.length > 0 && (
          <>
            <div className="download-model-description">
              <Grid
                container
                style={{
                  justifyContent: "space-between",
                  marginTop: 10,
                  marignBottom: 10,
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Montserrat, sans-serif",
                    fontSize: 17,
                    color: totalCounts === fetchComplaints?.length && "#1c5c1d",
                    fontWeight:
                      totalCounts === fetchComplaints?.length && "bold",
                  }}
                >
                  {totalCounts === fetchComplaints?.length
                    ? "Export Completed"
                    : "Exporting Complaints...."}
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Montserrat, sans-serif",
                    fontSize: 17,
                    fontWeight: "bold",
                  }}
                >
                  {fetchComplaints?.length}/{totalCounts}
                </Typography>
              </Grid>
            </div>
            <div className="progress-bar-container mt-2">
              <div
                className={
                  totalCounts === fetchComplaints?.length
                    ? "progress-bar progress-bar-animated bg-success"
                    : "progress-bar progress-bar-striped progress-bar-animated bg-primary"
                }
                style={{
                  width: `${
                    totalCounts
                      ? (fetchComplaints?.length / totalCounts) * 100
                      : 100
                  }%`,
                  borderRadius: "6px",
                  color: "#ffffff",
                  fontWeight: "bold",
                }}
              >
                {" "}
                {totalCounts
                  ? Math.round((fetchComplaints?.length / totalCounts) * 100) +
                    "%"
                  : 0 + "%"}{" "}
              </div>
            </div>
          </>
        )}
        {/* *********** BUTTONS *************** */}
        <Grid container style={{ justifyContent: "flex-end" }}>
          <div
            onClick={resetData}
            class="card"
            style={{
              height: 30,
              boxShadow: "0 3px 6px #7D858D",
              width: "120px",
              backgroundColor: "#e6e7f0",
              color: "#000",
              cursor: "pointer",
              marginTop: 7,
            }}
          >
            <div class="card-body ">
              <p
                class="card-text"
                style={{
                  fontSize: "11px",
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: "-12px",
                  textAlign: "center",
                }}
              >
                Close
              </p>
            </div>
          </div>
          <div
            class="card"
            style={{
              height: 30,
              boxShadow: "0 3px 6px #7D858D",
              width: "140px",
              backgroundColor: "#2f45c5",
              color: "#ffffff",
              cursor: "pointer",
              marginTop: 7,
              marginLeft: 10,
            }}
            onClick={() => {
              callApi();
            }}
          >
            <div class="card-body ">
              <p
                class="card-text"
                style={{
                  fontSize: "11px",
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: "-12px",
                  textAlign: "center",
                }}
              >
                Export &nbsp;
                {loader && (
                  <LoadingOutlined style={{ color: "#fff", fontSize: 19 }} />
                )}
              </p>
            </div>
          </div>
          {totalCounts === fetchComplaints?.length &&
            fetchComplaints?.length > 0 && (
              <ExportJsonCsv
                fileTitle={selectStatus + " Complaints"}
                headers={plant ? [...complaintModalCsvHeader, ...extraHeadersForPlant] : complaintModalCsvHeader}
                items={csvData}
                style={{
                  backgroundColor: "#2f45c5",
                  border: "none",
                  height: 30,
                  color: "#fff",
                  marginRight: 2,
                  padding: 6,
                  borderRadius: 5,
                  paddingLeft: 21,
                  paddingRight: 21,
                  cursor: "pointer",
                  justifyContent: "flex-end",
                  fontFamily: "Montserrat, sans-serif",
                  fontSize: "11px",
                  fontWeight: "bold",
                  marginLeft: 10,
                  marginTop: 7,
                }}
              >
                Download CSV
                <CloudDownloadOutlined
                  style={{ marginLeft: 6, fontSize: "20px" }}
                />
              </ExportJsonCsv>
            )}
        </Grid>
      </Paper>
    </Modal>
  );
}

export default CsvForComplaintModal;
