import React, { useState, useContext, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  IconButton,
  Card,
  CardActionArea,
  CardMedia,
} from "@material-ui/core";
import useStyles from "./styles";
import { useParams } from "react-router-dom";
import Modal from "@mui/material/Modal";
import { Close } from "@material-ui/icons";
import "./styledetails.css";
import { AuthContext } from "../../../contextAPI/context";
import NotifyVendor from "./ComplaintLifeCycle/LifecycleProcesses/NotifyVendorModal";
import VendorVisit from "./ComplaintLifeCycle/LifecycleProcesses/VendorVisit";
import moment from "moment";
import { handleGetCheckModulePermission } from "../../../contextAPI/checkModulesPermission";
import ComplaintDetailsCard from "./ComplaintDetailsCard";
import ApiCall from "../../../api/ApiCall";
import { AllComplaintRoutes } from "../../../api/Routes";
import ComplaintLifeCycle from "./ComplaintLifeCycle/ComplaintLifeCycle";
import UpdateLifecycleModal from "./ComplaintLifeCycle/UpdateLifecycleModal";
import AssignVendorModal from "./ComplaintLifeCycle/LifecycleProcesses/AssignVendorModal";
import ChangePriorityModal from "./ComplaintLifeCycle/LifecycleProcesses/ChangePriorityModal";
import ResolveComplaint from "./ComplaintLifeCycle/LifecycleProcesses/ResolveComplaint";
import CloseComplaint from "./ComplaintLifeCycle/LifecycleProcesses/CloseComplaint";
import CancelComplaint from "./ComplaintLifeCycle/LifecycleProcesses/CancelComplaint";
import ReopenModal from "./ComplaintLifeCycle/LifecycleProcesses/ReopenModal";
import SubComplaintModal from "./ComplaintLifeCycle/LifecycleProcesses/SubComplaintModal";
import BanterLoader from "../../../GlobalComponents/Loaders/BanterLoader";
import SubSubCategoryModal from "./ComplaintLifeCycle/LifecycleProcesses/SubSubCategoryModal";
import ExtendDeadline from "./ComplaintLifeCycle/LifecycleProcesses/ExtendDeadline";

function ComplaintDetails() {
  const classes = useStyles();
  const { authState } = useContext(AuthContext);
  const { user, usermoduleswithpermissions, plant } = authState;
  const [complaint, setcomplaint] = useState([]);
  const { id, complaintType } = useParams();
  const [updateProcecssModal, setupdateProcecssModal] = useState(false);
  const [selectProcess, setselectProcess] = useState("Select Process");
  const [csvData, setcsvData] = useState([]);
  const [showImage, setShowImage] = useState(false); // for popping up the picture as modal
  const [showPicture, setShowPicture] = useState(0); // for modal to show the full picture
  const [slideshow, setSlideshow] = useState(false);
  const [isReadWrite, setIsReadWrite] = useState(false);
  const [loader, setloader] = useState(false);

  useEffect(async () => {
    if (usermoduleswithpermissions) {
      setIsReadWrite(
        await handleGetCheckModulePermission(
          usermoduleswithpermissions,
          "Helpdesk"
        )
      );
    }
  }, [usermoduleswithpermissions]);

  const handlegetComplaintById = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    const res = await ApiCall("post", AllComplaintRoutes.getComplaintById, {
      complaintId: id,
    });
    if (res?.success) {
      setcomplaint(res?.complaint);
      createCsvData(res?.complaint?.complaintLifeCycle);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handlegetComplaintById();
  }, []);

  const headers = [
    {
      key: "index",
      name: "Sr. No.",
    },
    {
      key: "date",
      name: "Date and Time",
    },
    {
      key: "action",
      name: "Action",
    },
    {
      key: "updatedBy",
      name: "Updated By",
    },
    {
      key: "updatedByRole",
      name: "Updated By Role",
    },
    {
      key: "updatedFor",
      name: "Updated For",
    },
    {
      key: "updatedForRole",
      name: "Updated For Role",
    },
    {
      key: "message",
      name: "Action Message",
    },
  ];

  const createCsvData = (lifecycleData) => {
    let temp = [];
    lifecycleData?.map((cl, index) => {
      var date = moment(cl.time).format("DD/MM/YYYY - h:mm:ss A");
      if (
        cl?.type === "Complaint Raised" ||
        cl?.type === "Escalate to level one" ||
        cl?.type === "Escalate to level two" ||
        cl?.type === "Escalate to level three" ||
        cl?.type === "Deadline Exceeded"
      ) {
        return temp.push({
          index: index + 1,
          date: date,
          action: cl?.type,
          updatedBy:
            cl?.actionTakerRole === "System automated"
              ? "System Automated"
              : cl?.complainer?.name,
          updatedByRole: cl?.actionTakerRole ? cl?.actionTakerRole : "-",
          updatedFor: cl?.complainer?.name ? cl?.complainer?.name : "-",
          updatedForRole: cl?.complainer?.role ? cl?.complainer.role : "-",
          message: cl?.reasonDesc
            ? cl?.reasonDesc
            : cl?.description
            ? cl?.description
            : "-",
        });
      } else if (
        cl?.type === "Change priority" ||
        cl?.type === "Notify customer"
      ) {
        return temp.push({
          index: index + 1,
          date: date,
          action: cl?.type,
          updatedBy: cl?.admin?.name ? cl?.admin?.name : "-",
          updatedByRole: cl?.actionTakerRole,
          updatedFor: cl?.complainer?.name ? cl?.complainer?.name : "-",
          updatedForRole: cl?.complainer?.role ? cl?.complainer.role : "-",
          message: cl?.reasonDesc
            ? cl?.reasonDesc
            : cl?.description
            ? cl?.description
            : "-",
        });
      } else if (
        cl?.type === "Mark as resolved" ||
        cl?.type === "Close Complaint" ||
        cl?.type === "Reopen Complaint" ||
        cl?.type === "Complaint Cancelled"
      ) {
        return temp.push({
          index: index + 1,
          date: date,
          action: cl?.type,
          updatedBy: cl?.admin?.name ? cl?.admin.name : cl?.complainer.name,
          updatedByRole: cl?.actionTakerRole,
          updatedFor: cl?.complainer?.name ? cl?.complainer?.name : "-",
          updatedForRole: cl?.complainer?.role ? cl?.complainer.role : "-",
          message: cl?.reasonDesc
            ? cl?.reasonDesc
            : cl?.description
            ? cl?.description
            : "-",
        });
      } else if (
        cl?.type === "Assign vendor" ||
        cl?.type === "Reassign Vendor" ||
        cl?.type === "Notify vendor" ||
        cl?.type === "Vendor visit"
      ) {
        return temp.push({
          index: index + 1,
          date: date,
          action: cl?.type,
          updatedBy:
            cl?.actionTakerRole === "System automated"
              ? "System Automated"
              : cl?.admin?.name
              ? cl?.admin.name
              : cl?.complainer.name,
          updatedByRole: cl?.actionTakerRole,
          updatedFor: cl?.vendorId?.name ? cl?.vendorId.name : "-",
          updatedForRole: "Vendor",
          message: cl?.reasonDesc
            ? cl?.reasonDesc
            : cl?.description
            ? cl?.description
            : "-",
        });
      }
    });

    setcsvData([...temp]);
  };

  // const downloadFiles = (urls) => {
  //   urls.forEach((url, index) => {
  //     setTimeout(() => {
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", `file-${index + 1}`);
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     }, index * 500);
  //   });
  // };

  const downloadFiles = (urls) => {
    urls.forEach((url, index) => {
      setTimeout(async () => {
        try {
          const response = await fetch(url);
          const blob = await response.blob();
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.setAttribute("download", `file-${index + 1}`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(link.href);
        } catch (error) {
          console.error(`Failed to download ${url}:`, error);
        }
      }, index * 500);
    });
  };

  return (
    <div
      className={classes.root}
      style={{
        opacity: loader ? 0.4 : "",
        // pointerEvents: loader ? "none" : "",
        overflow: loader ? "hidden" : "auto",
        // height: "100vh",
      }}
    >
      <main className={classes.content} style={{ backgroundColor: "#ffffff" }}>
        <div className={classes.toolbar} />

        <ComplaintDetailsCard
          complaint={complaint}
          complaintType={complaintType}
          classes={classes}
          setSlideshow={setSlideshow}
          handlegetComplaintById={handlegetComplaintById}
          isPlant={plant}
        />
        <ComplaintLifeCycle
          complaint={complaint}
          user={user}
          handlegetComplaintById={handlegetComplaintById}
          headers={headers}
          csvData={csvData}
          isReadWrite={isReadWrite}
          setupdateProcecssModal={setupdateProcecssModal}
          loader={loader}
          setloader={setloader}
        />
        {updateProcecssModal && (
          <UpdateLifecycleModal
            updateProcecssModal={updateProcecssModal}
            setupdateProcecssModal={setupdateProcecssModal}
            complaint={complaint}
            selectProcess={selectProcess}
            setselectProcess={setselectProcess}
            classes={classes}
            isPlant={plant}
          />
        )}
        {(selectProcess === "Assign Vendor" ||
          selectProcess === "Re-assign Vendor") && (
          <AssignVendorModal
            selectProcess={selectProcess}
            complaint={complaint}
            classes={classes}
            setselectProcess={setselectProcess}
            handlegetComplaintById={handlegetComplaintById}
            setupdateProcecssModal={setupdateProcecssModal}
            user={user}
          />
        )}

        {selectProcess === "Vendor Visit" && (
          <VendorVisit
            complaint={complaint}
            selectProcess={selectProcess}
            setupdateProcecssModal={setupdateProcecssModal}
            setselectProcess={setselectProcess}
            classes={classes}
            user={user}
            handlegetComplaintById={handlegetComplaintById}
          />
        )}

        {(selectProcess === "Notify Vendor" ||
          selectProcess === "Notify Complainer") && (
          <NotifyVendor
            complaint={complaint}
            selectProcess={selectProcess}
            setselectProcess={setselectProcess}
            setupdateProcecssModal={setupdateProcecssModal}
            classes={classes}
            user={user}
            handlegetComplaintById={handlegetComplaintById}
          />
        )}

        {selectProcess === "Change Priority" && (
          <ChangePriorityModal
            complaint={complaint}
            selectProcess={selectProcess}
            setselectProcess={setselectProcess}
            setupdateProcecssModal={setupdateProcecssModal}
            user={user}
            handlegetComplaintById={handlegetComplaintById}
          />
        )}
        {selectProcess === "Sub-Subcategory" && (
          <SubSubCategoryModal
            complaint={complaint}
            selectProcess={selectProcess}
            setselectProcess={setselectProcess}
            classes={classes}
            user={user}
            setupdateProcecssModal={setupdateProcecssModal}
            handlegetComplaintById={handlegetComplaintById}
          />
        )}
        {selectProcess === "Mark As Resolved" && (
          <ResolveComplaint
            complaint={complaint}
            selectProcess={selectProcess}
            setselectProcess={setselectProcess}
            setupdateProcecssModal={setupdateProcecssModal}
            classes={classes}
            user={user}
            handlegetComplaintById={handlegetComplaintById}
          />
        )}
        {selectProcess === "Close Complaint" && (
          <CloseComplaint
            complaint={complaint}
            selectProcess={selectProcess}
            setselectProcess={setselectProcess}
            setupdateProcecssModal={setupdateProcecssModal}
            classes={classes}
            user={user}
            handlegetComplaintById={handlegetComplaintById}
            isPlant={plant}
          />
        )}
        {selectProcess === "Re-open Complaint" && (
          <ReopenModal
            complaint={complaint}
            selectProcess={selectProcess}
            setselectProcess={setselectProcess}
            setupdateProcecssModal={setupdateProcecssModal}
            classes={classes}
            user={user}
            handlegetComplaintById={handlegetComplaintById}
          />
        )}
        {selectProcess === "Cancel Complaint" && (
          <CancelComplaint
            complaint={complaint}
            selectProcess={selectProcess}
            setselectProcess={setselectProcess}
            setupdateProcecssModal={setupdateProcecssModal}
            classes={classes}
            user={user}
            handlegetComplaintById={handlegetComplaintById}
          />
        )}
        {selectProcess === "Sub Complaint" && (
          <SubComplaintModal
            complaint={complaint}
            selectProcess={selectProcess}
            setselectProcess={setselectProcess}
            setupdateProcecssModal={setupdateProcecssModal}
            classes={classes}
            user={user}
            handlegetComplaintById={handlegetComplaintById}
          />
        )}
        {selectProcess === "Extend-deadline" && (
          <ExtendDeadline
            selectProcess={selectProcess}
            setselectProcess={setselectProcess}
            classes={classes}
            complaint={complaint}
            setupdateProcecssModal={setupdateProcecssModal}
            handlegetComplaintById={handlegetComplaintById}
          />
        )}
      </main>
      {loader && <BanterLoader />}
      {slideshow && complaint?.image?.length > 0 && (
        <Modal open={slideshow}>
          <Paper
            style={{
              backgroundColor: "#e8f0fa",
              boxShadow: "2px 2px 8px #000",
              borderRadius: 7,
              height: "800",
            }}
            className={classes.ModalView}
          >
            <div className="d-flex justify-content-between">
              <Typography
                style={{
                  fontWeight: "bolder",
                  fontSize: 22,
                  fontFamily: "Montserrat, sans-serif",
                  color: "#6c757d",
                  marginBottom: 10,
                }}
              >
                Complaint Images&nbsp;&nbsp;
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="download-icon pointer"
                  onClick={() =>
                    downloadFiles(complaint?.image?.map((img) => img))
                  }
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width="23"
                    height="23"
                    rx="3.5"
                    stroke="#E1E1E1"
                  />
                  <path
                    d="M4 17V19C4 19.5304 4.21071 20.0391 4.58579 20.4142C4.96086 20.7893 5.46957 21 6 21H18C18.5304 21 19.0391 20.7893 19.4142 20.4142C19.7893 20.0391 20 19.5304 20 19V17M7 11L12 16M12 16L17 11M12 16V4"
                    stroke="#03045E"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Typography>
              <IconButton
                onClick={() => {
                  setSlideshow(false);
                }}
                style={{ textAlign: "flex-end" }}
              >
                <Close
                  style={{
                    color: "#000",
                    fontSize: 20,
                    textAlign: "flex-end",
                  }}
                />
              </IconButton>
            </div>

            <Card style={{ padding: 2 }}>
              <Grid
                container
                style={{
                  justifyContent: "space-between",
                  height: "34vh",
                  padding: "5px",
                }}
              >
                <Grid
                  item
                  xs={12}
                  style={{
                    overflowX: "auto",
                    overflowY: "hidden",
                    display: "flex",
                    flexDirection: "row",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  {complaint?.image?.map((img, index) => (
                    <div
                      key={index}
                      style={{ minWidth: "25%", marginRight: "8px" }}
                    >
                      {img && (
                        <Card style={{ padding: 4 }}>
                          <CardActionArea>
                            <CardMedia
                              component="img"
                              height="240"
                              style={{ objectFit: "contain" }}
                              image={
                                img
                                  ? img
                                  : "https://us.123rf.com/450wm/infadel/infadel1712/infadel171200119/91684826-a-black-linear-photo-camera-logo-like-no-image-available-.jpg?ver=6"
                              }
                              alt={`Complaint Image ${index + 1}`}
                              onClick={() => {
                                setShowImage(true);
                                setShowPicture(index);
                              }}
                            />
                          </CardActionArea>
                        </Card>
                      )}
                    </div>
                  ))}
                </Grid>
              </Grid>
            </Card>
          </Paper>
        </Modal>
      )}
      {showImage && (
        <Modal open={showImage}>
          <Paper
            style={{
              backgroundColor: "#e8f0fa",
              boxShadow: "2px 2px 8px #000",
              borderRadius: 7,
              height: "500",
            }}
            className={classes.ModalView}
          >
            <IconButton
              onClick={() => {
                setShowImage(false);
                setShowPicture(false);
              }}
              style={{ textAlign: "flex-end" }}
            >
              <Close
                style={{
                  color: "#000",
                  fontSize: 20,
                  textAlign: "flex-end",
                }}
              />
            </IconButton>
            <Card style={{ padding: 4 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  width="100%"
                  height={600}
                  style={{ objectFit: "contain" }}
                  image={
                    complaint?.image?.[showPicture]
                      ? complaint?.image?.[showPicture]
                      : `https://us.123rf.com/450wm/infadel/infadel1712/infadel171200119/91684826-a-black-linear-photo-camera-logo-like-no-image-available-.jpg?ver=6`
                  }
                  // image="https://us.123rf.com/450wm/infadel/infadel1712/infadel171200119/91684826-a-black-linear-photo-camera-logo-like-no-image-available-.jpg?ver=6"
                />
              </CardActionArea>
            </Card>
          </Paper>
        </Modal>
      )}
    </div>
  );
}

export default ComplaintDetails;
