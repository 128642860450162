import React, { useState, useContext, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  IconButton,
  Divider,
  Checkbox,
} from "@material-ui/core";
import useStyles from "./styles";
import { Close, ErrorOutline } from "@material-ui/icons";
import Modal from "@mui/material/Modal";
import * as api from "../../../api";
import CategoryIcon from "@mui/icons-material/Category";
import { LoadingOutlined } from "@ant-design/icons";
import { resolveErrorMessage } from "../../../services/service";
import { toast } from "react-toastify";
import { CATEGORIES } from "../../../contextAPI/actions/auth.actions";
import { AuthContext } from "../../../contextAPI/context";
import ChooseVendorDropDown from "../../../GlobalComponents/DropDowns/ChooseVendorDropDown";
import ChooseSubVendor from "../../../GlobalComponents/DropDowns/ChooseSubVendor";

function AddSubCategory(props) {
  const classes = useStyles();
  const { authDispatch } = useContext(AuthContext);
  const {
    addComplaintSubCategory,
    setAddComplaintSubCategory,
    getComplaintSubCategory,
    categoryId,
    isSubcategoryEdit,
    setisSubcategoryEdit,
    subCategoriEdited,
  } = props;
  const [subcategoryName, setsubCategoryName] = useState("");
  const [loader, setloader] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const [days, setdays] = useState("");
  const [hours, sethours] = useState("");
  const [autoAssign, setautoAssign] = useState(false);
  const [autoEscalation, setautoEscalation] = useState(false);
  const [vendor, setVendor] = useState([]);
  const [searchKey, setsearchKey] = useState("");
  const [subVendor, setsubVendor] = useState([]);

  useEffect(() => {
    if (isSubcategoryEdit) {
      setsubCategoryName(subCategoriEdited.subcategoryName);
      setautoAssign(subCategoriEdited.autoAssign);
      setVendor(subCategoriEdited.vendor ? subCategoriEdited.vendor : []);
      setsubVendor(subCategoriEdited.subVendors || []);
      let tempH = parseInt(subCategoriEdited.deadline) % 24;
      sethours(tempH.toString());
      let tempD = parseInt(subCategoriEdited.deadline) - tempH;
      let temp2 = tempD / 24;
      setdays(temp2.toString());
      setautoEscalation(subCategoriEdited.autoEscalation);
    }
  }, [isSubcategoryEdit, subCategoriEdited]);

  useEffect(() => {
    if (subCategoriEdited?.vendor?._id !== vendor?._id) {
      setsubVendor([]);
    }
  }, [subCategoriEdited, vendor]);

  const handleAddSubCategory = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      let sendData = {};
      let temp =
        24 * parseInt(days === "" ? 0 : days) +
        parseInt(hours === "" ? 0 : hours);

      if (autoAssign && (temp === "0" || !vendor || vendor?.length === 0)) {
        setloader(false);
        return seterrorMsg(
          "Deadline and vendor is required for Enable Auto Assign"
        );
      } else if (subcategoryName === "") {
        setloader(false);
        return seterrorMsg("Subcategory name is required");
      } else {
        if (!autoAssign && (!vendor || vendor?.length === 0)) {
          sendData = {
            subcategoryName,
            deadline: temp.toString(),
            categoryId,
          };
        } else {
          sendData = {
            subcategoryName,
            deadline: temp.toString(),
            categoryId,
            vendorId: vendor?._id,
            subVendors: subVendor?.map((vendor) => vendor?._id),
            autoAssign,
          };
        }
        const res = await api.createSubComplaintCategory(sendData);
        if (res?.data?.success) {
          authDispatch({
            type: CATEGORIES,
            payload: res.data.result.complaintCategories,
          });
          seterrorMsg("");
          setdays("");
          sethours("");
          setsubCategoryName("");
          setVendor([]);
          setAddComplaintSubCategory(false);
          setloader(false);
          getComplaintSubCategory();
          setisSubcategoryEdit(false);
          toast.success("Subcategory Added Successfully");
        }
      }
    } catch (error) {
      seterrorMsg(resolveErrorMessage(error));
      setloader(false);
    }
  };

  const handleEditSubCategory = async () => {
    seterrorMsg("");
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      let sendData = {};

      let temp =
        24 * parseInt(days === "" ? 0 : days) +
        parseInt(hours === "" ? 0 : hours);

      if (autoAssign && (temp === "0" || !vendor || vendor?.length === 0)) {
        setloader(false);
        return seterrorMsg(
          "Deadline and vendor is required for Enable Auto Assign"
        );
      } else if (subcategoryName === "") {
        setloader(false);
        return seterrorMsg("Subcategory name is required");
      } else {
        if (!autoAssign && (!vendor || vendor?.length === 0)) {
          sendData = {
            subcategoryName,
            deadline: temp.toString(),
            categoryId,
            autoEscalation,
            subcomplaintCategoryId: subCategoriEdited._id,
            
          };
        } else if (!autoAssign) {
          sendData = {
            subcategoryName,
            deadline: temp.toString(),
            categoryId,
            subcomplaintCategoryId: subCategoriEdited._id,
            autoEscalation,
            vendor: vendor?._id,
            subVendors: subVendor?.map((vendor) => vendor?._id),
          };
        } else {
          sendData = {
            subcategoryName,
            deadline: temp.toString(),
            categoryId,
            vendor: vendor?._id,
            autoAssign,
            autoEscalation,
            subcomplaintCategoryId: subCategoriEdited._id,
            subVendors: subVendor?.map((vendor) => vendor?._id),
          };
        }
        const res = await api.editSubComplaintCategory(sendData);
        if (res?.data?.success) {
          seterrorMsg("");
          setdays("");
          sethours("");
          setsubCategoryName("");
          setVendor([]);
          setAddComplaintSubCategory(false);
          setloader(false);
          getComplaintSubCategory();
          setisSubcategoryEdit(false);
          toast.success("Subcategory updated Successfully");
        }
      }
    } catch (error) {
      seterrorMsg(resolveErrorMessage(error));
      setloader(false);
    }
  };

  return (
    <Modal open={addComplaintSubCategory || isSubcategoryEdit}>
      <Paper
        className={classes.ModalView}
        style={{
          backgroundColor: "#e6e7f0",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
          // width: "29vw",
          height: "auto",
          minWidth: "330px",
          maxWidth: "29vw",
        }}
      >
        <Grid
          container
          style={{ textAlign: "center", justifyContent: "space-between" }}
        >
          <Typography
            style={{
              color: "#000",
              fontWeight: 400,
              fontFamily: "Montserrat, sans-serif",
              textAlign: "center",
              marginLeft: 10,
              fontSize: 17,
              opacity: 1,
            }}
          >
            {isSubcategoryEdit ? "Edit Subcategory" : "Add Subcategory"}
          </Typography>
          <IconButton
            onClick={() => {
              if (!loader) {
                setAddComplaintSubCategory(false);
                setisSubcategoryEdit(false);
                setloader(false);
                seterrorMsg("");
                setdays("");
                sethours("");
                setsubCategoryName("");
                setVendor([]);
                setautoAssign(false);
              }
            }}
          >
            {!loader && (
              <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
            )}{" "}
          </IconButton>
        </Grid>
        <Divider
          style={{ marginTop: 0, marginBottom: 10, backgroundColor: "#000" }}
        />
        <Grid>
          <Typography
            style={{
              color: "#6c757d",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: "600",

              marginBottom: 2,
            }}
          >
            Enter a name
          </Typography>
          <input
            style={{
              borderRadius: 10,
              padding: 10,
              width: "98%",
              border: "1px solid #416082",
              marginBottom: 10,
              outline: 0,
              marginTop: 6,
            }}
            onChange={(event) => setsubCategoryName(event.target.value)}
            placeholder="Subategory Name"
            value={subcategoryName}
          />
        </Grid>
        <Grid container style={{ marginLeft: -10 }}>
          <Grid container item xs={6}>
            <Typography
              style={{
                color: "#6c757d",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                fontSize: "14px",
                marginBottom: 2,
                marginLeft: 13,
              }}
            >
              Deadline in Days
            </Typography>
          </Grid>
          <Grid container item xs={6}>
            <Typography
              style={{
                color: "#6c757d",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                fontSize: "14px",
                marginBottom: 2,
                marginLeft: 13,
              }}
            >
              Deadline in Hours
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid container item xs={6}>
            <input
              type="number"
              style={{
                borderRadius: 10,
                padding: 10,
                width: "98%",
                border: "1px solid #416082",
                marginBottom: 8,
                outline: 0,
              }}
              onChange={(event) => setdays(event.target.value)}
              placeholder="Enter Days"
              value={days}
            />
          </Grid>
          <Grid container item xs={6}>
            <input
              type="number"
              style={{
                borderRadius: 10,
                padding: 10,
                width: "98%",
                border: "1px solid #416082",
                marginBottom: 8,
                outline: 0,
              }}
              onChange={(event) => sethours(event.target.value)}
              placeholder="Enter Hours"
              value={hours}
            />
          </Grid>
        </Grid>
        <Typography
          style={{
            fontWeight: "bold",
            color: "#283f59",
            fontSize: "11px",
            textAlign: "right",
            marginBottom: 8,
            marginRight: 8,
          }}
        >
          Total Deadline Hours : &nbsp;
          {24 * parseInt(days === "" ? 0 : days) +
            parseInt(hours === "" ? 0 : hours)}{" "}
          Hours
        </Typography>

        <Grid container style={{ marginTop: 8 }}>
          <ChooseVendorDropDown
            vendor={vendor}
            setVendor={setVendor}
            setsearchKey={setsearchKey}
            searchKey={searchKey}
            isMultiple={false}
          />
        </Grid>
        <Grid container style={{ marginTop: 8 }}>
          <ChooseSubVendor
            vendor={vendor}
            subVendor={subVendor}
            setsubVendor={setsubVendor}
            isMultiple={true}
          />
        </Grid>
        <Grid container style={{ marginTop: 8, justifyContent: "center" }}>
          <Typography
            style={{
              color: "#6c757d",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: "600",
              fontSize: "14px",
              marginTop: 4,
              marginRight: 7,
              marginLeft: 8,
            }}
          >
            Enable Auto Assign:
          </Typography>
          <Checkbox
            value={autoAssign}
            color="primary"
            size="large"
            checked={autoAssign}
            onChange={() => {
              setautoAssign(!autoAssign);
            }}
            style={{ color: "#2f45c5", marginTop: -6 }}
          />
        </Grid>
        {isSubcategoryEdit && (
          <Grid container style={{ marginTop: 8, justifyContent: "center" }}>
            <Typography
              style={{
                color: "#6c757d",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                fontSize: "14px",
                marginTop: 4,
                marginRight: 7,
                marginLeft: 8,
              }}
            >
              Enable Auto Esacalation:
            </Typography>
            <Checkbox
              value={autoEscalation}
              color="primary"
              size="large"
              checked={autoEscalation}
              onChange={() => {
                setautoEscalation(!autoEscalation);
              }}
              style={{ color: "#2f45c5", marginTop: -6 }}
            />
          </Grid>
        )}
        {errorMsg && (
          <Typography
            style={{
              marginTop: 10,
              color: "red",
              textAlign: "center",
              fontSize: "13px",
              fontWeight: "bold",
            }}
          >
            <ErrorOutline style={{ marginRight: 3 }} />
            {errorMsg}
          </Typography>
        )}
        <Grid container style={{ justifyContent: "center" }}>
          <div
            class="card"
            style={{
              height: 30,
              boxShadow: "0 3px 6px #4a6078",
              width: "160px",
              backgroundColor: "#2f45c5",
              color: "#ffffff",
              cursor: "pointer",
              marginTop: 10,
            }}
          >
            <div class="card-body ">
              <p
                class="card-text"
                style={{
                  fontSize: "11px",
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: "-12px",
                  marginBottom: "20px",
                  textAlign: "center",
                }}
                onClick={() => {
                  if (!loader && !isSubcategoryEdit) {
                    handleAddSubCategory();
                  }
                  if (!loader && isSubcategoryEdit) {
                    handleEditSubCategory();
                  } else {
                    //NOTHING HAPPENED
                  }
                }}
              >
                {isSubcategoryEdit ? (
                  <>
                    {loader ? (
                      <LoadingOutlined
                        style={{
                          marginRight: 10,
                          fontSize: "15px",
                        }}
                      />
                    ) : (
                      <CategoryIcon
                        style={{
                          marginRight: 10,
                          fontSize: "15px",
                        }}
                      />
                    )}

                    {!loader ? "Edit Subcategory" : `Editing...`}
                  </>
                ) : (
                  <>
                    {loader ? (
                      <LoadingOutlined
                        style={{
                          marginRight: 10,
                          fontSize: "15px",
                        }}
                      />
                    ) : (
                      <CategoryIcon
                        style={{
                          marginRight: 10,
                          fontSize: "15px",
                        }}
                      />
                    )}

                    {!loader ? "Add Subcategory" : `Adding...`}
                  </>
                )}
              </p>
            </div>
          </div>
        </Grid>
      </Paper>
    </Modal>
  );
}

export default AddSubCategory;
