import { handlePropertyName } from "../contextAPI/NamingConvention";

export const residentCsvHeader = [
  {
    key: "index",
    name: "SL. No.",
  },
  {
    key: "sector",
    name: "Sector",
  },
  {
    key: "propertyType",
    name: `${handlePropertyName()} Type`,
  },
  {
    key: "unit",
    name: "R.Unit",
  },
  {
    key: "customer",
    name: "Customer Name",
  },
  {
    key: "employeeID",
    name: "Employee ID", //complainer emp Id
  },
  {
    key: "address",
    name: "Rental Unit Text",
  },
  {
    key: "signUpDate",
    name: "Sign Up Date",
  },
  {
    key: "signUpTime",
    name: "Sign Up Time",
  },
];

export const complaintModalCsvHeader = [
  {
    key: "number",
    name: "Complain Number", //done
  },
  {
    key: "index",
    name: "SL. No.", //done
  },

  {
    key: "createdDate",
    name: "ComplainDT", //done create date
  },
  {
    key: "createdTime",
    name: "Complain Time", //done create time
  },
  {
    key: "complainer",
    name: "Complainer", //done
  },
  {
    key: "complaintFor",
    name: "Complain For", // RESIDENTIAL BUILDINGS
  },
  {
    key: "buildingType",
    name: "Building Type", //type
  },
  {
    key: "unit",
    name: "Unit", //flatnumber
  },
  {
    key: "occupancy",
    name: "Occupancy", //blank
  },
  {
    key: "contactNo",
    name: "Contact No", //complainer number
  },
  {
    key: "empId",
    name: "Employee ID", //complainer emp Id
  },
  {
    key: "outsideNo",
    name: "Outsider No", //blank
  },

  {
    key: "empName",
    name: "Employee Name", //complainer name
  },
  {
    key: "outsiderName",
    name: "Outsider Name", //blank
  },
  {
    key: "quarterStatus",
    name: "QuarterStatus", //blank
  },
  {
    key: "category",
    name: "Category", //category name
  },
  {
    key: "categoryType",
    name: "CategoryType", //subcategory name
  },
  {
    key: "priority",
    name: "Priority", //priority
  },
  {
    key: "details",
    name: "Details", //complaint description
  },
  {
    key: "remarks",
    name: "Remarks", //blank
  },
  {
    key: "assignmentStatus",
    name: "AssignmentStatus", //agar assigned hua hein toh assigned ------confusion
  },
  {
    key: "assignmentRemarks",
    name: "Assignment Remarks", ///blank
  },
  {
    key: "contractor",
    name: "Contractor", //vendor name
  },
  {
    key: "closingRemarks",
    name: "Closing Remarks", //closing reason
  },
  {
    key: "finalStatus",
    name: "Final Status", // current complaint status
  },
  {
    key: "quality",
    name: "Quality", //vendor k start k hisaab se (blank)-----
  },
  {
    key: "action",
    name: "Action", //assign hua hien toh process --- black ---confusiionnn
  },
  {
    key: "assignDate",
    name: "Assign Date", //assigned date
  },
  {
    key: "closeDate",
    name: "Closed Date", //closed date with time
  },
  {
    key: "closedDt",
    name: "ClosedDT", //closed date
  },
  {
    key: "closedTm",
    name: "CloseTM", //closed time
  },
  {
    key: "status",
    name: "Status", // complaint final status
  },
  {
    key: "address",
    name: "Address", //flat number if road side otherwise it will be flat name
  },
  {
    key: "sector",
    name: "Sector", // sector
  },
  {
    key: "expiryDt",
    name: "ExpiryDT", //deadline date  ----confusion
  },

  {
    key: "expiryTime",
    name: "ExpiryTime", //deadline time
  },
  {
    key: "goal",
    name: "Goal", //NA
  },
  {
    key: "expiryTargetDate",
    name: "Expiry Target Date", //blank
  },
  {
    key: "expiryDays",
    name: "ExpiryDays", //blank
  },
  {
    key: "itemType",
    name: "Item Type", //item
  },
  {
    key: "path",
    name: "Path", //link of complaint details
  },
  {
    key: "complainDate",
    name: "Complain Date", //link of complaint details
  },
];
