import React, { useState } from "react";
import OtpInput from "otp-input-react";
import {
  Typography,
  Grid,
  Badge,
  Card,
  CardMedia,
  Paper,
  IconButton,
  Divider,
} from "@material-ui/core";
import { Close, Cancel } from "@material-ui/icons";
import Modal from "@mui/material/Modal";
import { toast } from "react-toastify";
import { ComplaintUpdateProcessRoutes } from "../../../../../api/Routes";
import ApiCall from "../../../../../api/ApiCall";
import { LoadingOutlined } from "@ant-design/icons";
import { BASE_URL } from "../../../../../utils/base";
import { Input } from "antd";
import { Rating } from "@mui/material";
import {
  handleUploadInDirectAws,
  handleUploadInLocal,
} from "../../../../../utils/awsUpload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const CloseComplaint = (props) => {
  const {
    complaint,
    selectProcess,
    setselectProcess,
    setupdateProcecssModal,
    user,
    classes,
    handlegetComplaintById,
    isPlant,
  } = props;

  const { TextArea } = Input;
  const [errorMessage, seterrorMessage] = useState("");
  const [loader, setloader] = useState(false);
  const [feedBackMsg, setfeedBackMsg] = useState("");
  const [stars, setstars] = useState(0);
  const [message, setmessage] = useState("");
  const [OTP, setOTP] = useState("");
  const [imageTest, setimageTest] = useState({
    data: "",
    file: "",
  });
  const [attachTest, setattachTest] = useState({
    data: "",
    file: "",
  });

  const callingApi = () => {
    setloader(true);
    seterrorMessage("");
    setTimeout(() => {
      setloader(false);
    }, 40000);
  };

  const handleReset = () => {
    handlegetComplaintById();
    setupdateProcecssModal(false);
    setselectProcess("Select Process");
    setfeedBackMsg("");
    setmessage("");
    setstars(0);
    seterrorMessage("");
    setloader(false);
    setOTP("");
  };
  const handleCloseComplaint = async () => {
    let temp1 = [];
    let isError = false;
    if (OTP.trim() === "" && isPlant) {
      seterrorMessage("Please enter resolve OTP!");
    } else if (message.trim() === "" || message?.length < 8) {
      seterrorMessage("Please type a close reason of at least 8 characters");
    } else if (stars === 0) {
      seterrorMessage("Please enter a rating");
    } else if (feedBackMsg?.length < 8) {
      seterrorMessage("Please type a feedback of at least 8 characters");
    } else {
      callingApi();
      if (imageTest?.data) {
        try {
          temp1.push(await handleUploadInDirectAws(imageTest));
        } catch (error) {
          isError = true;
          seterrorMessage(
            "Something went wrong. Image uploading failed , try again after sometimes."
          );
          setloader(false);
        }
      }

      if (!isError) {
        const sendDataForLifeCycle = {
          complaintId: complaint?._id,
          complainerId: complaint?.complainer._id,
          complaintLifeCycle: "Close Complaint",
          adminId: user?._id,
          reasonDesc: message,
          vendorRating: stars.toString(),
          feedBack_message: feedBackMsg,
          otp: OTP,
          photo: temp1?.[0]?.Location,
        };
        const sendDataForClose = {
          complaintId: complaint?._id,
          status: "closed",
          stars: stars.toString(),
          feedback_message: feedBackMsg,
          photo: temp1?.[0]?.Location,
        };

        const res2 = await ApiCall(
          "post",
          ComplaintUpdateProcessRoutes.complaintLifeCycle,
          sendDataForLifeCycle
        );
        if (res2?.success) {
          const res = await ApiCall(
            "post",
            ComplaintUpdateProcessRoutes.resolveOrCloseComplaint,
            sendDataForClose
          );
          handleReset();
          toast.success("Complaint Closed Successfully");
        } else {
          setloader(false);
          seterrorMessage(res2.error);
        }
      }
    }
  };

  return (
    <Modal open={selectProcess === "Close Complaint"}>
      <Paper
        style={{
          backgroundColor: "#e6e7f0",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
          overflow: "auto",
          minWidth: "300px",
          maxWidth: "32vw",
          pointerEvents: loader ? "none" : "",
        }}
        className={classes.ModalView}
      >
        <Grid
          container
          style={{
            textAlign: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              color: "#000",
              textAlign: "center",
              fontWeight: "normal",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: "400",
              marginLeft: 10,
              fontSize: 17,
              opacity: 1,
            }}
          >
            {selectProcess}
          </Typography>

          <IconButton
            onClick={() => {
              if (!loader) {
                seterrorMessage("");
                setfeedBackMsg("");
                setmessage("");
                setstars(0);
                setselectProcess("Select Process");
              }
            }}
          >
            {!loader && (
              <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
            )}
          </IconButton>
        </Grid>
        <Divider
          style={{
            marginTop: 0,
            marginBottom: 10,
            backgroundColor: "#000",
          }}
        />

        <div style={{ maxHeight: "530px", overflowY: "scroll" }}>
          {isPlant && (
            <>
              <Typography
                style={{
                  color: "#283f59",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: 16,
                  marginBottom: 6,
                  marginTop: 16,
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                Enter 6 digit OTP
              </Typography>
              <Grid
                container
                style={{ justifyContent: "center", marginTop: 16 }}
              >
                <OtpInput
                  value={OTP}
                  onChange={setOTP}
                  autoFocus
                  OTPLength={6}
                  otpType="number"
                  disabled={false}
                />
              </Grid>
            </>
          )}

          <Grid className="mt-3" container style={{ justifyContent: "center" }}>
            <Typography
              style={{
                color: "#",
                fontWeight: "bold",
                textAlign: "center",
                textTransform: "capitalize",
                fontFamily: "Montserrat, sans-serif",
              }}
            >
              Complaint Current Status :{" "}
              {complaint?.status === "unassigned" && (
                <span
                  style={{
                    padding: 7,
                    fontSize: 12,
                    textTransform: "capitalize",
                  }}
                  class={"badge bg-warning"}
                >
                  {complaint?.status}
                </span>
              )}
              {complaint?.status === "assigned" && (
                <span
                  style={{
                    padding: 7,
                    fontSize: 12,
                    textTransform: "capitalize",
                  }}
                  class={"badge bg-info"}
                >
                  {complaint?.status}
                </span>
              )}
              {complaint?.status === "resolved" && (
                <span
                  style={{
                    padding: 7,
                    fontSize: 12,
                    textTransform: "capitalize",
                  }}
                  class={"badge bg-success"}
                >
                  {complaint?.status}
                </span>
              )}
              {complaint?.status === "closed" && (
                <span
                  style={{
                    padding: 7,
                    fontSize: 12,
                    textTransform: "capitalize",
                  }}
                  class={"badge bg-danger"}
                >
                  {complaint?.status}
                </span>
              )}
              {complaint?.status === "cancel" && (
                <span
                  style={{
                    padding: 7,
                    fontSize: 12,
                    textTransform: "capitalize",
                  }}
                  class={"badge bg-danger"}
                >
                  {complaint?.status}
                </span>
              )}
            </Typography>
          </Grid>

          <TextArea
            style={{
              marginTop: 10,
              height: 100,
              borderRadius: 10,
            }}
            placeholder="Enter the reason for closing your complaint"
            maxLength={500}
            value={message}
            onChange={(event) => setmessage(event.target.value)}
          />

          {complaint?.vendor && (
            <>
              <Typography
                style={{
                  color: "#000",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: 14,
                  marginBottom: 6,
                  marginTop: 12,
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                Feedback for Vendor
              </Typography>

              <Grid container style={{ justifyContent: "center" }}>
                <Grid
                  container
                  style={{
                    padding: 6,
                    borderRadius: 6,
                    backgroundColor: "#4b8edb",
                    color: "#fff",
                    textAlign: "center",
                    border: "2px solid #283f59",
                    width: 300,
                    marginBottom: 10,
                  }}
                >
                  <Grid item xs={2}>
                    <img
                      class="round"
                      src={
                        complaint?.vendor?.image
                          ? `${BASE_URL}/user/${complaint?.vendor?.image}`
                          : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                      }
                      alt="user"
                      height="50px"
                      width="50px"
                      style={{ borderRadius: 50 }}
                    />
                  </Grid>

                  <Grid item xs={10}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        textDecoration: "underline",
                      }}
                    >
                      {complaint?.vendor?.name}
                    </Typography>
                    <Typography style={{ fontSize: 13 }}>
                      {complaint?.vendor?.email}
                    </Typography>
                    <Typography style={{ fontSize: 13 }}>
                      {complaint?.vendor?.phone}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container style={{ justifyContent: "center" }}>
                <span style={{ textAlign: "center" }}>
                  <Rating
                    max={4}
                    name="simple-controlled"
                    value={stars}
                    style={{ marginTop: 10, fontSize: 26 }}
                    onChange={(event, newValue) => {
                      setstars(newValue);
                    }}
                  />
                </span>
              </Grid>

              <TextArea
                style={{
                  marginTop: 10,
                  height: 100,
                  borderRadius: 10,
                }}
                placeholder="Type Feedback Message"
                maxLength={500}
                value={feedBackMsg}
                onChange={(event) => setfeedBackMsg(event.target.value)}
              />
            </>
          )}
          {isPlant && (
            <Grid
              container
              xs={12}
              style={{
                justifyContent: "center",
                marginTop: 12,
                marginBottom: 5,
              }}
            >
              <Grid item xs={6}>
                <p
                  style={{
                    textAlign: "center",
                    fontFamily: "Montserrat, sans-serif",
                    fontSize: 12,
                    fontWeight: "bold",
                    marginTop: 12,
                  }}
                >
                  Add Images&nbsp;
                  <span style={{ color: "#000", fontSize: "11px" }}>
                    (Max Size 1MB/IMAGE)
                  </span>
                </p>
                <Grid
                  container
                  style={{ justifyContent: "center", marginTop: 20 }}
                >
                  {imageTest?.data ? (
                    <Badge
                      badgeContent={
                        <Cancel
                          style={{
                            color: "red",
                            cursor: "pointer",
                            fontSize: 22,
                          }}
                          onClick={() => {
                            setimageTest({ data: "" });
                          }}
                        />
                      }
                    >
                      <Card
                        style={{
                          width: "100px",
                          height: "100px",
                          backgroundColor: "#fff",
                          boxShadow: " rgba(3, 102, 214, 0.3) 0px 0px 0px 3px",
                          cursor: "pointer",
                        }}
                      >
                        <CardMedia
                          component="img"
                          style={{
                            width: "100px",
                            height: "100px",
                            objectFit: "contain",
                            cursor: "pointer",
                          }}
                          image={
                            imageTest?.data
                              ? imageTest?.data
                              : "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png"
                          }
                          alt="Image1"
                        />
                      </Card>
                    </Badge>
                  ) : (
                    <Card
                      style={{
                        width: "100px",
                        height: "100px",
                        justifyContent: "center",
                        textAlign: "center",
                        padding: "20px",
                        backgroundColor: "#fff",
                        boxShadow: " rgba(3, 102, 214, 0.3) 0px 0px 0px 3px",
                        cursor: "pointer",
                      }}
                    >
                      <label>
                        <CloudUploadIcon
                          style={{
                            width: "40px",
                            height: "40px",
                            color: "#2f45c5",
                            cursor: "pointer",
                          }}
                        />
                        <Typography
                          style={{
                            color: "#2f45c5",
                            textAlign: "center",
                            fontSize: 12,
                            fontWeight: "bold",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Add Image
                        </Typography>
                        <input
                          type="file"
                          onChange={(event) => {
                            handleUploadInLocal(event, "image", setimageTest);
                          }}
                          style={{
                            width: 0,
                            outline: 0,
                            backgroundColor: "#4b8edb",
                            height: 0,
                            marginBottom: 0,
                          }}
                          accept="image/png,image/jpeg"
                        ></input>
                      </label>
                    </Card>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
        </div>

        {errorMessage && (
          <Typography
            style={{
              textAlign: "center",
              color: "red",
              fontWeight: "bold",
              marginTop: 10,
              fontSize: "12px",
            }}
          >
            {errorMessage}
          </Typography>
        )}
        <Grid container style={{ justifyContent: "center" }}>
          <div
            class="card"
            style={{
              height: 35,
              boxShadow: "0 3px 6px #7D858D",
              width: "140px",
              backgroundColor: "#2f45c5",
              color: "#ffffff",
              cursor: "pointer",
              marginLeft: 5,
              marginTop: 20,
            }}
            onClick={() => {
              handleCloseComplaint();
            }}
          >
            <div class="card-body">
              <p
                class="card-text"
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: "-10px",
                  textAlign: "center",
                }}
              >
                {loader ? (
                  <LoadingOutlined
                    style={{
                      marginRight: 10,
                      fontSize: "15px",
                    }}
                  />
                ) : (
                  ""
                )}
                {!loader ? "Submit" : `Submitting...`}
              </p>
            </div>
          </div>
        </Grid>
      </Paper>
    </Modal>
  );
};

export default CloseComplaint;
