import { Grid, MenuItem, Select } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { fetchSocietyId } from "../../contextAPI/fetchSociety";
import { getSocietySectors } from "../../GlobalFunctions/GlobalFunctions";

function ChooseSectorDropDown(props) {
  const { sector, setsector } = props;
  const [loading, setLoading] = useState(false);
  const [allSector, setallSector] = useState([]);

  const handleGetSector = async () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 40000);
    const res = await getSocietySectors({ societyId: fetchSocietyId() });

    setallSector(res?.sector);
    setLoading(false);
  };

  useEffect(() => {
    handleGetSector();
  }, []);

  return (
    <Grid container item xs={12}>
      <Select
        value={sector}
        onChange={(event) =>{
          setsector(event.target.value)}
        }
        style={{
          display: "block",
          color: "#283f59",
          fontFamily: "Montserrat, sans-serif",
          backgroundColor: "#fff",
          borderRadius: 6,
          fontSize: 16,
          width: "97%",
          padding: 8,
          paddingLeft: 20,
          height: 52,
          outline: 0,
          border: "1px solid #283f59",
        }}
        disabled={loading}
      >
        <MenuItem selected value="Select Sector">
          Select Sector
        </MenuItem>
        {allSector?.map((sec) => {
          return (
            <MenuItem selected value={sec}>
              {sec}
            </MenuItem>
          );
        })}
      </Select>
    </Grid>
  );
}

export default ChooseSectorDropDown;
