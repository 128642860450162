import React, { useEffect, useRef, useState } from "react";
import { Avatar, Divider, Grid, Typography } from "@material-ui/core";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Add, Construction, ExitToApp, Person } from "@mui/icons-material";
import { BASE_URL } from "../../utils/base";
import {
  navigateToAddSocietyRequest,
  navigateToConfiguration,
} from "../../Navigation/Navigations";
import ConfirmLogoutModal from "../../GlobalComponents/Modals/ConfirmLogoutModal";
import { toast } from "react-toastify";
import { LOGOUT } from "../../contextAPI/actions/auth.actions";
import { useNavigate } from "react-router-dom";
import * as api from "../../api/index";
import { isMemberTypeS2Admin } from "../../GlobalFunctions/GlobalFunctions";
import { handleGetCheckModulePermission } from "../../contextAPI/checkModulesPermission";

const useClickOutside = (ref, handler) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, handler]);
};

const Dropper = (props) => {
  const {
    user,
    authDispatch,
    refreshToken,
    setIsActive,
    memberType,
    usermoduleswithpermissions,
  } = props;
  const navigate = useNavigate();
  const [confirmLogout, setconfirmLogout] = useState(false);
  const [loader, setloader] = useState(false);
  const dropdownRef = useRef(null);
  const [isReadWrite, setIsReadWrite] = useState(false);

  useEffect(async () => {
    if (usermoduleswithpermissions) {
      setIsReadWrite(
        await handleGetCheckModulePermission(
          usermoduleswithpermissions,
          "Society Manage"
        )
      );
    }
  }, [usermoduleswithpermissions]);

  useClickOutside(dropdownRef, () => {
    setIsActive(false);
  });
  const isLogout = () => {
    setconfirmLogout(true);
  };

  const callingApi = () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
  };
  const handleLogout = async () => {
    callingApi();
    try {
      const res = await api.logout({ token: refreshToken });
      authDispatch({
        type: LOGOUT,
      });
      setIsActive(false);
      setconfirmLogout();
      toast.success("Logout Successfully");
      navigate("/");
      setloader(false);
    } catch (error) {
      if (error) {
        setloader(false);
        authDispatch({
          type: LOGOUT,
        });
        toast.success("Logout Successfully");
        navigate("/");
      }
    }
  };

  return (
    <>
      <div className="dropdown-menu" ref={confirmLogout ? null : dropdownRef}>
        <Grid container style={{ justifyContent: "center" }}>
          <Avatar
            src={user?.image && `${BASE_URL}/user/${user.image}`}
            style={{ height: "60px", width: "60px" }}
          />
        </Grid>
        <Typography
          style={{
            fontFamily: "Montserrat, sans-serif",
            fontWeight: "bold",
            textTransform: "capitalize",
            textAlign: "center",
          }}
        >
          {user?.name}
        </Typography>
        <Typography
          style={{
            fontFamily: "Montserrat, sans-serif",
            textTransform: "capitalize",
            textAlign: "center",
            color: "#AAA",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        >
          {user?.role}
        </Typography>
        <Divider
          style={{
            marginTop: 5,
            marginBottom: 10,
            backgroundColor: "#000",
          }}
        />
        <Grid>
          <Grid
            container
            style={{
              marginTop: 12,
              justifyContent: "space-between",
              fontWeight: 400,
            }}
            className="extra-item"
            onClick={() => {
              navigate("/profile");
              setIsActive(false);
            }}
          >
            <Typography style={{ fontFamily: "Montserrat, sans-serif" }}>
              <Person
                style={{
                  backgroundColor: "#c8c8c8",
                  padding: "5px",
                  borderRadius: 50,
                  fontSize: "30px",
                  marginRight: 20,
                }}
              />
              Profile
            </Typography>

            <Typography>
              <ArrowForwardIosIcon style={{ fontSize: "13px" }} />
            </Typography>
          </Grid>

          <Grid
            container
            style={{
              marginTop: 12,
              justifyContent: "space-between",
              fontWeight: 400,
            }}
            className="extra-item"
            onClick={() => {
              navigate(navigateToAddSocietyRequest({}));
              setIsActive(false);
            }}
          >
            <Typography style={{ fontFamily: "Montserrat, sans-serif" }}>
              <Add
                style={{
                  backgroundColor: "#c8c8c8",
                  padding: "5px",
                  borderRadius: 50,
                  fontSize: "30px",
                  marginRight: 20,
                }}
              />
              {isReadWrite ? "Add Society" : "View Society"}
            </Typography>

            <Typography>
              <ArrowForwardIosIcon style={{ fontSize: "13px" }} />
            </Typography>
          </Grid>
          {isMemberTypeS2Admin(user?.role) && (
            <Grid
              container
              style={{
                marginTop: 12,
                justifyContent: "space-between",
                fontWeight: 400,
              }}
              className="extra-item"
              onClick={() => {
                navigate(navigateToConfiguration({}));
                setIsActive(false);
              }}
            >
              <Typography style={{ fontFamily: "Montserrat, sans-serif" }}>
                <Construction
                  style={{
                    backgroundColor: "#c8c8c8",
                    padding: "5px",
                    borderRadius: 50,
                    fontSize: "30px",
                    marginRight: 20,
                  }}
                />
                Configuration
              </Typography>

              <Typography>
                <ArrowForwardIosIcon style={{ fontSize: "13px" }} />
              </Typography>
            </Grid>
          )}

          <Divider
            style={{
              marginTop: 10,
              marginBottom: 5,
              backgroundColor: "#000",
            }}
          />
          <Grid
            container
            style={{
              marginTop: 12,
              justifyContent: "space-between",
              fontWeight: 400,
            }}
            className="extra-item"
            onClick={isLogout}
          >
            <Typography style={{ fontFamily: "Montserrat, sans-serif" }}>
              <ExitToApp
                style={{
                  backgroundColor: "#c8c8c8",
                  padding: "5px",
                  borderRadius: 50,
                  fontSize: "30px",
                  marginRight: 20,
                }}
              />
              Logout
            </Typography>

            <Typography>
              <ArrowForwardIosIcon style={{ fontSize: "13px" }} />
            </Typography>
          </Grid>
        </Grid>
        {confirmLogout && (
          <ConfirmLogoutModal
            setisConfirm={setconfirmLogout}
            isConfirm={confirmLogout}
            confirmLogout={handleLogout}
            loader={loader}
          />
        )}
      </div>
    </>
  );
};

export default Dropper;
