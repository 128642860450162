import React, { useContext, useState, useEffect } from "react";
import useStyles from "./style";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import "./Profile.css";
import InfoIcon from "@mui/icons-material/Info";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import ManIcon from "@mui/icons-material/Man";
import { resolveErrorMessage } from "../../services/service";
import { toast } from "react-toastify";
import * as api from "../../api";
import { BASE_URL } from "../../utils/base";
import { LoadingOutlined } from "@ant-design/icons";
import { GETUSER, LOGOUT } from "../../contextAPI/actions/auth.actions";
import { AuthContext } from "../../contextAPI/context";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { fetchSocietyDetails } from "../../contextAPI/fetchSociety";
import { Key, Logout, VideoCallOutlined } from "@mui/icons-material";
import { Divider, Paper, Button, Avatar } from "@material-ui/core";
import Modal from "@mui/material/Modal";
import { LockClock } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Drawer } from "antd";
import {
  AccessTime,
  Apartment,
  Cake,
  Close,
  Domain,
  Home,
  Mail,
  Phone,
} from "@material-ui/icons";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import PollIcon from "@mui/icons-material/Poll";
import EditIcon from "@mui/icons-material/Edit";

function ProfileDetails() {
  const classes = useStyles();
  const { authState, authDispatch } = useContext(AuthContext);
  const { user } = authState;
  const [AdminImg, setAdminImg] = useState(
    "https://cdn-icons-png.flaticon.com/512/149/149071.png"
  );

  const [errorMsg, seterrorMsg] = useState("");
  const [pleaseWait, setpleaseWait] = useState(false);
  const refreshToken = JSON.parse(localStorage.getItem("refreshToken"));
  const [uniqueId, setuniqueId] = useState("");
  const [confirmLogout, setconfirmLogout] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editName, setEditName] = useState("");

  useEffect(() => {
    if(user){
      setEditName(user?.name)
    }
  },[isEdit])

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const cardStyle = {
    display: "block",
    height: "10vw",
    borderRadius: "20px",
  };

  const photoUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setAdminImg(reader.result);
    };
    reader.readAsDataURL(file);
    handleUpdateAdmin(file);
  };

  const handleLogout = async () => {
    try {
      const res = await api.logout({ token: refreshToken });
      authDispatch({
        type: LOGOUT,
      });
      toast.success("Logout Successfully");
      navigate("/");
    } catch (error) {
      if (error) {
        authDispatch({
          type: LOGOUT,
        });
        toast.success("Logout Successfully");
        navigate("/");
      }
    }
  };
  const handleUpdateAdmin = async (image) => {
    setpleaseWait(true);
    seterrorMsg("");
    try {
      const formData = new FormData();
      formData.append("adminId", user._id);
      formData.append("image", image);

      const res = await api.updateAdmin(formData);
      if (res?.data?.success) {
        toast.success("Admin Updated Successfully", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        const res = await api.getUser({ params: { token: refreshToken } });
        authDispatch({
          type: GETUSER,
          payload: res.data,
        });
        setpleaseWait(false);
      }
    } catch (error) {
      setpleaseWait(false);
      seterrorMsg(resolveErrorMessage(error));
    }
  };

  useEffect(async () => {
    setuniqueId(await fetchSocietyDetails("societyUniqueId"));
  }, []);

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div class="card" style={{ borderRadius: 6, height: "85vh" }}>
          <div class="card-body" style={{ marginTop: 40 }}>
            <Grid
              container
              style={{
                justifyContent: "space-between",
                marginBottom: 20,
              }}
            >
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: 22,
                  fontFamily: "Montserrat, sans-serif",
                  color: "#6c757d",
                }}
              >
                Profile Details
              </Typography>
              {/* <Grid
                container
                item
                style={{
                  justifyContent: "flex-end",
                }}
              >
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: 22,
                    fontFamily: "Montserrat, sans-serif",
                    color: "#6c757d",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={showDrawer}
                >
                  View Profile Details
                </Typography>
              </Grid> */}
            </Grid>
            <Drawer
              width={550}
              placement="right"
              closable={false}
              onClose={onClose}
              open={open}
            >
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: 18,
                  fontFamily: "Montserrat, sans-serif",
                  color: "#000",
                  cursor: "pointer",
                  marginTop: 50,
                  textAlign: "center",
                }}
              >
                User Profile Details
              </Typography>
              <Divider
                style={{
                  marginTop: 5,
                  marginBottom: 5,
                  backgroundColor: "#000",
                }}
              />
              <Grid
                container
                style={{ justifyContent: "center", marginTop: 10 }}
              >
                <img
                  style={{
                    cursor: "pointer",
                    border: "1px solid #e6e7f0",
                    height: 70,
                    width: 70,
                    borderRadius: 50,
                  }}
                  src={
                    user?.image ? `${BASE_URL}/user/${user.image}` : AdminImg
                  }
                  alt="Sociohood"
                />
              </Grid>
              <Grid
                container
                style={{ justifyContent: "center", marginTop: 5 }}
              >
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: 15,
                    fontFamily: "Montserrat, sans-serif",
                    color: "#000",
                    cursor: "pointer",
                    textAlign: "start",
                  }}
                >
                  <span>Sandip Kedia</span> {""}(Admin)
                </Typography>
              </Grid>
              <Grid item xs={12} lg={12} sm={12}>
                <Grid
                  container
                  style={{ justifyContent: "center", marginTop: 10 }}
                >
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 15,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#000",
                      cursor: "pointer",
                      marginBottom: 10,
                    }}
                  >
                    <Apartment style={{ fontSize: "18px" }} />
                    &nbsp; Society : &nbsp;
                    <span style={{ color: "#6c757d" }}>TATAMOTORSSOCIETY</span>
                  </Typography>
                </Grid>
                <Grid style={{ justifyContent: "space-between", marginTop: 8 }}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 15,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#000",
                      cursor: "pointer",
                      textAlign: "start",
                      marginTop: 5,
                    }}
                  >
                    <AccessTime style={{ fontSize: "18px" }} />
                    &nbsp; Created At : &nbsp;
                    <span style={{ color: "#6c757d" }}>
                      21/03/2023 - 11.00 AM
                    </span>
                  </Typography>
                </Grid>
                <Grid style={{ justifyContent: "space-between", marginTop: 8 }}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 15,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#000",
                      cursor: "pointer",
                      marginTop: 5,
                    }}
                  >
                    <Mail style={{ fontSize: "18px" }} />
                    &nbsp; Email : &nbsp;
                    <span style={{ color: "#6c757d" }}>
                      sandip@technixia.com
                    </span>
                  </Typography>
                </Grid>
                <Grid style={{ justifyContent: "space-between", marginTop: 8 }}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 15,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#000",
                      cursor: "pointer",
                      textAlign: "start",
                      marginTop: 5,
                    }}
                  >
                    <Phone style={{ fontSize: "18px" }} />
                    &nbsp; Phone : &nbsp;
                    <span style={{ color: "#6c757d" }}>9876543210</span>
                  </Typography>
                </Grid>
                <Grid style={{ justifyContent: "space-between", marginTop: 8 }}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 15,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#000",
                      cursor: "pointer",
                      textAlign: "start",
                      marginTop: 5,
                    }}
                  >
                    <Key style={{ fontSize: "18px" }} />
                    &nbsp; UniqueId : &nbsp;
                    <span style={{ color: "#6c757d" }}>USRBA9FBkEz7ZU7</span>
                  </Typography>
                </Grid>
                <Grid style={{ justifyContent: "space-between", marginTop: 8 }}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 15,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#000",
                      cursor: "pointer",
                      textAlign: "start",
                      marginTop: 5,
                    }}
                  >
                    <Cake style={{ fontSize: "18px" }} />
                    &nbsp; DOB : &nbsp;
                    <span style={{ color: "#6c757d" }}>18-11-90</span>
                  </Typography>
                </Grid>
                <Grid style={{ justifyContent: "space-between", marginTop: 8 }}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 15,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#000",
                      cursor: "pointer",
                      textAlign: "start",
                      marginTop: 5,
                    }}
                  >
                    <Home style={{ fontSize: "18px" }} />
                    &nbsp; Address : &nbsp;
                    <span style={{ color: "#6c757d" }}>
                      Sociohood - Ground Floor - Sandip's Flat
                    </span>
                  </Typography>
                </Grid>
                <Divider
                  style={{
                    marginTop: 5,
                    marginBottom: 5,
                    backgroundColor: "#000",
                  }}
                />
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: 18,
                    fontFamily: "Montserrat, sans-serif",
                    color: "#6c757d",
                    cursor: "pointer",
                    textAlign: "center",
                    marginTop: 5,
                  }}
                >
                  User Activity
                </Typography>
                <Grid style={{ justifyContent: "space-between", marginTop: 8 }}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 15,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#000",
                      cursor: "pointer",
                      textAlign: "start",
                      marginTop: 5,
                    }}
                  >
                    <AnnouncementIcon style={{ fontSize: "18px" }} />
                    &nbsp; No of Complaints Raised : &nbsp;
                    <span style={{ color: "#6c757d" }}>20</span>
                  </Typography>
                </Grid>
                <Grid style={{ justifyContent: "space-between", marginTop: 8 }}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 15,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#000",
                      cursor: "pointer",
                      textAlign: "start",
                      marginTop: 5,
                    }}
                  >
                    <VideoCallOutlined style={{ fontSize: "18px" }} />
                    &nbsp; No of Meetings Created : &nbsp;
                    <span style={{ color: "#6c757d" }}>20</span>
                  </Typography>
                </Grid>
                <Grid style={{ justifyContent: "space-between", marginTop: 8 }}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 15,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#000",
                      cursor: "pointer",
                      textAlign: "start",
                      marginTop: 5,
                    }}
                  >
                    <PollIcon style={{ fontSize: "18px" }} />
                    &nbsp; No of Polls Created : &nbsp;
                    <span style={{ color: "#6c757d" }}>20</span>
                  </Typography>
                </Grid>
                <Divider
                  style={{
                    marginTop: 5,
                    marginBottom: 5,
                    backgroundColor: "#000",
                  }}
                />
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: 18,
                    fontFamily: "Montserrat, sans-serif",
                    color: "#6c757d",
                    cursor: "pointer",
                    textAlign: "center",
                    marginTop: 5,
                  }}
                >
                  Login Details
                </Typography>
                <Grid
                  style={{ justifyContent: "space-between", marginTop: 10 }}
                >
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 15,
                      fontFamily: "Montserrat, sans-serif",
                      color: "#000",
                      cursor: "pointer",
                      textAlign: "start",
                      marginTop: 5,
                    }}
                  >
                    &nbsp;Last Login At : &nbsp;
                    <span style={{ color: "#6c757d" }}>
                      21/03/2023 - 12.00 PM
                    </span>
                  </Typography>
                </Grid>
              </Grid>
            </Drawer>
            <div className="row">
              <div className="col-md-4">
                <Card sx={cardStyle} className>
                  <CardContent>
                    {!pleaseWait ? (
                      <Grid
                        className="Img-container"
                        container
                        style={{
                          justifyContent: "center",
                          height: "303px",
                          cursor: "pointer",
                          cursor: "pointer",
                        }}
                      >
                        <label htmlFor="photo-upload">
                          <CardMedia
                            component="img"
                            height="300"
                            image={
                              user?.image
                                ? `${BASE_URL}/user/${user.image}`
                                : AdminImg
                            }
                            alt="Admin Image"
                          />
                          <input
                            id="photo-upload"
                            type="file"
                            accept="image/*"
                            onChange={photoUpload}
                            style={{
                              width: 0,
                              outline: 0,
                              backgroundColor: "#4b8edb",
                              height: 0,
                              marginBottom: 0,
                            }}
                          />
                          <div className="overlay">
                            <p className="text">
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 15,
                                  fontFamily: "Poppins, sans-serif",
                                  color: "#000",
                                }}
                              >
                                Click here to update your profile picture
                              </Typography>
                            </p>
                          </div>
                        </label>
                      </Grid>
                    ) : (
                      <Grid>
                        <Grid
                          container
                          style={{
                            justifyContent: "center",
                          }}
                        >
                          <LoadingOutlined
                            style={{
                              fontSize: 50,
                              color: "#2f3aa3",
                              fontWeight: "normal",
                            }}
                          />
                        </Grid>
                        <Typography
                          style={{
                            fontWeight: "bold",
                            fontSize: 14,
                            fontFamily: "Montserrat, sans-serif",
                            color: "#6c757d",
                            textAlign: "center",
                          }}
                        >
                          Changing Profile Picture....
                        </Typography>
                      </Grid>
                    )}
                  </CardContent>
                </Card>
              </div>
              <div className="col-md-8">
                <Card sx={{ height: 150 }}>
                  <CardContent>
                    <Grid
                      container
                      xs={12}
                      md={12}
                      lg={12}
                      style={{ justifyContent: "space-between" }}
                    >
                      <Grid container item xs={9} md={9} lg={9}>
                        {isEdit ? (
                          <>
                            <input
                              type="text"
                              style={{
                                borderRadius: 10,
                                padding: 5,
                                width: "45%",
                                border: "1px solid #416082",
                                marginBottom: 2,
                                outline: 0,
                                fontSize: 15,
                              }}
                              placeholder="Enter Your Name"
                              value={editName}
                              onChange={(event) => setEditName(event.target.value)}
                            />
                            <div
                              class="card"
                              style={{
                                height: 35,
                                boxShadow: "0 3px 6px #4a6078",
                                width: "90px",
                                backgroundColor: "#2f45c5",
                                color: "#ffffff",
                                cursor: "pointer",
                                marginLeft: 15,
                              }}
                              onClick={() => {}}
                            >
                              <div class="card-body ">
                                <p
                                  class="card-text"
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: "bold",
                                    fontFamily: "Montserrat, sans-serif",
                                    marginTop: "-11px",
                                    marginBottom: "20px",
                                    textAlign: "center",
                                  }}
                                >
                                  {/* <KeyboardBackspaceRounded
                                style={{
                                  marginRight: 10,
                                  fontSize: "15px",
                                }}
                              /> */}
                                  Submit
                                </p>
                              </div>
                            </div>
                            <IconButton
                              onClick={() => {
                                setIsEdit(false);
                              }}
                            >
                              <Close
                                style={{
                                  fontSize: 20,
                                  color: "#6c757d",
                                  marginTop: -5,
                                }}
                              />
                            </IconButton>
                          </>
                        ) : (
                          <>
                            <Typography
                              style={{
                                fontSize: 25,
                                fontFamily: "Poppins, sans-serif",
                                color: "#6c757d",
                                fontWeight: "bold",
                                marginLeft: 5,
                              }}
                            >
                              {user?.name}
                            </Typography>
                            {/* <Tooltip title="edit name here" placement="top">
                              <IconButton
                                onClick={() => {
                                  setIsEdit(true);
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <EditIcon
                                  style={{
                                    fontSize: 20,
                                    color: "#6c757d",
                                    marginTop: -5,
                                  }}
                                />
                              </IconButton>
                            </Tooltip> */}
                          </>
                        )}
                      </Grid>
                      <Grid
                        container
                        item
                        xs={3}
                        md={3}
                        lg={3}
                        style={{ justifyContent: "flex-end" }}
                      >
                        <Typography
                          style={{
                            fontSize: 15,
                            fontFamily: "Montserrat, sans-serif",
                            color: "red",
                            fontWeight: "bold",
                            textAlign: "right",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setconfirmLogout(true);
                          }}
                        >
                          <Logout />
                          &nbsp; LOGOUT
                        </Typography>
                      </Grid>
                    </Grid>
                    <Typography
                      style={{
                        fontSize: 13,
                        fontFamily: "Poppins, sans-serif",
                        color: "#2f45c5",
                        fontWeight: "bold",
                        marginLeft: 5,
                        marginBottom: 5,
                        textTransform: "capitalize",
                      }}
                    >
                      {user?.role}
                    </Typography>

                    <CardContent>
                      <div className="row">
                        <div className="col-md-6">
                          <Typography
                            style={{
                              fontSize: 15,
                              fontFamily: "Montserrat, sans-serif",
                              color: "#6c757d",
                              fontWeight: "bold",
                            }}
                          >
                            <InfoIcon style={{ marginRight: 5 }} />
                            Society Id
                          </Typography>
                        </div>
                        <div className="col-md-6">
                          <Typography
                            style={{
                              fontSize: 15,
                              fontFamily: "Montserrat, sans-serif",
                              color: "#6c757d",
                              fontWeight: "bold",
                            }}
                          >
                            {uniqueId ? uniqueId : "-"}
                          </Typography>
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div className="col-md-6">
                          <Typography
                            style={{
                              fontSize: 15,
                              fontFamily: "Montserrat, sans-serif",
                              color: "#6c757d",
                              fontWeight: "bold",
                              marginTop: 10,
                            }}
                          >
                            <LocalPhoneIcon style={{ marginRight: 5 }} />
                            Phone Number
                          </Typography>
                        </div>
                        <div className="col-md-6">
                          <Typography
                            style={{
                              fontSize: 15,
                              fontFamily: "Montserrat, sans-serif",
                              color: "#6c757d",
                              fontWeight: "bold",
                              marginTop: 10,
                            }}
                          >
                            {user?.phone}
                          </Typography>
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div className="col-md-6">
                          <Typography
                            style={{
                              fontSize: 15,
                              fontFamily: "Montserrat, sans-serif",
                              color: "#6c757d",
                              fontWeight: "bold",
                              marginTop: 10,
                            }}
                          >
                            <AlternateEmailIcon style={{ marginRight: 5 }} />
                            Email Address
                          </Typography>
                        </div>
                        <div className="col-md-6">
                          <Typography
                            style={{
                              fontSize: 15,
                              fontFamily: "Montserrat, sans-serif",
                              color: "#2f45c5",
                              fontWeight: "bold",
                              marginTop: 10,
                            }}
                          >
                            {user?.email}
                          </Typography>
                        </div>
                      </div>

                    </CardContent>
                  </CardContent>
                </Card>
              </div>
              <div className="col-md-2">{/* Bio space */}</div>
            </div>

            <div className="row">
              <div className="col-md-8 pl-5 mt-2">
                <div className=""></div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {confirmLogout && (
            <Modal open={confirmLogout}>
              <Paper className={classes.deleteModal}>
                <Typography
                  style={{
                    color: "#6c757d",
                    fontSize: 19,
                    textAlign: "center",
                    fontWeight: "bold",
                    fontFamily: "Montserrat, sans-serif",
                    marginBottom: 7,
                  }}
                >
                  ARE YOU STILL HERE?
                </Typography>
                <Typography style={{ textAlign: "center" }}>
                  <LockClock style={{ fontSize: 70, color: "#1e2082" }} />
                </Typography>

                <Typography
                  style={{
                    color: "#6c757d",
                    fontSize: 14,
                    textAlign: "center",
                    fontFamily: "Montserrat, sans-serif",
                    marginTop: 5,
                  }}
                >
                  In order to keep your account safe, we periodically check to
                  see if you're still here. You can stay logged in by clicking
                  below.
                </Typography>
                <Grid container style={{ justifyContent: "space-evenly" }}>
                  <Button
                    onClick={() => {
                      setconfirmLogout(false);
                    }}
                    style={{
                      backgroundColor: "#1e2082",
                      color: "#fff",
                    }}
                    className={classes.buttonDelete}
                  >
                    KEEP ME LOGGED IN
                  </Button>
                  <Button
                    onClick={() => {
                      handleLogout();
                    }}
                    className={classes.buttonDelete}
                  >
                    LOGOUT
                  </Button>
                </Grid>
              </Paper>
            </Modal>
          )}
        </div>
      </main>
    </div>
  );
}

export default ProfileDetails;
