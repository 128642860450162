import { Divider, IconButton, Paper } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { useContext, useState } from "react";
import ApiCall from "../../../../../api/ApiCall";
import { ComplaintUpdateProcessRoutes } from "../../../../../api/Routes";
import { toast } from "react-toastify";
import { AuthContext } from "../../../../../contextAPI/context";
import { Modal } from "@mui/material";

function ExtendDeadline({
  selectProcess,
  setselectProcess,
  classes,
  complaint,
  setupdateProcecssModal,
  handlegetComplaintById,
}) {
  const { authState } = useContext(AuthContext);
  const { user } = authState;
  const [deadLineInDays, setdeadLineInDays] = useState("");
  const [deadLineInHours, setdeadLineInHours] = useState("");
  const [loader, setloader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");

  const callingApi = () => {
    setloader(true);
    seterrorMessage("");
    setTimeout(() => {
      setloader(false);
    }, 40000);
  };

  const handleReset = () => {
    setupdateProcecssModal(false);
    setselectProcess("Select Process");
    setdeadLineInDays("");
    setdeadLineInHours("");
    seterrorMessage("");
    setloader(false);
  };

  const handleExtendDeadline = async () => {
    callingApi();
    let temp =
      24 * parseInt(deadLineInDays === "" ? 0 : deadLineInDays) +
      parseInt(deadLineInHours === "" ? 0 : deadLineInHours);
    if (temp <= "0") {
      seterrorMessage("Please add a valid Deadline");
      setloader(false);
    } else {
      const sendData = {
        complaintId: complaint?._id,
        extendedHours: temp?.toString(),
        actionBy: user?._id,
      };

      const res = await ApiCall(
        "post",
        ComplaintUpdateProcessRoutes.extendDeadline,
        sendData
      );
      if (res?.success) {
        handleReset();
        handlegetComplaintById();
        toast.success("Deadline Extended Successfully");
      } else {
        setloader(false);
        seterrorMessage(res.error);
      }
    }
  };
  return (
    <Modal
      open={selectProcess === "Extend-deadline"}
      style={{ pointerEvents: loader ? "none" : "" }}
    >
      <Paper
        style={{
          backgroundColor: "#fff",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
          minWidth: "400px",
          maxWidth: "40vw",
        }}
        className={classes.ModalView}
      >
        <div className="col-sm-12 d-flex text-align-center justify-content-between">
          <span
            style={{
              fontFamily: "Montserrat, sans-serif",
            }}
            className="fw-bold fs-6"
          >
            Extend Deadline
          </span>
          <IconButton
            onClick={() => {
              handleReset();
            }}
          >
            <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
          </IconButton>
        </div>
        <Divider
          style={{
            marginTop: 0,
            marginBottom: 10,
            backgroundColor: "#000",
          }}
        />
        <div className="col-sm-12 d-flex justify-content-center mt-3">
          <div className="col-sm-6">
            <span
              style={{
                color: "#283f59",
                fontWeight: "600",
                fontFamily: "Montserrat, sans-serif",
              }}
              className="ms-2 fs-6"
            >
              Deadline in Days
            </span>
          </div>
          <div className="col-sm-6">
            <span
              style={{
                color: "#283f59",
                fontWeight: "600",
                fontFamily: "Montserrat, sans-serif",
              }}
              className="ms-2 fs-6"
            >
              Deadline in Hours
            </span>
          </div>
        </div>
        <div className="col-sm-12 d-flex justify-content-center mt-1">
          <div className="col-sm-6">
            <input
              type="number"
              style={{
                borderRadius: 3,
                padding: 10,
                width: "98%",
                border: "0.5px solid #010101",
                marginBottom: 8,
                outline: 0,
              }}
              onChange={(event) => setdeadLineInDays(event.target.value)}
              placeholder="Enter Days"
              value={deadLineInDays}
            />
          </div>
          <div className="col-sm-6">
            <input
              type="number"
              style={{
                borderRadius: 3,
                padding: 10,
                width: "98%",
                border: "0.5px solid #010101",
                marginBottom: 8,
                outline: 0,
              }}
              onChange={(event) => setdeadLineInHours(event.target.value)}
              placeholder="Enter Hours"
              value={deadLineInHours}
            />
          </div>
        </div>
        <span
          style={{
            fontWeight: "bold",
            fontFamily: "Montserrat, sans-serif",
            color: "#283f59",
            fontSize: "13px",
          }}
        >
          Total Deadline Hours : &nbsp;
          {24 * parseInt(deadLineInDays === "" ? 0 : deadLineInDays) +
            parseInt(deadLineInHours === "" ? 0 : deadLineInHours)}{" "}
          Hours
        </span>
        {errorMessage && (
          <span className="col-sm-12 d-flex justify-content-center text-align-center text-danger fw-bold fs-6">
            {errorMessage}
          </span>
        )}
        <div className="col-sm-12 d-flex justify-content-center">
          <div
            class="card"
            style={{
              height: 35,
              boxShadow: "0 3px 6px #7D858D",
              width: "120px",
              backgroundColor: "#2f45c5",
              color: "#ffffff",
              cursor: "pointer",
              marginLeft: 5,
              marginTop: 20,
            }}
            onClick={handleExtendDeadline}
          >
            <div class="card-body ">
              <p
                class="card-text"
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: "-10px",
                  textAlign: "center",
                }}
              >
                {!loader ? "Add" : `Adding...`}
              </p>
            </div>
          </div>
        </div>
      </Paper>
    </Modal>
  );
}

export default ExtendDeadline;
