import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Grid } from "@material-ui/core";
import { fetchSocietyId } from "../../contextAPI/fetchSociety";
import { CheckCircle } from "@material-ui/icons";
import { getSocietyVendors } from "../../GlobalFunctions/GlobalFunctions";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    fontWeight: "bold",
    fontFamily: "Montserrat, sans-serif",
  },
  clearIndicator: {
    right: "-4px",
    marginTop: -8,
  },
}));

function ChooseVendorDropDown(props) {
  const {
    setsearchKey,
    searchKey,
    setVendor,
    vendor,
    isMultiple,
    isLocal,
    isDisabled,
    category,
  } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [vendors, setvendors] = useState([]);
  const [totalCount, settotalCount] = useState(0);
  const [pageForVendor, setpageForVendor] = useState(1);

  useEffect(() => {
    handleGetVendors();
  }, [pageForVendor, searchKey]);

  const handleGetVendors = async () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 40000);
    let sendData = {
      societyId: fetchSocietyId(),
      pageNumber: pageForVendor,
      searchKey,
      pageLimit: 50,
      category: category ? category?._id : "",
      isSubVendor: false,
    };
    const res = await getSocietyVendors(sendData);
    if (pageForVendor === 1) {
      setvendors(res?.vendor);
      settotalCount(res?.count);
    } else {
      setvendors((prev) => [...prev, ...res?.vendor]);
      settotalCount(res?.count);
    }
    setLoading(false);
  };

  const handleScroll = (e) => {
    const target = e.target;
    if (
      target.scrollTop + target.offsetHeight + 1 >= target.scrollHeight &&
      !loading &&
      totalCount !== vendors?.length
    ) {
      setpageForVendor((prev) => prev + 1);
    }
  };

  return (
    <Grid container item xs={12}>
      <Autocomplete
        multiple={isMultiple}
        onChange={(e, value) => {
          if (isLocal) {
            localStorage.setItem("setVendorId", value ? value?._id : "");
            localStorage.setItem("setVendorName", value ? value?.name : "");
            setVendor(value);
          } else {
            setVendor(value);
          }
        }}
        id="country-select-demo"
        style={{
          width: "98%",
          backgroundColor: "#fff",
        }}
        freeSolo
        value={vendor}
        classes={classes}
        options={vendors}
        disabled={isDisabled}
        autoHighlight
        getOptionLabel={(option) =>
          `${
            localStorage.getItem("setVendorId")
              ? localStorage.getItem("setVendorName")
              : option?.name
              ? option?.name
              : ""
          }`
        }
        renderOption={(props, option) => {
          return (
            <div>
              <li {...props} key={option.id}>
                {vendor?.length > 0 && vendor.includes(option) && (
                  <CheckCircle
                    style={{
                      color: "rgb(36 202 38)",
                      fontSize: "14px",
                      marginRight: "5px",
                    }}
                  />
                )}
                {option.name}
              </li>
            </div>
          );
        }}
        renderInput={(params) => (
          <TextField
            required
            {...params}
            onChange={(e) => {
              setpageForVendor(1);
              setsearchKey(e.target.value);
            }}
            onBlur={() => {
              setsearchKey("");
            }}
            label={loading ? "Loading..." : `Select Vendor (${totalCount})`}
            inputProps={{
              ...params.inputProps,
            }}
          />
        )}
        ListboxProps={{
          onScroll: handleScroll,
        }}
      />
    </Grid>
  );
}

export default ChooseVendorDropDown;
