import React, { useEffect, useState } from "react";
import {
  Typography,
  Paper,
  Grid,
  CssBaseline,
  Divider,
  Card,
} from "@material-ui/core";
import useStyles from "../styles";
import {
  AccessTime,
  Apartment,
  Cake,
  Domain,
  Home,
  KeyboardBackspaceRounded,
  Mail,
  Phone,
} from "@material-ui/icons";
import { Key } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router";
import { fetchSocietyId } from "../../../contextAPI/fetchSociety";
import * as api from "../../../api";
import RefreshIcon from "@mui/icons-material/Refresh";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";
import Loading from "../../../contextAPI/Loading";
import { BASE_URL } from "../../../utils/base";
import { Tag } from "antd";
import { navigateToResident } from "../../../Navigation/Navigations";
import {
  handleFlatName,
  handleFloorName,
  handlePropertyName,
} from "../../../contextAPI/NamingConvention";

//demo branch code check
function FamilyMember() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  const [member, setMember] = useState([]);
  const [admin, setAdmin] = useState([]);
  const [search, setSearch] = useState("");
  const [loader, setloader] = useState(false);

  const getFamilyMembers = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      let sendData = {
        societyId: fetchSocietyId(),
        userId: id,
        searchKey: search,
        isDeleted: true,
      };
      const res = await api.getFamilyMember(sendData);
      if (res?.data?.success) {
        setMember(res?.data?.user?.familyMember);
        setAdmin(res?.data?.user?.admin);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getFamilyMembers();
  }, []);

  return (
    <>
      {" "}
      <div
        className={classes.root}
        style={{
          opacity: loader ? 0.4 : "",
          pointerEvents: loader ? "none" : "",
          overflow: loader ? "hidden" : "auto",
          height: "100vh",
        }}
      >
        <CssBaseline />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12} sm={12}>
              <Paper style={{ padding: 12, boxShadow: "0px 0px 0px" }}>
                <Grid
                  container
                  style={{ justifyContent: "space-between", marginTop: 8 }}
                >
                  <Grid
                    container
                    item
                    xs={4}
                    lg={4}
                    sm={4}
                    style={{ justifyContent: "space-between" }}
                  >
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: 22,
                        fontFamily: "Montserrat, sans-serif",
                        color: "#6c757d",
                        marginTop: "-5px",
                        marginBottom: 10,
                      }}
                    >
                      User Details
                      {!loader ? (
                        <RefreshIcon
                          title="refresh family Page"
                          onClick={getFamilyMembers}
                          style={{
                            marginLeft: 5,
                            marginTop: 0,
                            cursor: "pointer",
                            color: "#000",
                          }}
                        />
                      ) : (
                        <LoadingOutlined
                          title="laoding..."
                          style={{
                            marginLeft: 5,
                            color: "#000",
                            marginTop: 0,
                          }}
                        />
                      )}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={8}
                    lg={8}
                    sm={8}
                    justifyContent={"flex-end"}
                  >
                    <div
                      class="card"
                      style={{
                        height: 30,
                        boxShadow: "0 3px 6px #4a6078",
                        width: "90px",
                        backgroundColor: "#2f45c5",
                        color: "#ffffff",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate(navigateToResident({ status: "approved" }));
                      }}
                    >
                      <div class="card-body ">
                        <p
                          class="card-text"
                          style={{
                            fontSize: "11px",
                            fontWeight: "bold",
                            fontFamily: "Montserrat, sans-serif",
                            marginTop: "-12px",
                            marginBottom: "20px",
                            textAlign: "center",
                          }}
                        >
                          <KeyboardBackspaceRounded
                            style={{
                              marginRight: 10,
                              fontSize: "15px",
                            }}
                          />
                          Back
                        </p>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Grid
                  container
                  style={{ marginTop: 15, justifyContent: "center" }}
                >
                  <Card
                    style={{
                      backgroundolor: " #fff",
                      borderRadius: "7px",
                      display: "flex",
                      overflow: "hidden",
                      width: "47.4vw",
                      height: "35vh",
                      boxShadow:
                        "rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px",
                    }}
                  >
                    <div
                      style={{
                        backgroundImage:
                          "radial-gradient(circle at 48.7% 44.3%, rgb(30, 144, 231) 0%, rgb(56, 113, 209) 22.9%, rgb(38, 76, 140) 76.7%, rgb(31, 63, 116) 100.2%)",
                        color: "#fff",
                        padding: "20px",
                        maxWidth: "350px",
                        minWidth: "350px",
                      }}
                    >
                      <Grid container style={{ justifyContent: "center" }}>
                        <img
                          style={{
                            width: 70,
                            height: 70,
                            borderRadius: 50,
                          }}
                          src={
                            admin.image
                              ? `${BASE_URL}/user/${admin.image}`
                              : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                          }
                          alt="profile"
                        />
                      </Grid>
                      <Typography
                        style={{
                          textAlign: "center",
                          fontFamily: "Montserrat, sans-serif",
                          color: "#ffffff",
                          fontSize: 18,
                          fontWeight: "bold",
                        }}
                      >
                        {admin?.name}
                      </Typography>
                      <Typography
                        style={{
                          textAlign: "center",
                          fontFamily: "Montserrat, sans-serif",
                          color: "#ffffff",
                          fontSize: 15,
                          textDecoration: "capitalize",
                        }}
                      >
                        {admin?.role}
                      </Typography>
                      <Divider
                        style={{
                          marginTop: 10,
                          marginBottom: 10,
                          backgroundColor: "#000",
                        }}
                      />
                      <Grid container>
                        <Grid
                          container
                          item
                          xs={6}
                          lg={6}
                          md={6}
                          style={{ justifyContent: "flex-start" }}
                        >
                          <Typography
                            style={{
                              textAlign: "center",
                              fontFamily: "Montserrat, sans-serif",
                              color: "#fff",
                              fontSize: 14,
                              fontWeight: "bold",
                              marginTop: 4,
                            }}
                          >
                            <Apartment style={{ fontSize: "18px" }} />
                            &nbsp; {handlePropertyName()} : &nbsp;
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          item
                          xs={6}
                          lg={6}
                          md={6}
                          style={{ justifyContent: "flex-start" }}
                        >
                          <Typography
                            style={{
                              textAlign: "center",
                              fontFamily: "Montserrat, sans-serif",
                              color: "#fff",
                              fontSize: 14,
                              fontWeight: "bold",
                              marginTop: 4,
                            }}
                          >
                            {admin?.property?.name}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid
                          container
                          item
                          xs={6}
                          lg={6}
                          md={6}
                          style={{ justifyContent: "flex-start" }}
                        >
                          <Typography
                            style={{
                              textAlign: "center",
                              fontFamily: "Montserrat, sans-serif",
                              color: "#fff",
                              fontSize: 14,
                              fontWeight: "bold",
                              marginTop: 4,
                            }}
                          >
                            <Domain style={{ fontSize: "18px" }} />
                            &nbsp; {handleFloorName()} : &nbsp;
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          item
                          xs={6}
                          lg={6}
                          md={6}
                          style={{ justifyContent: "flex-start" }}
                        >
                          <Typography
                            style={{
                              textAlign: "center",
                              fontFamily: "Montserrat, sans-serif",
                              color: "#fff",
                              fontSize: 14,
                              fontWeight: "bold",
                              marginTop: 4,
                            }}
                          >
                            {admin?.floor?.name}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid
                          container
                          item
                          xs={6}
                          lg={6}
                          md={6}
                          style={{ justifyContent: "flex-start" }}
                        >
                          <Typography
                            style={{
                              textAlign: "center",
                              fontFamily: "Montserrat, sans-serif",
                              color: "#fff",
                              fontSize: 14,
                              fontWeight: "bold",
                              marginTop: 4,
                            }}
                          >
                            <Home style={{ fontSize: "18px" }} />
                            &nbsp; {handleFlatName()} : &nbsp;
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          item
                          xs={6}
                          lg={6}
                          md={6}
                          style={{ justifyContent: "flex-start" }}
                        >
                          <Typography
                            style={{
                              textAlign: "center",
                              fontFamily: "Montserrat, sans-serif",
                              color: "#fff",
                              fontSize: 14,
                              fontWeight: "bold",
                              marginTop: 4,
                            }}
                          >
                            {admin?.flat?.number}&nbsp; {admin?.flat?.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                    <div
                      style={{
                        padding: "15px",
                        position: "relative",
                        width: "100%",
                      }}
                    >
                      <Typography
                        style={{
                          color: "#000",
                          textAlign: "start",
                          fontWeight: "bold",
                          fontSize: 18,
                          opacity: 1,
                          marginBottom: 10,
                          fontFamily: "Montserrat, sans-serif",
                        }}
                      >
                        Other Information
                      </Typography>
                      <Divider
                        style={{
                          marginTop: 0,
                          marginBottom: 10,
                          backgroundColor: "#000",
                        }}
                      />
                      <Grid conatiner>
                        <Typography
                          style={{
                            color: "#000",
                            textAlign: "start",
                            fontSize: 14,
                            opacity: 1,
                            marginBottom: 10,
                            fontWeight: 600,
                            fontFamily: "Montserrat, sans-serif",
                          }}
                        >
                          <AccessTime style={{ fontSize: "18px" }} />
                          &nbsp; Created At : &nbsp;
                          <span style={{ color: "#6c757d" }}>
                            {moment(admin?.createdAt).format(
                              "DD/MM/YYYY h:mm A"
                            )}
                          </span>
                        </Typography>
                        <Typography
                          style={{
                            fontWeight: "normal",
                            color: "#000",
                            textAlign: "start",
                            fontSize: 14,
                            opacity: 1,
                            marginBottom: 10,
                            fontWeight: 600,
                            fontFamily: "Montserrat, sans-serif",
                          }}
                        >
                          <Mail style={{ fontSize: "18px" }} />
                          &nbsp; Email : &nbsp;
                          <span style={{ color: "#6c757d" }}>
                            {admin?.email}
                          </span>
                        </Typography>
                        <Typography
                          style={{
                            color: "#000",
                            textAlign: "start",
                            fontSize: 14,
                            opacity: 1,
                            marginBottom: 10,
                            fontWeight: 600,
                            fontFamily: "Montserrat, sans-serif",
                          }}
                        >
                          <Phone style={{ fontSize: "18px" }} />
                          &nbsp; Phone : &nbsp;
                          <span style={{ color: "#6c757d" }}>
                            {admin?.phone}
                          </span>
                        </Typography>
                        <Typography
                          style={{
                            color: "#000",
                            textAlign: "start",
                            fontSize: 14,
                            opacity: 1,
                            marginBottom: 10,
                            fontWeight: 600,
                            fontFamily: "Montserrat, sans-serif",
                          }}
                        >
                          <Key style={{ fontSize: "18px" }} />
                          &nbsp; UniqueId : &nbsp;
                          <span style={{ color: "#6c757d" }}>
                            {admin?.uniqueId}
                          </span>
                        </Typography>
                        <Typography
                          style={{
                            color: "#000",
                            textAlign: "start",
                            fontSize: 14,
                            opacity: 1,
                            marginBottom: 10,
                            fontWeight: 600,
                            fontFamily: "Montserrat, sans-serif",
                          }}
                        >
                          <Cake style={{ fontSize: "18px" }} />
                          &nbsp; DOB : &nbsp;
                          <span style={{ color: "#6c757d" }}>
                            {" "}
                            {admin?.DOB ? admin?.DOB : "-"}
                          </span>
                        </Typography>
                      </Grid>
                    </div>
                  </Card>
                </Grid>
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: 22,
                    fontFamily: "Montserrat, sans-serif",
                    color: "#6c757d",
                    marginTop: "-5px",
                    marginBottom: 10,
                  }}
                >
                  Family Member
                </Typography>
                <Grid
                  container
                  spacing={1}
                  style={{
                    marginTop: 18,
                  }}
                >
                  {member?.map((user) => {
                    return (
                      <Grid item xs={3}>
                        <Paper className={classes.familyCard}>
                          <Grid
                            container
                            style={{ justifyContent: "flex-end" }}
                          >
                            {user?.familyMember?.isDeleted && (
                              <Tag
                                color="red"
                                variant="outlined"
                                style={{
                                  padding: 3,
                                  fontWeight: "bold",
                                  fontFamily: "Montserrat, sans-serif",
                                  fontSize: 14,
                                  marginRight: 0,
                                }}
                              >
                                Deleted
                              </Tag>
                            )}
                          </Grid>
                          <Typography
                            style={{
                              textAlign: "center",
                              marginTop: 7,
                            }}
                          >
                            <img
                              src={
                                user?.familyMember?.image
                                  ? `${BASE_URL}/user/${user?.familyMember?.image}`
                                  : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                              }
                              alt="John"
                              style={{
                                width: 90,
                                height: 90,
                                borderRadius: "50%",
                              }}
                            />
                          </Typography>
                          <Typography
                            style={{
                              color: "#DAA520",
                              textAlign: "center",
                              marginTop: 10,
                              fontSize: 20,
                              fontWeight: "bolder",
                              fontFamily: "Montserrat, sans-serif",
                            }}
                          >
                            {user?.familyMember?.name}
                          </Typography>
                          <Typography
                            style={{
                              textAlign: "center",
                              fontFamily: "Montserrat, sans-serif",
                              color: "#000000",
                              fontSize: 15,
                              opacity: 0.6,
                              textDecoration: "capitalize",
                              fontWeight: 400,
                              marginBottom: 10,
                            }}
                          >
                            Family
                          </Typography>

                          <Typography
                            style={{
                              color: "#000",
                              textAlign: "start",
                              fontSize: 15,
                              opacity: 1,
                              marginBottom: 10,
                              fontWeight: 600,
                              fontFamily: "Montserrat, sans-serif",
                            }}
                          >
                            <Mail style={{ fontSize: "18px" }} />
                            &nbsp; Email : &nbsp;
                            <span
                              style={{
                                color: "#000",
                                opacity: 0.7,
                                fontSize: 14,
                              }}
                            >
                              {" "}
                              {user?.familyMember?.email
                                ? user?.familyMember?.email
                                : "No Email Added"}
                            </span>
                          </Typography>
                          <Typography
                            style={{
                              color: "#000",
                              textAlign: "start",
                              fontSize: 15,
                              opacity: 1,
                              marginBottom: 10,
                              fontWeight: 600,
                              fontFamily: "Montserrat, sans-serif",
                            }}
                          >
                            <Phone style={{ fontSize: "18px" }} />
                            &nbsp; Phone : &nbsp;
                            <span
                              style={{
                                color: "#000",
                                opacity: 0.7,
                                fontSize: 14,
                              }}
                            >
                              {" "}
                              {user?.familyMember?.phone
                                ? user?.familyMember?.phone
                                : "No Phone Added"}
                            </span>
                          </Typography>

                          <Typography
                            style={{
                              color: "#000",
                              textAlign: "start",
                              fontSize: 15,
                              opacity: 1,
                              marginBottom: 10,
                              fontWeight: 600,
                              fontFamily: "Montserrat, sans-serif",
                            }}
                          >
                            <AccessTime style={{ fontSize: "18px" }} />
                            &nbsp; Created At : &nbsp;
                            <span
                              style={{
                                color: "#000",
                                opacity: 0.7,
                                fontSize: 14,
                              }}
                            >
                              {moment(user?.familyMember?.createdAt).format(
                                "DD/MM/YYYY h:mm A"
                              )}
                            </span>
                          </Typography>
                        </Paper>
                      </Grid>
                    );
                  })}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </main>
        {loader && <Loading loader={loader} />}
      </div>
    </>
  );
}

export default FamilyMember;
